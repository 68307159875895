'use strict';

const angular = require('angular');
const moment = require('moment');

/**
 * @ngInject
 */
function ShopStylingService($log, $interpolate, apiConfiguration, injectCSS) {
    const self = this;

    // cache-busting paramter an URL anfügen, da im Netzwerk in dem sich die OnSite-Kassensysteme
    // befinden anscheinend ein Proxy drinhängt, der zu aggressiv cached.
    const endpointUrl = `${apiConfiguration.baseUrl}/shop/{{shop.id}}/styling?t=${moment().unix()}`;

    self.injectStylingForShop = function (shop) {
        const url = $interpolate(endpointUrl)(shop);
        return injectCSS('shopstyling_' + shop.shop.id, url).then(() => shop);
    };
}

angular.module('leipzigerMesse.ticketShop.frontend.shop').service('shopStylingService', ShopStylingService);
