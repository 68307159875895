'use strict';

const _ = require('lodash');

require('angular').module('leipzigerMesse.ticketShop.frontend.error').config($stateProvider => {
    'ngInject';

    $stateProvider
        .state('error', {
            abstract: true,
            resolve: {
                styling: (lmAppConfig, injectCSS) => {
                    'ngInject';

                    const stylingHref = _.get(lmAppConfig, 'api.stylingUrl');
                    return injectCSS('shopstyling_generic', stylingHref);
                }
            }
        })
        .state('error.order', {
            abstract: true
        })
        .state('error.order.unauthorized', {
            component: 'lmErrorPage',
            resolve: {
                heading: () => 'error.order.unauthorized.heading',
                body: () => 'error.order.unauthorized.body',
                bodyValues: ($location, keycloak) => {
                    return {
                        loginUrl: keycloak.createLogoutUrl({
                            redirectUri: keycloak.createLoginUrl({
                                prompt: 'login',
                                redirectUri: $location.href
                            })
                        })
                    };
                }
            }
        });
});
