'use strict';

const _ = require('lodash');

class ContingentDisplayWidgetComponentController {

    /**
     * @ngInject
     */
    constructor($log, $interval, $http, apiConfiguration) {
        this.$log = $log;
        this.$interval = $interval;
        this.$http= $http;

        this.updateInterval = undefined;
        this.endpointUrl = apiConfiguration.baseUrl + '/contingent/';
    }


    $onInit() {
        this.updateInterval = this.$interval(() => {
            this.$http.get(this.endpointUrl + this.widget.contingent.id).then(response => {
                this.widget.contingent = response.data;
            });
        }, 5000);
    }

    $onDestroy() {
        if(!_.isUndefined(this.updateInterval)) {
            this.$interval.cancel(this.updateInterval);
        }
    };

}

require('angular').module('leipzigerMesse.ticketShop.frontend.widget').component('lmContingentDisplayWidget', {
    templateUrl: 'widget/widget.contingentDisplay.component.html',
    controller: ContingentDisplayWidgetComponentController,
    require: {
        shop: '^^lmShop'
    },
    bindings: {
        widget: '<'
    }
});
