'use strict';

/**
 * @ngInject
 */
function RootController() {
    // leer
}

module.exports = RootController;
