'use strict';

var _ = require('lodash');

function ShopModel(model, isSubmodel) {
    var self = this;

    _.extend(self, model);

    // formData initialisieren falls bisher nicht vorhanden, damit die spätere Zuweiseung unkomplizierter ist.
    self.formData = self.formData || {};

    // ggf. sind bereits ausgewählte Leistungen vorhanden, welche ebenfalls ShopModels sein müssen.
    self.items = _.mapValues(self.items || [], function (itemsForKey) {
        return _.map(itemsForKey, function (item) {
            return new ShopModel(item);
        })
    });

    self.$$isSubmodel = !!isSubmodel;
}

ShopModel.prototype.hasValue = function (propertyPath) {
    return !_.isEmpty(this.getFormDataProperty(propertyPath)());
};

ShopModel.prototype.getValue = function (propertyPath) {
    var value = this.getFormDataProperty(propertyPath)();
    if (_.isObject(value)) {
        return _.get(value, 'value');
    }
    return value;
};


ShopModel.prototype.getData = function (propertyPath) {
    var property = this.getFormDataProperty(propertyPath);
    return property();
};

ShopModel.prototype.setData = function (propertyPath, value) {
    var property = this.getFormDataProperty(propertyPath);
    property(value);
};

ShopModel.prototype.getSubmodel = function (subModelPath) {
    var subModel = _.get(this.formData, subModelPath, {subFormModel: {formData: {}}});

    //ggf. noch nicht existierendes subFormModel initialisieren.
    _.set(this.formData, subModelPath, subModel);

    return new ShopModel(subModel.subFormModel, true);
};

ShopModel.prototype.getFormDataProperty = function (propertyPath) {
    var self = this;

    propertyPath = [propertyPath, 'data'].join('.');

    return function (value) {
        if (arguments.length > 0) {
            return _.set(self.formData, propertyPath, value);
        }
        else {
            return _.get(self.formData, propertyPath);
        }
    }
};

ShopModel.prototype.getError = function (propertyPath) {
    return _.get(this.formData, propertyPath + '.error');
};

ShopModel.prototype.hasError = function (propertyPath) {
    return !!this.getError(propertyPath);
};

ShopModel.prototype.clearError = function (propertyPath) {
    return _.set(this.formData, propertyPath + '.error', undefined);
};

ShopModel.prototype.clearFormData = function () {
    this.formData = {};
};

module.exports = ShopModel;