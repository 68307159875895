'use strict';

const angular = require('angular');
const _ = require('lodash');
const $ = require('jquery-browserify');
const {PYT_MODES} = require('../common/constants');

const ITF_HEADER_HEIGHT = 180;

/**
 * Enum der möglichen Status von Gutscheinen.
 */
const VoucherStatus = {
    ENTERED: 'ENTERED',
    APPLIED: 'APPLIED',
    IGNORED: 'IGNORED',
    INVALID: 'INVALID',
}

/**
 * Enum der möglichen Sichtbarkeit der Schritte.
 *
 * @enum {string}
 */
const StepVisibility = {
    ALL: 'ALL',
    FIRST: 'FIRST',
    CURRENT: 'CURRENT'
};

function markInvalidFormControlsDirty(form) {
    _.each(form.$error, function (controls) {
        _.each(controls, function (control) {
            control.$setDirty();
            // Wenn "control" ein FormController ist, dann erlaubt dessen $error property einen rekursiven Abstieg,
            // wenn "control" hingegen ein NgModelController ist, dann sind die Werte von $error booleans und
            // ein weiterer Abstieg wird graceful vermieden, da eine iteration über boolean Werte keinen Effekt hat.
            markInvalidFormControlsDirty(control);
        });
    });
}

/**
 * @param {ShopService} shopService
 *
 * @ngInject
 */
function ShopComponentController(
    $log,
    $window,
    $document,
    $rootScope,
    $timeout,
    $translate,
    growl,
    countryDataByIsoCode,
    shopService,
    Shop,
    Order,
    ShopModel,
    lmItemRegistry,
    keycloakUserProfile,
    i18nFilter,
    lmAppConfig
) {
    const self = this;

    // Behelfs-Flag, um die erste Neu-Berechnung der
    // anzuwendenden Gutschein-Werte auszusetzen.
    self.isFirstVoucherRecalculation = true;

    /**
     * Mapping von Schritt IDs auf boolean als Indikator für die Sichtbarkeit der Schritte.
     */
    self.visibleSteps = {};

    function scrollToElement(element) {
        let yOffset = 250;

        if (self.isItfShop()) {
            // ITF Shops haben einen extra header auf voller breite, daher muss der offset größer ausfallen.
            yOffset += ITF_HEADER_HEIGHT;
        }

        if (self.isOnsite()) {
            const container = angular.element($('#content .column-content'));
            container.scrollToElement(element, 50, 500);
        } else {
            $document.scrollToElement(element, yOffset, 500);
        }
    }

    function createCallingCodeString(callingCode) {
        return callingCode ? '+' + callingCode : '';
    }

    /**
     * Etabliert ein $watch welches auf Änderung der Länderauswahl im spezifierten Adress-Modell reagiert und den
     * spezifierten Telefonnummer Datenpfad die korrespondierende Ländervorwahl schreibt.
     */
    function setupSyncingOfCallingCodeWithCountrySelection(addressSubModelPath, phonePropertyPath) {
        $rootScope.$watch(function () {
            const addressModel = new ShopModel(self.data.model).getSubmodel(addressSubModelPath);
            return _.get(addressModel.getFormDataProperty('country')(), 'value');
        }, function (newCountry, oldCountry) {
            const dataModel = new ShopModel(self.data.model);
            const phoneProperty = dataModel.getFormDataProperty(phonePropertyPath);
            const phoneValue = phoneProperty();

            const newCallingCode = _.get(countryDataByIsoCode[newCountry], 'callingCode');
            const oldCallingCode = _.get(countryDataByIsoCode[oldCountry], 'callingCode');

            const inputContainsOnlyOldCallingCode = createCallingCodeString(oldCallingCode) === phoneValue;

            // Eine evtl. bereits durch den Benutzer vorgenommene Eingabe wollen wir nicht überschreiben.
            if (_.isEmpty(phoneValue) || inputContainsOnlyOldCallingCode) {
                phoneProperty(createCallingCodeString(newCallingCode));
            }
        });
    }

    /**
     * "Initialisiert" die Input-Felder für Käufer-Personendaten, indem alle nicht belegten Felder explizit auf undefined
     * gesetzt werden, da dies (Gott weiß wieso) dazu führt, dass das Binding der AccessCode-Personendaten funktioniert
     */
    function initFieldsForAccessCodeBinding() {
        const userFormFields = [
            'user.firstName',
            'user.lastName',
            'user.email',
            'user.phone',
            'user.function',
            'user.company',
            'user.company2',
            'user.company3'
        ];

        _.each(userFormFields, function (formDataPath) {
            const property = self.data.model.getFormDataProperty(formDataPath);
            if (_.isEmpty(property())) {
                property(undefined);
            }
        });

        const salutationWidget = shopService.getWidgetsByPredicate(self.data, function (widget) {
            return widget.type === 'choice_input' && widget.path === 'user.salutation';
        })[0];
        if (salutationWidget) {
            const property = self.data.model.getFormDataProperty('user.salutation');
            if (_.isEmpty(property())) {
                property(undefined);
            }
        }

        const titleWidget = shopService.getWidgetsByPredicate(self.data, function (widget) {
            return widget.type === 'choice_input' && widget.path === 'user.title';
        })[0];
        if (titleWidget) {
            const property = self.data.model.getFormDataProperty('user.title');
            if (_.isEmpty(property())) {
                property(undefined);
            }
        }

        const addressFormFields = [
            'city',
            'street',
            'houseno',
            'postalCode'
        ];
        const addressModel = self.data.model.getSubmodel('user.address');
        _.each(addressFormFields, function (formDataPath) {
            const property = addressModel.getFormDataProperty(formDataPath);
            if (_.isEmpty(property())) {
                property(undefined);
            }
        });
    }

    /**
     * Befüllt die Kundendaten des FormModels anhand der Personendaten, welche an dem verwendeten Keycloak-Account sind
     */
    function prefillCustomerDataFromUserProfile() {
        const simpleMappings = {
            'firstName': 'user.firstName',
            'lastName': 'user.lastName',
            'email': 'user.email'
        };

        _.each(simpleMappings, function (formDataPath, userProfilePath) {
            const property = self.data.model.getFormDataProperty(formDataPath);
            if (_.isEmpty(property())) {
                property(_.get(keycloakUserProfile, userProfilePath));
            }
        });
    }

    /**
     * Initialisiert die Schritte des Kaufprozesses.
     *
     * @param {StepVisibility} stepVisibility - Gibt an welche Schritte nach der Initialisierung sichtbar sein sollen.
     */
    function initializeProcessSteps(stepVisibility) {
        self.stepWidgetsById = _.reduce(self.getStepWidgets(), function (acc, w) {
            acc[w.id] = w;
            return acc;
        }, {});

        // Alle navigationSteps aus früheren Stages können/müssen als bereits vollständig markiert werden,
        // da diese erfolgreich durchlaufen worden sein müssen, um zum aktuellen Punkt im Prozess zu kommen.
        const firstStepId = _.get(_.first(self.getStepWidgets()), 'id');

        _(self.getNavigationSteps())
            .takeWhile(step => {
                // alle Schritte bis zum ersten der aktuellen Stage müssen
                // in einer vorherigen Stage enthalten gewesen sein.
                return step.id !== firstStepId;
            })
            .each(step => {
                step.$$completedInPreviousStage = true;
            });

        switch (stepVisibility) {
            case StepVisibility.ALL:
                self.visibleSteps = {};
                self.setStepVisible(_.last(self.getStepWidgets()));
                break;
            case StepVisibility.FIRST:
                self.visibleSteps = {};
                self.setStepVisible(_.first(getActiveSteps()));
                break;
            case StepVisibility.CURRENT:
            default: // eslint-disable no-fallthrough
                // nichts zu tun, da aktuell sichtbare Schritte beibehalten werden sollen.
                break;
        }
    }

    // Mapping von order_step_widget_group Widgets auf die sie repräsentierenden Angular-Components.
    const stepComponentsByWidgetId = {};

    self.refreshShop = function () {
        return Shop.submit({id: self.data.shop.id}, self.data).$promise.then(applyNewShopData);
    };

    self.applyLastOrderData = function () {
        return Shop.applyLastOrderData({id: self.data.shop.id}, self.data).$promise.then(applyNewShopData);
    };

    $rootScope.$on('$translateChangeSuccess', function () {
        self.refreshShop();
    });

    self.getStepWidgets = function () {
        return _.get(self.data, 'shop.widgetContainer.widgets', []);
    };

    /**
     * Liefert die in der Navigation anzuzeigenden Schritte.
     */
    self.getNavigationSteps = function () {
        return _.get(self.data, 'navigationSteps');
    };

    /**
     * Ermittelt ob der Schritt mit der übergebenen ID aktuell vollständig ist.
     */
    self.isStepComplete = function (stepId) {
        const step = stepComponentsByWidgetId[stepId];

        return step ? step.isComplete() : false;
    };

    /**
     * Ermittelt ob der Schritt mit der übergebenen ID aktuell unvollständig ist.
     */
    self.isStepIncomplete = function (stepId) {
        const step = stepComponentsByWidgetId[stepId];

        return step ? step.isIncomplete() : false;
    };

    /**
     * Ermittelt die Nummer eines Schritts.
     *
     * @param step - Der Schritt für den die Nummer gesucht wird.
     *
     * @return {number} Die Nummer des Schritts oder undefined, wenn der übergebene Schritt nicht aktiv ist.
     */
    self.getStepNumber = function (step) {
        const stepNumber = _(self.getNavigationSteps())
            .filter(self.isStepActive)
            .findIndex({id: step.id});

        return stepNumber < 0 ? undefined : stepNumber + 1;
    };

    /**
     * Wendet die übergebenen Shop Daten an und initialisiert die Schritte des Kaufprozess neu.
     * @param {ShopData} shopData - Die anzuwendenden Shop Daten.
     * @param {StepVisibility} stepVisibility - Die Sichtbarkeit der Schritte nach Anwendung der neuen Daten.
     */
    function applyNewShopData(shopData, stepVisibility) {
        self.data = Shop.shopify(shopData);
        initializeProcessSteps(stepVisibility || StepVisibility.CURRENT);

        prefillCustomerDataFromUserProfile();

        if (self.isOnsite()) {
            _.each(self.data.model.items, (itemModels) => {
                _.each(itemModels, (itemModel) => {
                    if (shopService.isItemModelWithoutPersonalisation(itemModel)) {
                        shopService.applyOrderFormDataToItemModel(self.data.model, itemModel);
                    }
                });
            });
        }
    }

    self.submitOrder = function () {
        if (self.isValid()) {
            $log.info('submitting order');

            // Ausgewählte Sprache mitschicken, damit Tickets und Rechnung in der korrekten Sprache gedruckt werden
            self.data.model.language = $translate.use();

            return Order.save(self.data).$promise.then(function (response) {
                // Ein erfolgreiche Antwort bedeutet, dass der Kaufprozess in die nächste Phase übergegangen ist.
                // Dies kann erfordern dass der Benutzer nun Aktionen auf einer externen Seite durchführen muss,
                // bspw. die Bezahlung beim Zahlungsdienstleister durchzuführen. Daher müssen wird prüfen ob eine
                // redirect Anweisung vorlieget und dann entsprechend umleiten, andernfalls verwenden die Daten
                // der Antwort um den Shop für den nächsten Shop neu aufzubauen.
                if (_.has(response, 'redirectUrl')) {
                    $window.location.href = response.redirectUrl;
                } else {
                    // Wir haben die Daten für eine neue Stage erhalten: Daten übernehmen und mit ersten
                    // Schritt der nächsten Stage beginnen.
                    applyNewShopData(response, StepVisibility.FIRST);
                }
            }).catch(function (res) {
                // Daten die wir geschickt haben sind fehlerhaft; validierte Daten vom Server übernehmen.
                if (res.status < 500) {
                    $log.warn('order submission was rejected by server, resetting data with server validated values');
                    const error = i18nFilter(_.get(res.data, 'model.error', {}));

                    if (error) {
                        growl.error(error);
                    }

                    applyNewShopData(res.data);
                }
                // Server hatte internen Fehler, wir können nicht davon ausgehen, dass die Daten vom Server verwendbar sind.
                else {
                    $log.error('server encountered internal error on order submission; response data will be ignored');
                    growl.error('shop.checkout.submit.serverError.message');
                }
            });
        } else {
            $log.info('unable to submit order due to invalid data');

            // Alle aktuell vorhandenen Eingabeelemente als $dirty markieren, damit evtl. Fehlermeldungen angezeigt werden.
            markInvalidFormControlsDirty(self.form);
            // Das erste ungültige Eingabeelement fokussieren, damit der Benutzer gleich weiß was er korrigieren muss.
            self.focusFirstInvalidControl();
        }
    };

    self.focusFirstInvalidControl = function () {
        // In ein $timeout callback wickeln, damit die Ausführung um min. einen digest-cycle verzögert wird,
        // so dass evtl. auch neu sichtbar gewordenen Eingabelemente fokussiert werden können.
        return $timeout(function () {
            // Das erste sichtbare ungültige Eingabelement finden, denn ggf. sind einige Controls
            // auf Mobil/Desktop ausgeblendet. Die Such erfolgt mit jQuery anhand der durch die
            // Validierung hinzugefügten Klassen im aktuellen DOM, da es anderweitig keine
            // Möglichkeit gibt bzgl. der sichtbaren Reihenfolge das erste ungültige Eingabelement zu finden.
            const firstInvalidControl = $('[lm-widget-control].ng-invalid, .form-control.ng-invalid, lm-accreditation.ng-invalid .lm-accreditation, .min-max-order-amount-error').filter(':visible').get(0);

            if (firstInvalidControl) {
                const element = angular.element(firstInvalidControl);
                scrollToElement(element);
                return true;
            } else {
                return false;
            }
        }, 300);
    };

    self.addStepComponent = function (stepComponent) {
        $log.debug('addStepComponent #%s "%s" (%s)', stepComponent.index + 1, stepComponent.widget.layout.label.de, stepComponent.id);
        stepComponentsByWidgetId[stepComponent.widget.id] = stepComponent;
    };

    self.removeStepComponent = function (stepComponent) {
        $log.debug('removeStepComponent #%s "%s" (%s)', stepComponent.index + 1, stepComponent.widget.layout.label.de, stepComponent.id);
        delete stepComponentsByWidgetId[stepComponent.widget.id];
    };

    self.removeCode = function (code) {
        self.data.model.codes.input = '';
        const index = _.findIndex(self.data.model.codes.validCodes, function (c) {
            return c.code === code.code;
        });
        self.data.model.codes.validCodes.splice(index, 1);
        self.data.model.codes.validCodes = _.without(self.data.model.codes.validCodes, code);
        return self.refreshShop();
    };

    self.wasSubmitted = function () {
        return self.form.$submitted;
    };

    self.getNumberOfItemsInCart = function () {
        return _.reduce(self.data.model.items, function (sum, items) {
            return sum + items.length;
        }, 0);
    };

    self.isCartEmpty = function () {
        return self.getNumberOfItemsInCart() === 0;
    };

    self.isValid = function () {
        const isCartEmpty = self.isCartEmpty();
        const isSubmitWithEmptyCartAllowed = shopService.isSubmitWithEmptyCartAllowed(self.data);

        return self.form.$valid && (!isCartEmpty || isSubmitWithEmptyCartAllowed);
    };

    /**
     * Liefert die aktuell aktiven Schritte.
     */
    function getActiveSteps() {
        return _.filter(self.getStepWidgets(), self.isStepActive);
    }

    /**
     * Ermittelt ausgehend von dem übergebenen Schritt den nächsten aktiven Schritt.
     */
    function findNextStep(step) {
        const activeSteps = getActiveSteps();
        const currentStepIndex = _.findIndex(activeSteps, {id: step.id});
        return _.get(activeSteps, currentStepIndex + 1);
    }

    /**
     * Ermittelt den letzen aktiven und sichtbaren Schritt.
     */
    function findLastActiveAndVisibleStep() {
        return _.findLast(getActiveSteps(), self.isStepVisible);
    }

    /**
     * Setzt den Kaufprozess ausgehend vom übergebenen Schritt mit dem nächsten Schritt fort sofern möglich.
     *
     * Sollte der übergebene Schritt nicht valide sein, verbleibt der Kaufprozess im aktuellen Zustand und
     * es werden lediglich die fehlenden/fehlerhaften Eingaben des Benutzers kenntlich gemacht.
     *
     * @param currentStepComponent Komponente des aktuellen Schrittes
     */
    self.proceed = function (currentStepComponent) {
        _.each(stepComponentsByWidgetId, function (step) {
            if (self.isStepVisible(step)) {
                // Alle Eingabelemente im Schritt $dirty markieren, damit evtl. Fehlermeldungen angezeigt werden.
                markInvalidFormControlsDirty(step.form);
            }
        });

        // ggf. muss der Benutzer noch Daten korrigieren
        self.focusFirstInvalidControl().then(function (invalidControlHasBeenFocussed) {
            // Den nächsten Schritt nur anzeigen, wenn der aktuelle Schritt valide ist
            if (currentStepComponent.isValid()) {
                const nextStep = findNextStep(currentStepComponent);

                if (nextStep) {
                    self.setStepVisible(nextStep);
                }
            }

            // Nur wenn bis jetzt alles korrekt ist, zum nächsten sichtbaren Schritt gehen.
            if (!invalidControlHasBeenFocussed) {
                // scroll-to um einen digest-cycle verzögern, damit sich die Änderung in
                // der Sichtbarkeit der Schritte auf den DOM auswirken können.
                $timeout(() => {
                    const lastStep = findLastActiveAndVisibleStep();
                    const element = document.getElementById(lastStep.id);
                    scrollToElement(element);
                }, 300);
            }
        });
    };

    /**
     * Ermittelt ob ein Schritt sichtbar ist.
     */
    self.isStepVisible = function (step) {
        return !!_.get(self.visibleSteps, step.id);
    };

    /**
     * Setzt die Sichtbarkeit eines Schritts.
     *
     * Alle Schritte vor dem übergebenen Schritt werden hierdurch ebenfalls als sichtbar gesetzt, da der Benutzer
     * sinnvollerweise diesen einen Schritt nur sehen kann, wenn er alle vorherigen auch sehen konnte abgesehen
     * von evtl. nicht aktiven Schritten; aber speziell für diese ist es wichtig, dass sie auch sichtbar markiert
     * werden für den Fall, dass sie später aktiv werden.
     */
    self.setStepVisible = function (stepComponent) {
        _(self.getStepWidgets())
            .map('id')
            .dropRightWhile(function (id) {
                return id !== stepComponent.id;
            })
            .each(function (id) {
                self.visibleSteps[id] = true;
            });
    };

    /**
     * Prüft ob der aktuelle Shop einsprachig ist.
     */
    self.isMonolingual = function () {
        // Es wird fest davon ausgegegangen, dass nur Deutsch und Englisch unterstützt werden,
        // daher reicht es hier aus zu prüfen, ob min. eine der Sprachen nicht verfügbar ist.
        return !_.every(self.data.shop.allowedDisplayLanguages);
    };

    /**
     * Erzwingt die Darstellung des Shops in der einzigen verfügbaren Sprache.
     */
    self.forceAvailableLanguage = function () {
        // Es wird fest davon ausgegegangen, dass nur Deutsch und Englisch unterstützt werden,
        // daher reicht es hier aus die erste verfügbare Sprachen zu nehmen.
        $translate.use(_.findKey(self.data.shop.allowedDisplayLanguages));
    };

    self.isAccreditationRequired = function () {
        const accreditationWidgets = shopService.getWidgetsByPredicate(self.data, w => w.type === 'accreditation', true);
        return accreditationWidgets.length > 0;
    };

    self.isAcceptanceOfTermsRequired = function () {
        return _.get(self.data, 'shop.requireAcceptTerms') && shopService.hasActiveItemWidgets(self.data);
    };

    self.isDesktopTicketDisabled = function () {
        return _.get(self.data, 'shop.disableDesktopTicket', false);
    };

    self.isMobileTicketDisabled = function () {
        return _.get(self.data, 'shop.disableMobileTicket', false);
    };

    /**
     * Prüft ob der Kaufschritt mit der übergebenen ID entsprechend den deklarierten Regeln aktiv ist und somit Teil des Kaufprozesses ist.
     */
    self.isStepWithIdActive = function (stepId) {
        const stepWidget = self.stepWidgetsById[stepId];
        return stepWidget ? self.isStepActive(stepWidget) : true;
    };

    /**
     * Prüft ob ein Kaufschritt entsprechend den deklarierten Regeln aktiv ist und somit Teil des Kaufprozesses ist.
     */
    self.isStepActive = function (step) {
        const fulfillment = shopService.determineRuleFulfillment(step.rules, self.data);

        // Wenn keine Regel vom Typ 'is_active' definiert war, dann ist dieses Widget auf jeden Fall sichtbar.
        const isActive = _.get(fulfillment, 'is_active', true);

        // Wenn keine Regel vom Typ 'is_not_active' definiert war, dann ist dieses Widget auf jeden Fall sichtbar.
        const isNotActive = _.get(fulfillment, 'is_not_active', false);

        // Wenn eine is_not_active Regel erfüllt ist, dominiert diese
        return isActive && !isNotActive;
    };

    self.isItfShop = function () {
        return shopService.isItfShop(self.data.shop);
    };

    self.isOnsite = function () {
        return _.get(self.data, 'shop.isOnsite', false);
    };

    self.showTimeout = function () {
        return _.get(self.data, 'shop.showTimeoutInFrontend', false);
    };

    const findSeatingItem = () => {
        return _.get(self.data, 'order.mainItems', []).find(item => Object.keys(item).findIndex(key => key === 'seatingData') !== -1);
    };

    self.containsSeating = () => {
        return _.has(self.data, 'seatingConfig');
    };
    /**
     * Die seatingData beinhaltet den Seating-Betriebsmodus. Diese Methode testet
     * den Modus auf den PYT-Sonderfall. Definiert werden die Modi in der
     * EventSeatingConfiguration der API, sowie in common/constants.js
     *
     * @returns {boolean}
     */
    self.usesPytMode = () => {
        const seatingItem = findSeatingItem();

        return !!seatingItem && !!seatingItem.seatingData && PYT_MODES.includes(seatingItem.seatingData.mode);
    };

    // Testen, ob sich die Order in prePayment/erstem Schritt befindet
    self.isPrePaymentStage = () => {
        return _.get(self.data, 'order.status', '') === 'OPENED';
    };

    self.getOrderTimeout = function () {
        if (!self.showTimeout()) {
            return;
        }
        const orderTimeout = _.get(self.data, 'order.timeoutInSeconds', 0);
        return orderTimeout >= 0 ? orderTimeout : 0;
    };

    self.jumpBackToSeating = () => {
        window.location.assign(lmAppConfig.api.seatingFrontendUrl + window.location.pathname + '/seating-shop');
    };

    self.isPaymentNecessary = () => {
        return !_.get(self.data, 'shop.isAfterPayment', false);
    };

    /**
     * Ermittel ob es sich bei dem übergebenen Schritt um den letzten Schritt im Kaufprozess handelt.
     */
    self.isLastStep = function (step) {
        return _.last(getActiveSteps()).id === step.id;
    };

    self.getItemUnitPrice = function (ident) {
        return _.get(lmItemRegistry.getItemInformation(ident), 'priceInCents', 0);
    };

    self.getCartTotal = function () {
        function getItemTotal(items) {
            return _(items).map(function (entries, ident) {
                return self.getItemUnitPrice(ident) * entries.length + _(entries).map(function (entry) {
                    return getItemTotal(entry.items);
                }).sum();
            }).sum();
        }

        return getItemTotal(self.data.model.items);
    };

    self.applyContactData = function (contactData) {
        _.set(self.data, 'meta', {applyPersonData: contactData});
        return self.refreshShop();
    };

    const itemSelections = {};

    function initItemSelection(path, itemSelection) {
        itemSelections[path] = {
            active: itemSelection,
            registered: [itemSelection]
        };
    }

    self.registerItemSelection = function (path, itemSelection) {
        if (!(path in itemSelections)) {
            initItemSelection(path, itemSelection);
        } else {
            itemSelections[path].registered.push(itemSelection);
        }
    };

    self.getActiveItemSelection = function (path) {
        return (path in itemSelections) ? itemSelections[path].active : undefined;
    };
    self.getItemSelectionCount = function (path) {
        return (path in itemSelections) ? itemSelections[path].registered.length : 0;
    };

    self.setActiveItemSelection = function (path, itemSelection) {
        if (!(path in itemSelections)) {
            initItemSelection(path, itemSelection);
        } else {
            itemSelections[path].active = itemSelection;
        }
    };

    // --- Gutscheine - BEGIN ---

    // Nicht schön das auch noch hier unterzubringen, aber das ist vorerst die minimalinvasive
    // Art um diese Funktionalität nicht über meherer Komponenten verteilen zu müssen.

    self.allowVoucherRedemption = () => {
        return _.get(self.data, 'shop.allowVoucherRedemption', false);
    }

    self.hasAppliedVouchers = () => {
        return _.some(self.data.model.vouchers, c => c.status === VoucherStatus.APPLIED);
    }

    self.hasInvalidVouchers = () => {
        return _.some(self.data.model.vouchers, c => c.status === VoucherStatus.INVALID);
    }

    self.discardInvalidVouchers = () => {
        _.remove(self.data.model.vouchers, c => c.status === VoucherStatus.INVALID);
    }

    self.discardIgnoredVouchers = () => {
        _.remove(self.data.model.vouchers, c => c.status === VoucherStatus.IGNORED);
    }

    self.getAppliedVouchers = () => {
        return self.data.model.vouchers.filter(c => c.status === VoucherStatus.APPLIED);
    }

    self.addVoucherCode = (code) => {
        self.data.model.vouchers.push({
            code: code,
            status: VoucherStatus.ENTERED
        })

        return self.refreshShop()
    };

    self.removeVoucher = (voucher) => {
        _.pull(self.data.model.vouchers, voucher);

        return self.refreshShop()
    }

    /**
     * Ermittelt den effektiven Gesamtwert des aktuellen Warenkorbs
     * unter Berücksichtigung evtl. angewendeter Gutscheine.
     */
    self.getEffectiveCartTotal = () => {
        const appliedVoucherAmount = _.sumBy(self.getAppliedVouchers(), 'appliedAmount');

        return self.getCartTotal() - appliedVoucherAmount;
    }

    /**
     * Die von den einzelnen Gutscheinen anzuwendenden Werte neu berechnen.
     *
     * Diese Methode implementiert die gleiche Logik wie VoucherService::calculateAmountReservation() in der API.
     */
    self.recalculateAppliedVoucherAmounts = () => {
        let total = self.getCartTotal();
        const appliedVouchers = self.getAppliedVouchers();

        appliedVouchers.forEach(voucher => {
            if (total <= 0) {
                // Wenn der Betrag bereits vollständig gedeckt ist, können alle weiteren Gutscheine ignoriert werden.
                voucher.status = VoucherStatus.IGNORED;
                return;
            }

            // Wenn total >= available, dann den verbleibenden Wert zu 100% anwenden.
            voucher.appliedAmount = Math.min(total, voucher.availableAmount);

            total -= voucher.appliedAmount;
        });

        // Sollten einige Gutscheine nun nicht mehr notwendig sein, dann können wir sie entfernen,
        // damit sie nicht unerwartet beim nächsten submit wieder auftauchen.
        self.discardIgnoredVouchers();
    }

    // --- Gutscheine - END ---

    self.$onInit = function () {
        // Für den Fall, dass bereits Daten vorhanden sind zeigen wir alle Schritte sofort an, damit der Benutzer nicht
        // nochmal alle Schritte durchlaufen muss, wenn er bspw. von einer abgebrochenen Zahlung zurückkehrt.
        const stepsRevealed = _.get(self.data, 'model.hasSubmitData') ? StepVisibility.ALL : StepVisibility.FIRST;

        initializeProcessSteps(stepsRevealed);

        if (self.isMonolingual()) {
            self.forceAvailableLanguage();
        }

        setupSyncingOfCallingCodeWithCountrySelection('user.address', 'user.phone');
        setupSyncingOfCallingCodeWithCountrySelection('billing.address', 'billing.phone');

        // Methode immer aufrufen, da andernfalls Binding der Personendaten von AccessCodes nicht funktioniert
        // -> Wahrscheinlich, weil Model nicht richtig initialisiert und hierdurch alles einmal mindestens touched wird
        initFieldsForAccessCodeBinding();

        if (keycloakUserProfile) {
            prefillCustomerDataFromUserProfile();
        }

        // Wenn sich der Inhalt des Warenkorbs ändert müssen auch die anzuwendenden Beträge der Gutscheine neu
        // berechnet werden. Eine Option wäre einfach wie auch beim hinzufügen/entfernen von Gutscheinen eine
        // self.refresh() auszuführen, jedoch müssten wir dazu mit sinnvoller Granularität wissen,wann sich der
        // WK ändert und aktuell gibt es keine zentrale Stelle, um dies zu tun und mit einem $watch mit
        // objectEquality == true bekommen wir eine Änderung auch dann signalisiert, wenn der Benutzer bspw.
        // einfach die Personalisierung einer Leistung ändert, daher wäre es nicht gangbar dann jedesmal ein
        // refresh auszuführen.
        //
        // Alternativ behelfen wir uns damit die Neuberechnung lokal mit den vorliegenden Daten durchzuführen.
        // Das bedeutet aber auch, dass die Logik hierzu hier im Frontend dupliziert vorliegt und es muss darauf
        // geachtet werden, dass die beiden Implementierungen nicht divergieren.
        $rootScope.$watch(() => self.data.model.items, () => {
            // Der Listener wird auch beim initialen Render aufgerufen und es würde dadurch alle evtl. in der
            // Session vorhandenen Gutscheine entfernt, da der WK noch nicht korrekt initialisiert ist,
            // daher behelfen wird uns einfach mit einem Flag um die "erste Runde" zu skippen.
            if (self.isFirstVoucherRecalculation) {
                return self.isFirstVoucherRecalculation = false;
            }

            self.recalculateAppliedVoucherAmounts();
        }, true);
    };
}

module.exports = {
    templateUrl: 'shop/shop.component.html',
    controller: ShopComponentController,
    bindings: {
        data: '<'
    },
    require: {
        form: '^'
    }
};
