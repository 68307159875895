'use strict';

const moment = require('moment-timezone');

require('angular').module('leipzigerMesse.ticketShop.frontend.shop').component('lmOrderExpirationTimer', {
    templateUrl: 'shop/shop.orderExpirationTimer.component.html',
    controller: function ($interval, lmAppConfig) {
        'ngInject';

        const self = this;

        self.refreshTimeout = () => {
            self.orderTimeout--;
            self.timeoutDisplay = moment.utc(self.orderTimeout * 1000).format('mm:ss');
            if (!self.orderTimeout) {
                $interval.cancel(self.intervalId);
            }
        };

        self.$onInit = () => {
            if (self.orderTimeout) {
                self.intervalId = $interval(self.refreshTimeout, 1000);
            }
        };

        self.$onDestroy = () => {
            $interval.cancel(self.intervalId);
        };

        self.reloadShop = () => {
            if (self.containsSeating) {
                window.location.assign(lmAppConfig.api.seatingFrontendUrl + window.location.pathname + '/seating-shop');
            } else {
                window.location.reload();
            }
        };
    },
    bindings: {
        orderTimeout: '<',
        containsSeating: '<'
    }
});
