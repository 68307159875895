'use strict';

const _ = require('lodash');
const angular = require('angular');

/**
 * @ngInject
 */
function Order($resource, apiConfiguration, $http, $log) {
    const endpointUrl = apiConfiguration.baseUrl + '/order/:id';

    const orderResource = $resource(endpointUrl, {id: '@id'}, {
        save: {
            method: 'POST',
            url: endpointUrl,
            transformRequest: function (data, headersGetter) {
                // Die Shop-Definition nicht komplett schicken, die API benötigt nur
                // die ID um den Shop zu bestimmen, zu dem diese Order gehört.
                return angular.toJson(_.defaults(_.pick(data, 'shop.id'), data));
            }
        },
        removeSeatingItem: {
            method: 'POST',
            url: apiConfiguration.baseUrl + '/order/cancelItem/:id',
            transformRequest: function (data, headersGetter) {
                // Die Shop-Definition nicht komplett schicken, die API benötigt nur
                // die ID um den Shop zu bestimmen, zu dem diese Order gehört.
                return angular.toJson(_.defaults(_.pick(data, 'shop.id'), data));
            }
        }
    });

    orderResource.prototype.markCompletionTracked = function () {
        $http.get(apiConfiguration.baseUrl + '/order/markCompletionTracked/' + this.id).then(() => {
            $log.info('Completion of order tracked and marked.');
        });
    };

    return orderResource;
}

module.exports = Order;
