'use strict';

var _ = require('lodash');

/**
 * @ngInject
 */
function CheckboxChoiceGroupComponentController($log) {
    var self = this;

    var choicesByIndex = {};

    function render() {
        choicesByIndex = {};

        _.each(self.model.$viewValue || [], function (choice) {
            choicesByIndex[choice.index] = _.clone(choice);
        })
    }

    self.isSelected = function (choice) {
        return !_.isUndefined(choicesByIndex[choice.index]);
    };

    self.updateChoiceSelection = function (event) {
        if (event.selected) {
            choicesByIndex[event.choice.index] = _.clone(event.choice);
        }
        else {
            choicesByIndex[event.choice.index] = undefined;
        }

        self.model.$setViewValue(_.compact(_.values(choicesByIndex)));
    };

    self.$onInit = function () {
        self.model.$render = render;

        // Da das Model mit einer Liste von Choices befüllt wird, müssen wir
        // $isEmpty selbst definieren, damit auf leere Listen geprüft wird.
        self.model.$isEmpty = _.isEmpty
    };
}

module.exports = {
    templateUrl: 'widget/form/checkbox-choice-group.component.html',
    controller: CheckboxChoiceGroupComponentController,
    require: {
        model: 'ngModel'
    },
    bindings: {
        choices: '<',
        // es muss ein anderer Name als "disabled" verwendet werden, da sonst im IE das
        // Element und seine Kinder immer disabled sind, wenn das Attribut gesetzt ist.
        isDisabled: '<?'
    }
};
