"use strict";

var _ = require('lodash');

require('angular').module('leipzigerMesse.ticketShop.frontend.item').service('lmItemRegistry', function () {

    var itemInformationByIdent = {};

    /**
     * Registriert die Informationen der durch das übergebene Widget repräsentierten Leistung.
     *
     * Nach der Registrierung können die Informationen über getItemInformation() abgerufen werden.
     * Leistungen werden anhand ihreres Ident identifiziert, daher werden bei merhmaliger Registierung
     * von Informationen mit dem gleichen Ident evtl. bereits vorhanden Informationen überschrieben
     *
     *  @param {Widget} itemWidget - Widget das die Leistung repräsentiert, deren Informationen registriert werden sollen.
     */
    this.registerItemInformation = function (itemWidget) {
        itemWidget = _.cloneDeep(itemWidget);

        var info = _.pick(itemWidget, ['ident', 'type', 'priceInCents', 'vatPercent']);
        _.assign(info, _.pick(itemWidget.layout, ['label', 'freeOfChargeLabel']));

        itemInformationByIdent[info.ident] = info;
    };

    /**
     * Liefert die unter dem angegebenen Ident registrierte Leistungsinformationen sofern vorhande.
     *
     * @param {string} ident - Der Ident der gesuchten Leistungsinformationen.
     *
     * @return {object} Die zum Ident gehörenden Leistungsinformationen wenn vorhanden, andernfalls undefined.
     */
    this.getItemInformation = function (ident) {
        return itemInformationByIdent[ident];
    };

});