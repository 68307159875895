'use strict';

var _ = require('lodash');

require('angular').module('leipzigerMesse.ticketShop.frontend.shop').component('lmShopStep', {
    controller: function ShopStepComponentController($log, $scope, $analytics) {
        'ngInject';

        var self = this;

        function trackStepCompletion() {
            $analytics.transactionTrack('checkout', {
                checkout: {
                    actionField: {step: self.index + 1}
                }
            });
        }

        self.getShortLabel = function () {
            return _.get(self.widget, 'layout.shortLabel', {});
        };

        self.isComplete = function () {
            return self.form.$valid && self.form.$dirty;
        };

        self.isIncomplete = function () {
            return self.form.$invalid && (self.form.$dirty || self.shop.wasSubmitted());
        };

        self.isValid = function () {
            return self.form.$valid;
        };

        self.isLastStep = function () {
            return self.shop.isLastStep(self);
        };

        self.isSubmitAllowed = function () {
            return self.shop.data.isSubmitAllowed();
        };

        self.proceed = function () {
            self.shop.proceed(self);
        };

        self.$onInit = function () {
            // Die Komponente übernimmt die ID des Widgets, dessen Repräsentation sie ist.
            self.id = self.widget.id;

            $log.debug('step #%s "%s" (%s) initialized', self.index + 1, self.getShortLabel().de, self.widget.id);
            self.shop.addStepComponent(self);

            // wenn das Formular valide wird, dann erachten wir diesen Schritt als abgeschlossen.
            $scope.$watch(function () {
                return self.form.$dirty && self.form.$valid;
            }, function (valid) {
                if (valid) {
                    trackStepCompletion();
                }
            });
        };

        self.$onDestroy = function () {
            $log.debug('step #%s "%s" (%s) destroyed', self.index + 1, self.getShortLabel().de, self.widget.id);

            self.shop.removeStepComponent(self);
        };
    },
    templateUrl: 'shop/shop.step.component.html',
    bindings: {
        widget: '<',
        order: '=',
        first: '<',
        stepNumber: '<',
        visible: '<'
    },
    require: {
        form: '',
        shop: '^^lmShop'
    }
});
