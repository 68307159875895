'use strict';

class SocialShareButtonsWidgetComponentController {

}

require('angular').module('leipzigerMesse.ticketShop.frontend.widget').component('lmSocialShareButtonsWidget', {
    templateUrl: 'widget/widget.socialShareButtons.component.html',
    controller: SocialShareButtonsWidgetComponentController,
    bindings: {
        widget: '<'
    }
});
