'use strict';

var _ = require('lodash');

/**
 * @ngInject
 */
function trustHtmlFilter($sce) {
    return function (untrustedValue) {
        return _.isString(untrustedValue) ? $sce.trustAsHtml(untrustedValue) : untrustedValue;
    };
}

module.exports = trustHtmlFilter;