'use strict';

var _ = require('lodash');

/**
 * @ngInject
 */
function ShopTemplatePageComponentController(shopService) {

    var self = this;

    self.isMonolingual = function () {
        // Es wird fest davon ausgegegangen, dass nur Deutsch und Englisch unterstützt werden,
        // daher reicht es hier aus zu prüfen, ob min. eine der Sprachen nicht verfügbar ist.
        return !_.every(self.shop.allowedDisplayLanguages);
    };

    self.isItfShop = function () {
        return shopService.isItfShop(self.shop);
    };

    self.getTemplate = function () {
        return self.shop.templateContainer[self.template];
    };

}

module.exports = {
    templateUrl: 'shop/shop.templatePage.component.html',
    controller: ShopTemplatePageComponentController,
    bindings: {
        shop: '<',
        template: '@'
    }
};