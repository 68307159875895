'use strict';

const _ = require('lodash');

/**
 * Komponente zeigt dem Kunden ein Uploadfeld, in dem er genau eine Datei hochladen kann.
 * Diese Datei ist speziell im Rahmen der Akkreditierung gedacht. Der Kunde soll ein
 * Portrait Bild / Foto ID von sich hochladen, was im weiteren Verlauf benutzt werden kann.
 * (Ausdruck auf A6 Badge)
 * Wenn der Kunde ein Mobile hat, sollte er auch mit der nativen Funktion ein Bild
 * hochladen können.
 *
 * @ngInject
 */
function AccreditationPhotoIdComponentController($log, Upload, apiConfiguration) {
    const self = this;

    self.isFileTooLarge = false;
    self.isUploadInProgress = false;

    self.internalModel = {};

    self.error = undefined;

    self.upload = {
        file: undefined,
        inProgress: false
    };

    function render() {
        self.internalModel = _.cloneDeep(self.model.$viewValue) || {};
    }

    function isEmpty(value) {
        if (_.isEmpty(value)) {
            return true;
        }

        return _.isEmpty(_.get(self.internalModel, 'files'));
    }

    self.$onInit = function () {
        self.model.$render = render;
        self.model.$isEmpty = isEmpty;
    };

    self.isUploadDisabled = function () {
        return self.isFileTooLarge
            || self.upload.file !== undefined
            || self.upload.inProgress
            || !_.isEmpty(_.get(self.internalModel, 'files'))
    };

    self.selectFile = function (file) {
        // Callback für ngf-select oder ngf-change wird auch aufgerufen, wenn Dialog ein weiteres mal
        // geöffnent wird, aber bereits bevor eine Datei ausgewählt wurde. In diesem Fall ist $file == null.
        if (file === null) {
            return;
        }

        self.upload.file = file;

        if (self.widget.maxFileSize && (file.size > self.widget.maxFileSize)) {
            self.isFileTooLarge = true;
        } else {
            self.isFileTooLarge = false;
            self.error = undefined;
            self.uploadFile();
        }
    };

    self.getDownloadUrl = file => {
        return `${apiConfiguration.baseUrl}/file/download/${file.id}`;
    };

    self.onChange = function () {
        self.model.$setViewValue(_.cloneDeep(self.internalModel));
    };

    self.uploadFile = function () {
        self.upload.inProgress = true;

        Upload.upload({
            url: apiConfiguration.baseUrl + '/file/upload',
            data: _.assign({
                widgetId: self.widget.id,
                shopId: self.shop.data.shop.id
            }, self.upload)
        }).then(function (result) {
            self.upload.file = undefined;
            self.internalModel.files = self.internalModel.files || [];
            self.internalModel.files.push(result.data);
            self.onChange();
        }).catch(function (result) {
            self.error = result.data;

            $log.error('An error occured while uploading file', self.upload.file.name, self.error);

            // In den Fällen in denen keine lokalisierten Fehlermeldungen vorliegen zeigen wir eine
            // generische Meldung an, damit der Benutzer wenigstens erfährt, dass etwas schiefgelaufen ist.
            self.error.message = _.assign({
                de: 'Es ist ein Fehler aufgetreten',
                en: 'An Error occurred'
            }, self.error.message);
        }).finally(function () {
            self.upload.inProgress = false;
        });
    };

    self.removeFile = function (file) {
        _.remove(self.internalModel.files, file);
        self.onChange();
    };
}

module.exports = {
    templateUrl: 'widget/form/accreditationPhotoId.component.html',
    controller: AccreditationPhotoIdComponentController,
    require: {
        model: 'ngModel',
        shop: '^^lmShop'
    },
    bindings: {
        widget: '<',
        isDisabled: '<'
    }
};
