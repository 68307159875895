'use strict';

var _ = require('lodash');

/**
 * @ngInject
 */
function PersonTypeSelectionComponentController($log) {
    var self = this;

    self.selectedOption = null;
    self.loading = false;

    self.submit = function () {
        self.loading = true;
        self.shop.refreshShop().finally(function () {
            self.loading = false;
        });
    };

    self.select = function (item) {
        self.model.$setViewValue(item);
        self.model.$render();

        self.submit();
    };
    self.deselect = function () {
        self.model.$setViewValue();
        self.model.$render();

        self.submit();
    };

    self.$onInit = function () {
        self.model.$render = function render() {
            self.selection = self.model.$viewValue;
        };
    };
}

module.exports = {
    templateUrl: 'widget/form/personTypeSelection.component.html',
    controller: PersonTypeSelectionComponentController,
    bindings: {
        shop: '<',
        widget: '<'
    },
    require: {
        model: 'ngModel'
    }
};
