'use strict';

const _ = require('lodash');
const {PYT_MODES} = require('../../common/constants');

/**
 * @ngInject
 */
function TicketItemWidgetController($scope, $rootScope, widget, order, ShopModel, shop, parent, isActive, $log, shopService, Order) {
    const self = this;

    self.widget = undefined;
    self.shop = undefined;
    self.items = undefined;

    /**
     * Erstellt das Model für das Item
     *
     * @returns {*}
     */
    function createItemModel() {
        const itemModel = new ShopModel({});

        // Bei onsite-Verkäufen die Käuferdaten als Teilnehmerdaten übernehmen
        // als Eingabeerleichterung, da im Regefall gilt Käufer == Teilnehner.
        if (shop.isOnsite()) {
            shopService.applyOrderFormDataToItemModel(order, itemModel);
        }

        return itemModel;
    }

    /**
     * Versucht die Anzahl der hier ausgewählten Leistungen einem potentiellen Vater-Widget mitzuteilen.
     *
     * @param amount
     */
    function registerItemAmountWithParent(amount) {
        _.attempt(function () {
            parent.registerItemAmount(self.widget.ident, amount);
        });
    }

    /**
     * Prüft ob diese Leistung ein Limit an auswählbaren Leistungen hat.
     *
     * @returns {value is number}
     */
    self.hasMaxQuantity = () => {
        return _.isNumber(_.get(self.widget, 'maxOrderItemCount'));
    };

    /**
     * Gibt das Limit an auswählbaren Leistungen oder INFINITY zurück.
     *
     * @returns {undefined|number}
     */
    self.getMaxQuantity = () => {
        // Begrenzung der ausw.nameählbaren Anzahl ist optional, daher ist dieser Wert ggf. nicht gesetzt.
        const maxOrderItemCount = _.get(self.widget, 'maxOrderItemCount');
        return _.isNumber(maxOrderItemCount) ? maxOrderItemCount : Number.POSITIVE_INFINITY;
    };

    /**
     * Prüft, ob die maximale Anzahl an auswählbaren Leistungen überschritten wurde.
     *
     * @returns {boolean}
     */
    self.isMaxQuantitySelected = () => {
        return self.quantity() >= self.getMaxQuantity();
    };

    /**
     * Gibt an, ob eine Leistung auswählbar ist, wenn ein Maximum gesetzt ist und ob dieses > 0 ist.
     *
     * @returns {boolean}
     */
    self.isSelectable = () => {
        return self.getMaxQuantity() > 0;
    };

    /**
     * Erhöht die Anzahl der ausgewählten Tickets um 1.
     */
    self.increaseQuantity = function () {
        self.quantity(self.quantity() + 1);
    };

    /**
     * Gibt zurück, ob für diese Leistung mindestens ein Item ausgewählt wurde
     *
     * @returns {boolean}
     */
    self.hasAtLeastOneItem = () => {
        return self.quantity() > 0;
    };

    /**
     * getter/setter für die Menge der Leistung. Fungiert bei Aufruf ohne Argumente als getter, andernfalls als setter.
     *
     * @param {number} quantity - Die neue Menge der Leistung.
     *
     * @return {number} Die aktuelle/neue Menge der Leistung.
     */
    self.quantity = function (quantity) {
        const currentQuantity = self.items.length;

        // Aufruf ohne Argumente -> Aufruf als getter; übrige Logik muss und *soll* nicht mehr ausgeführt werden.
        if (_.isEmpty(arguments)) {
            return currentQuantity;
        }

        if (quantity < 0) {
            throw new Error('quantity must not be negative');
        }

        // Pflichtleistungen sind automatisch genau 1x im Warenkorb
        // jede Änderung der Anzahl zu einer anderen Zahl ist ungültig
        if (self.widget.displayStatus === 'REQUIRED') {
            // Da auch Unterleistungen Pflichtleistungen reicht es nicht aus einfach von der Shop-Wurzel aus
            // zu prüfen ob eine aktive Leistung mit dem gesuchten Ident erreichbar ist, denn es könnte sich
            // dann um eine Unterleistung einer anderen Oberleistung handeln. Daher wenn vorhanden immer vom
            // Parent aus suchen.
            const dataRoot = _.get(parent, 'widget', _.get(self, 'shop.data.shop.widgetContainer'));

            quantity = shopService.hasActiveItemWidgetForIdent(self.shop.data, self.widget.ident, dataRoot) ? 1 : 0;
        }

        quantity = Math.min(quantity, self.getMaxQuantity());

        if (currentQuantity < quantity) {
            // Wenn die Menge erhöht wurde den soviele Objekte anfügen wie nötig.
            _.times(quantity - currentQuantity, function () {
                self.items.push(createItemModel());
            });
        } else {
            // Wenn die Menge verringert wurde den Überstand am Ende entfernen.
            self.items.splice(quantity, (currentQuantity - quantity));
        }

        // Evtl. umgebende Gruppen über geänderte Menge informieren.
        registerItemAmountWithParent(quantity);

        return quantity;
    };

    /**
     * Entfernt die Auswahl einer Leistung.
     * Im Falle von SeatingLeistungen wird ein Call an die API getätigt, welcher die zugehörigen Plätze wieder freigibt.
     *
     * @param index
     */
    self.removeItem = function (index) {
        if (self.widget.displayStatus === 'REQUIRED') {
            // Bei Pflichtleistungen ist die Anzahl nicht veränderbar
            return;
        }

        const item = self.items[index];

        if (item.seatingData && !shopService.areSeatingMinMaxQuantitiesFulfilled(self.shop.data, -1)) {
            // Wenn nach dem Löschen die MinMax-Bestellmengen nicht mehr erfüllt würden
            // das Löschen verhindern
            $rootScope.$broadcast('cart:removeSeatingItemNotAllowed');
            return;
        }

        self.items.splice(index, 1);
        registerItemAmountWithParent(self.items.length);

        if (item.inviteCode) {
            shop.removeCode(item.inviteCode);
        }

        if (item.seatingData) {
            // Das Item muss gecanceled werden, damit der Platz wieder freigegeben wird
            Order.removeSeatingItem({id: item.id}).$promise.then(() => {
                // Das Item muss zusätzlich aus den Order-Items entfernt werden, da es ansonsten noch im Warenkorb ist
                _.remove(self.shop.data.order.mainItems, (modelItem) => {
                    return modelItem.id === item.id;
                });
                // Das Item muss zusätzlich auch aus dem Shop-Model entfernt werden, da es ansonsten noch im Warenkorb ist
                _.remove(_.get(self.shop.data.model.items, item.ident, []), (modelItem) => {
                    return modelItem.id === item.id;
                });
                // Broadcast um den Seating-Leistungswidget mitzuteilen, dass eine Leistung entfernt wurde
                $rootScope.$broadcast('cart:removeSeatingItem', {items: self.items});
            });
        }
    };

    /**
     * Wählt eine Leistung aus.
     */
    self.selectItem = function () {
        self.quantity(1);
    };

    /**
     * Wählt eine Leistung ab.
     */
    self.deselectItem = function () {
        self.quantity(0);
    };

    /**
     * @param item
     * @returns {*}
     */
    self.selectedItem = function (item) {
        return parent && parent.selectedItem(item);
    };

    /**
     * Gibt die ID eines potentiellen Vater-Gruppen-Widgets zurück.
     *
     * @returns {*}
     */
    self.getGroupId = function () {
        return parent && parent.id;
    };

    /**
     * Die seatingData beinhaltet den Seating-Betriebsmodus. Diese Methode testet
     * den Modus auf den PYT-Sonderfall. Definiert werden die Modi in der
     * EventSeatingConfiguration der API, sowie in common/constants.js
     *
     * @param seatingData
     * @returns {boolean}
     */
    self.usesPytMode = (seatingData) => {
        return PYT_MODES.includes(seatingData.mode);
    };

    /**
     * Weißt die Leistungen einer Order den State-Variablen dieses Widgets zu.
     *
     * @param items
     */
    self.assignItems = (items) => {
        // Prüfen, ob es sich um eine Seating Leistung handelt und View-Daten mit Seating-Daten anreichern
        const orderItems = _.filter(shop.data.order.mainItems, (item) => item.ident === widget.ident);
        if (orderItems && orderItems.length > 0) {
            self.items = _.map(items, (item, index) => {
                return _.merge(item, {
                    id: orderItems[index].id,
                    ident: orderItems[index].ident,
                    seatingData: orderItems[index].seatingData
                });
            });
        } else {
            // Items veröffentlichen für view
            self.items = items;
        }

        registerItemAmountWithParent(items.length);
    };

    /**
     * Methode welche steuert, ob das gesammte Widget angezeigt wird oder nicht.
     *
     * @returns {boolean}
     */
    self.isVisible = () => {
        // Sichtbarkeit über das generische widget.component.js::isActive()
        const active = _.isFunction(isActive) ? isActive() : true;
        // Wenn keine Leistung mehr ausgewählt ist, soll das Seating-Widget auch nicht mehr angezeigt werden
        const atLeastOneItem = self.hasAtLeastOneItem();

        return active && atLeastOneItem;
    };

    /**
     * Broadcast-Listener, welcher wenn eine Seating-Leistung aus dem Warenkorb entfernt wird, diese auch an dem
     * Leistungs-Widget entfernt.
     */
    $scope.$on('cart:removeSeatingItem', (event, args) => {
        if (self.items) {
            self.items = self.items.filter((item) => {
                return _.find(_.get(args, 'items', []), (broadcastItem) => {
                    if (broadcastItem.id) {
                        return broadcastItem.id === item.id;
                    }
                    return false;
                });
            });
        }
    });

    self.$onInit = () => {
        self.widget = widget;
        self.shop = shop;

        order.items = order.items || {};
        order.items[widget.ident] = order.items[widget.ident] || [];
        // order.items ist ein assoziatives Array aus Leistungsident -> Leistungs-Widget
        const items = order.items[widget.ident];
        self.assignItems(items);

        if (self.widget.displayStatus === 'REQUIRED') {
            $scope.$watch(function () {
                return isActive();
            }, function (newVal, oldVal) {
                // Pflichtleistungen sind automatisch genau 1x im Warenkorb
                // wir setzen die Anzahl daher immer auf genau diesen Wert
                self.quantity(newVal ? 1 : 0);
            });

            $scope.$on('$destroy', function () {
                self.quantity(isActive() ? 1 : 0);
            });
        }
    };
}

module.exports = TicketItemWidgetController;
