'use strict';

// als erstes requiren, damit die polyfills für den restlichen code verfügbar sind.
require('babel-polyfill');

const Keycloak = require('keycloak-js');

// build-time Konfiguration verfügbar machen
const LM_APP_CONFIG = require('lm-app-config');

const angular = require('angular');
// Locale Spezifische konfiguration für AngularJS einbinden.
// Dies muss nach require('angular') stehen.
require('angular-i18n/angular-locale_de-de');

const _ = require('lodash');
const {PYT_MODES} = require('./common/constants');

const app = angular.module('leipzigerMesse.ticketShop.frontend', [
    // Angular-Core
    require('angular-resource'),
    require('angular-animate'),
    require('angular-cookies'),
    require('angular-sanitize'),

    // 3rd party Module
    require('angular-scroll'),
    require('angular-translate'),
    require('angular-translate-storage-cookie'),
    require('angular-ui-bootstrap'),
    require('angular-ui-validate') && 'ui.validate',
    require('@uirouter/angularjs').default,
    require('angular-ui-scrollpoint') && 'ui.scrollpoint',
    require('ng-file-upload'),
    require('ng-device-detector'),

    // Eigene Module
    require('./debug').name,
    require('./data').name,
    require('./example').name,
    require('./api'),
    require('./auth'),
    require('./analytics').name,
    require('./root').name,
    require('./i18n'),
    require('./common').name,
    require('./widget').name,
    require('./contact').name,
    require('./shop').name,
    require('./onsite').name,
    require('./order').name,
    require('./template').name,
    require('./google').name,
    require('./error')
]);

app.constant('lmAppConfig', LM_APP_CONFIG);
app.constant('PYT_MODES', PYT_MODES);

// Anwendungsglobale Konfiguration
app.config(function ($logProvider,
                     $locationProvider,
                     $urlRouterProvider,
                     $translateProvider,
                     $httpProvider,
                     lmAppConfig,
                     apiConfigurationProvider) {
    apiConfigurationProvider.config.baseUrl = lmAppConfig.api.baseUrl;
    apiConfigurationProvider.config.authEndpointUrl = lmAppConfig.api.authEndpointUrl;

    $locationProvider.html5Mode(true);

    // Fallback für alle unbekannten Routen
    $urlRouterProvider.otherwise('/');

    $translateProvider
        // nur die Parameter sanitizen, da sonst Umlaute oder Sonderzeichen nicht korrekt
        // dargestellt werden, wenn diese im lokalisierten string auftauchen.
        .useSanitizeValueStrategy('sanitizeParameters')
        .useLoader('i18nTranslationLoader')
        .fallbackLanguage('en')
        .registerAvailableLanguageKeys(['de', 'en'], {
            'de_*': 'de',
            'en_*': 'en',
            '*': 'en' // alles andere auf Englisch abbilden
        })
        .determinePreferredLanguage()
        .useCookieStorage();

    $httpProvider.defaults.withCredentials = true;
    $httpProvider.interceptors.push(function ($q, $translate) {
        return {
            request: function (config) {
                if (config.url && _.startsWith(config.url, lmAppConfig.api.baseUrl)) {
                    if (!config.params) config.params = {};
                    config.params.language = $translate.use();
                }
                return config || $q.when(config);
            }
        };
    });

    $logProvider.debugEnabled(lmAppConfig.debug.enabled);
});

app.run(function ($rootScope, $log, $trace, $transitions, $state, lmAppConfig) {
    $log.debug('app configuration:', lmAppConfig);

    if (_.get(lmAppConfig, 'debug.enabled')) {
        $trace.enable('TRANSITION');
    }

    $transitions.onError({to: 'root.shop.*'}, function (transition) {
        if (_.get(transition.error(), 'detail.status') === 404) {
            $state.go('root.shopNotFound');
        }
    });
});

// Der Keycloak Adapter muss vor der AngularJS App initialisiert werden. Erst im Anschluss wird die App manuell bootstrapped.
angular.element(document).ready(function () {
    let alreadyBootstrapped = false;

    function bootstrapNgApp(keycloakAdapter, keycloakUserProfile) {
        // // Den Keycloak Adapter und Benutzerprofil in der AngularJS App verfügbar machen.
        app.constant('keycloak', keycloakAdapter);
        app.constant('keycloakUserProfile', keycloakUserProfile);

        angular.bootstrap(document, [app.name], {strictDi: true});

        alreadyBootstrapped = true;
    }

    // ggf. ist Keycloak nicht verfügbar. Daher spätestens nach Ablauf
    // des Timeouts die App ohne Keycloak Anbindung hochfahren.
    const keycloakFailoverBootstrap = setTimeout(bootstrapNgApp, LM_APP_CONFIG.keycloak.timeout * 1000);

    const keycloakAdapter = Keycloak(_.cloneDeep(LM_APP_CONFIG.keycloak));

    // ACHTUNG: Die Ergebnisse der Keycloak-Methoden sind, auch wenn die Doku davon spricht, keine echten
    // Promises, da sich die Handler nicht chainen lassen, es wird lediglich der letzte per success()
    // oder error() registrierte Handler ausgeführt.

    // Initial nur prüfen, ob der Benutzer eingeloggt ist, Login soll falls gewünscht explizit durchgeführt werden.
    keycloakAdapter.init({onLoad: 'check-sso'}).success(function (isAuthenticated) {
        // Applikation kann mit Keycloak Anbindung hochgefahren werden, das Failoververhalten unterbinden.
        clearTimeout(keycloakFailoverBootstrap);

        // Es hat ggf. zu lange gedauert Keycloak zu initialisieren dann gibts einfach keine Keycloak Anbindung.
        if (alreadyBootstrapped) {
            window.console && window.console.warn('initialization of Keycloak timed out');
            return;
        }

        window.console && window.console.info('initialization of Keycloak adapter successful; ' + (isAuthenticated ? 'user is already authenticated' : 'user is not yet authenticated'));

        if (isAuthenticated) {
            keycloakAdapter.loadUserProfile()
                .success(function (userProfile) {
                    bootstrapNgApp(keycloakAdapter, userProfile);
                })
                .error(function (reason) {
                    window.console && window.console.warn('failed to load Keycloak user profile: ' + reason);
                    bootstrapNgApp(keycloakAdapter);
                });
        } else {
            bootstrapNgApp(keycloakAdapter);
        }
    }).error(function (reason) {
        // Dieser Fehlerfall scheint niemals einzutreten. Wenn irgendwas schiefläuft, Server nicht erreichbar,
        // Daten falsch, etc... findet der Redirect zum Ticketshop gar nicht erst statt.
        window.console && window.console.error('initialization of Keycloak adapter failed: ' + reason);
        bootstrapNgApp(keycloakAdapter);
    });
});

app.factory('$exceptionHandler', ($window, $log) => {
    return (...params) => {
        // Sofern möglich Fehler direkt mit console.error loggen, denn nur so werden
        // die stack traces über die source maps rückaufgelöst, so dass wir Verweise
        // in die originalen Quellen bekommen anstatt nur in das minifizierte bundle.
        // -> https://github.com/angular/angular.js/issues/15590#issuecomment-278302504
        ($window.console || $log).error(...params);
    };
});
