"use strict";

module.exports = function (ngModule) {
    ngModule.service('ContactSuggestion', function ($resource, apiConfiguration) {
        'ngInject';

        return $resource(apiConfiguration.baseUrl + '/shop/findKnownPersons', {}, {
            query: {
                method: 'POST',
                isArray: true
            }
        });
    });
};
