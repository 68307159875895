'use strict';

const _ = require('lodash');

/**
 * Bestimmt den delay für den periodischen reload der Order-Daten, abhängig von der bereits erfolgten Anzahl an reloads.
 */
function determineOrderReloadDelay(orderReloadCount) {
    // Die ersten drei Versuche im Abstand von jeweils 5s vornehmen, denn bei
    // keiner/normaler Last sollte die Tickets relativ zügig verfügbar sein.
    if (orderReloadCount < 3) {
        return 5000;
    }
    // Danach die Reloads stärker verzögern, da es wahrscheinlich aufgrund von
    // Last länger dauert, Tickets zu erzeugen, daher bringt es auch nicht
    // viel ständig zu pollen und somit zusätzlich Last auf den App Servern zu
    // verursachen.
    if (orderReloadCount < 10) {
        return 10000;
    }
    if (orderReloadCount < 20) {
        return 30000;
    }

    // Wenn die Tickets immer noch nicht vorhanden sind, dann auf einen 1m delay gehen,
    // denn es könnte lang werden...
    return 60000;
}

require('angular').module('leipzigerMesse.ticketShop.frontend.widget').component('lmThankYouWidget', {
    templateUrl: 'widget/widget.thankYou.component.html',
    controller: function ($window, repeat, $state, $uiRouterGlobals, $translate, $analytics, apiConfiguration, Order) {
        'ngInject';

        const self = this;

        /**
         * Erzeugt ein Objekt für die Downloadliste mit welchem sich ein Kunde einen "RCL" erstellen und downloaden kann
         *
         * @returns {[{archived: boolean, isDownloaded: boolean, isReady: boolean, downloadUrl: string, description: {}, id, type: string, status: string}]}
         */
        const createRCL = () => {
            return [
                {
                    id: self.data.id,
                    description: _.transform(['de', 'en'], function (result, lang) {
                        result[lang] = $translate.instant('shop.thankYou.rcl.description', {}, undefined, lang);
                    }, {}),
                    status: 'COMPLETED',
                    isReady: true,
                    type: 'VOUCHER',
                    downloadUrl: [apiConfiguration.baseUrl, 'itf/orderConfirmationLetter', self.data.id].join('/'),
                    isDownloaded: false,
                    archived: false
                }
            ];
        };

        function createDataForDisplay() {
            const invalidStatus = ['ERROR', 'TIMEOUT'];

            // #6135    Bei Zahlung per Überweisung ("auf Rechnung") dauert es mehrere Tage, bis die Rechnung vorliegt
            //          Bis dahin nicht anzeigen, damit nicht der Eindruck entsteht, sie wäre bald bereit
            if (self.data.paymentMethod === 'invoice') {
                invalidStatus.push('PROCESSING');
            }

            self.invoices = _(self.data.bookings)
                .filter(b => b.generatesInvoice && !_.includes(invalidStatus, b.status))
                .map(self.createInvoiceDataForDisplay)
                .value();

            // Items für Darstellung aufbereiten und in Tickets und nicht-Ticket
            // Leistungen aufteilen, da diese getrennt dargestellt werden sollen.
            const items = _(self.data.items)
                .map(self.createItemForDisplay)
                .partition({type: 'TICKET'})
                .value();

            self.tickets = items[0];
            self.otherItems = items[1];

            // #6135 Wenn keinerlei Tickets erstellt werden, dann sollen auch keine angezeigt werden
            if (self.shop.isDesktopTicketDisabled() && self.shop.isMobileTicketDisabled()) {
                self.tickets = [];
            }

            // Im Falle eines OnSite Shop zusätzlich ein Item anzeigen für den Download Aller-Tickets als "Sammel-PDF".
            if (self.shop.isOnsite() && ((self.tickets.length > 0) || _.filter(self.otherItems, (item) => item.requiresVoucher).length > 0)) {
                self.tickets.unshift(self.createAllTicketItemForDisplay(_.every(self.tickets, {isReady: true})));
            }

            // W2-987 ITF-Shops müssen dem Kunden die Möglichkeit bieten deren "RCL" auf der Dankeseite runterzuladen
            if (self.isItfShop()) {
                self.rcl = createRCL();
            }
        }

        /**
         * Gibt zurück, ob es sich hierbei um einen Shop einer ITF-Veranstaltung handelt.
         *
         * @returns boolean
         */
        self.isItfShop = function () {
            return self.shop.isItfShop();
        };

        self.isWaitingForAccreditation = function () {
            return !self.data.isComplete && self.data.status === 'WAITING_FOR_ACCREDITATION';
        };

        /**
         * Erzeugt zu einem PurchasableItem die Beschreibung zur Anzeige als I18nText.
         */
        self.createItemDescription = function (purchasableItem) {
            return _.transform(['de', 'en'], function (result, lang) {
                // formData der aktuellen Order soll als default genutzt werden,
                // wenn keine spezifischeren Angaben am Item vorhanden sind.
                const formData = _.defaultsDeep({}, purchasableItem.formData, _.get(self.data, 'formData'));

                result[lang] = _.compact([
                    _.get(purchasableItem.label, lang),
                    _.get(formData, 'user.salutation.data.label'),
                    _.get(formData, 'user.title.data.label'),
                    _.get(formData, 'user.firstName.data'),
                    _.get(formData, 'user.lastName.data')
                ]).join(' ');
            }, {});
        };

        self.createAllTicketItemForDisplay = function (isDownloadReady) {
            const result = {
                id: self.data.id,
                isReady: isDownloadReady,
                downloadUrl: [apiConfiguration.baseUrl, 'order/allTicketPdfs', self.data.id].join('/')
            };

            result.description = _.transform(['de', 'en'], function (result, lang) {
                result[lang] = $translate.instant('shop.thankYou.tickets.all.description', {}, undefined, lang);
            }, {});

            return result;
        };

        /**
         * Erzeugt zu einem Booking eine Repräsentation zur Anzeige.
         */
        self.createInvoiceDataForDisplay = function (booking) {
            const result = _.pick(booking, ['id', 'type', 'status', 'archived']);

            result.description = _.transform(['de', 'en'], function (result, lang) {
                const translateId = booking.type === 'BOOKING' ? 'shop.thankYou.invoices.invoice.description' : 'shop.thankYou.invoices.refund.description';
                result[lang] = $translate.instant(translateId, booking, undefined, lang);
            }, {});

            result.isReady = !_.isEmpty(booking.pdfFile);

            if (result.isReady) {
                result.downloadUrl = [apiConfiguration.baseUrl, 'order/invoicePdf', booking.id].join('/');
                result.isDownloaded = !_.isEmpty(_.get(booking.pdfFile, 'downloadedAt'));
            }

            return result;
        };

        /**
         * Erzeugt zu einem PurchasableItem eine Repräsentation zur Anzeige.
         */
        self.createItemForDisplay = function (purchasableItem) {
            const result = _.pick(purchasableItem, ['id', 'type', 'status', 'archived', 'requiresVoucher']);

            result.description = self.createItemDescription(purchasableItem);

            // Im Gegensatz zu Tickets werden die ggf. vorhandenen Voucher von nicht-Ticketleistungen on-download erzeugt,
            // daher kann/muss der Link immer angezeigt werden, denn sonst wäre ein Download gar nicht erst möglich.
            result.isReady = !_.isEmpty(purchasableItem.pdfFile) || result.type !== 'TICKET';

            if (result.isReady) {
                // Ticket-Items erhalten URLs für den Download von PDF- und Mobile-Ticket
                if (result.type === 'TICKET') {
                    // Desktop-Tickets können per Shop deaktiviert sein.
                    if (!self.shop.isDesktopTicketDisabled()) {
                        result.downloadUrl = [apiConfiguration.baseUrl, 'order/ticketPdf', purchasableItem.id].join('/');
                        result.isDownloaded = !_.isEmpty(_.get(purchasableItem.pdfFile, 'downloadedAt'));
                    }

                    // Mobile-Tickets können per Shop deaktiviert sein.
                    if (!self.shop.isMobileTicketDisabled()) {
                        result.mobileDownloadUrl = [apiConfiguration.baseUrl, 'order/mobileTicket', purchasableItem.id].join('/');
                        result.isMobileDownloaded = !_.isEmpty(_.get(purchasableItem.mobileTicket, 'downloadedAt'));
                    }
                }
                // Gutschein-Items erhalten URLs für den Download von PDF- und Mobile-Ticket
                else if (result.type === 'VOUCHER') {
                    result.downloadUrl = [apiConfiguration.baseUrl, 'order/voucherItemPdf', purchasableItem.id].join('/');
                    result.isDownloaded = !_.isEmpty(_.get(purchasableItem.pdfFile, 'downloadedAt'));
                }
                // Nicht-Ticket-Items erhalten nur eine URL für den Download eines ggf. vorhandenen Vouchers.
                else {
                    result.downloadUrl = [apiConfiguration.baseUrl, 'order/voucherPdf', purchasableItem.id].join('/');
                    result.isDownloaded = !_.isEmpty(_.get(purchasableItem.pdfFile, 'downloadedAt'));
                }
            }

            return result;
        };

        function trackOrderCompletion(order) {
            // Zuerst sagen wir der API Bescheid, dass wir den Abschluss des Kaufes tracken
            // damit uns bei zukünftigen Abrufen dieser Order ausgegeben wird, dass dies nicht mehr
            // erlaubt ist
            order.markCompletionTracked();

            const total = order.total;
            const totalTax = total - order.netTotal;

            const purchase = {
                actionField: {
                    id: order.orderNumber.toString(),
                    affiliation: order.shopName,
                    revenue: total.toFixed(2),
                    tax: totalTax.toFixed(2)
                },
                products: _(order.items)
                    .groupBy('ident')
                    .map(function (items) {
                        return {
                            id: items[0].ident,
                            name: _.get(items[0], 'label.de'),
                            price: (items[0].priceInCents / 100.0).toFixed(2),
                            category: items[0].widgetType,
                            quantity: items.length
                        };
                    })
                    .value()
            };

            $analytics.transactionTrack('purchase', {purchase: purchase});
        }

        self.reloadShop = () => {
            const shopPath = _.get(self.shop, 'data.shop.shopUrl');
            const shopUrl = $state.href('root.shop.show', {shopReference: shopPath}, {absolute: true, inherit: false});
            $window.location.href = shopUrl;
        };

        self.$onInit = function () {
            // Wird der Shop innerhalb eines Order-Kontext aufgerufen, entweder explizit oder durch die serverseitige
            // Session, sollte sich in der Shop-Definition auch die aktuelle Order befinde, daher zunächst deren ID
            // heranziehen ansonsten fallback auf die Order ID in der URL.
            const orderId = _.get(self.shop, 'data.order.id', $uiRouterGlobals.params.oid);

            // Wenn der Shop ohne Order-Kontext aufgerufen wurde können wir nichts
            // auf der Seite darstellen und können daher gleich aussteigen.
            if (!orderId) {
                return;
            }

            // FIXME: Die Order wird bereits durch ein resolve des state geladen, aber da kommt man hier nicht ohne
            //  weiteres dran. Wenn man die bereits geladenen Daten hier verfügbar zu macht, könnte dieser eigentlich
            //  unnötigen request eingespart werden.
            self.data = Order.get({id: orderId});

            // sobald die Daten verfügbar sind für Anzeige umformen und completion für analytics tracken.
            self.data.$promise.then(() => {
                createDataForDisplay();

                if (self.data.allowsCompletionTracking) {
                    trackOrderCompletion(self.data);
                }
            });

            // periodischer refresh der Daten, da ggf. Tickets und Rechnung noch nicht verfügbar sind.
            self.reloadOrderPeriodically = repeat(() => {
                // Daten wurden noch gar nicht initial geladen, skip!
                if (!self.data.$resolved) {
                    return;
                }

                // Hier darauf stützen, dass createDataForDisplay() bereits herausfindet,
                // welche Rechnungen/Tickets (sofern vorhanden) bereits fertig sind.
                const allInvoicesReady = _.every(self.invoices, i => i.isReady);
                const allTicketsReady = _.every(self.tickets, t => t.isReady);
                // für nicht-Ticket Items wird keine Prüfung durchgeführt,
                // da diese immer on-demand erzeugt werden.

                // Reload aussetzen, sobald alle Rechnungen & Tickets verfügbar sind.
                if (allInvoicesReady && allTicketsReady) {
                    self.reloadOrderPeriodically.cancel();
                    return;
                }

                return self.data.$get().then(createDataForDisplay);
            }, determineOrderReloadDelay);
        };

        self.$onDestroy = function () {
            self.reloadOrderPeriodically.cancel();
        };
    },
    require: {
        shop: '^^lmShop'
    },
    bindings: {
        widget: '<',
        model: '<'
    }
});
