"use strict";

var UPDATE_DEBOUNCE_WAIT = 500;

var _ = require('lodash');

/**
 * @ngInject
 */
function DataContactSuggestionsComponentController($log, $scope, ContactSuggestion) {
    var self = this;

    var stopListeningOnFormData = undefined;

    self.$onInit = function () {
        // Sobald andere Kundendaten eingegeben werden die Vorschläge neu laden.
        stopListeningOnFormData = $scope.$watch(function () {
            return _.get(self.shop, 'model.formData', {});
        }, _.debounce(function () {
            ContactSuggestion.query(self.shop).$promise.then(function (contacts) {
                self.contacts = contacts;
            });
        }, UPDATE_DEBOUNCE_WAIT), true);
    };

    self.$onDestroy = function () {
        stopListeningOnFormData();
    };
}

module.exports = function (ngModule) {
    ngModule.component('lmDataContactSuggestions', {
        templateUrl: 'data/data.contactSuggestions.component.html',
        controller: DataContactSuggestionsComponentController,
        bindings: {
            shop: '<',
            onApplyData: '&?',
            onApplyLastData: '&?'
        }
    });
};
