'use strict';

/**
 * $resource zum Abrufen aller im System definierten Benutzer Gruppen.
 *
 * @ngInject
 */
function AuthGroup($resource, apiConfiguration) {
    return $resource(apiConfiguration.baseUrl + '/security/groups');
}

module.exports = AuthGroup;