'use strict';

var _ = require('lodash');

/**
 * @ngInject
 */
function RadioChoiceGroupComponentController($log) {
    var self = this;

    function render() {
        if (!self.model.$isEmpty(self.model.$viewValue)) {
            self.selectedIndex = self.model.$viewValue.index;
        }
    }

    function copyChoices() {
        // Kopien von den Choices ziehen, damit Angaben bei mehrfachen Abfragen mit den selben Choices,
        // bspw. bei Personalisierung vopn Tickets, unabhängig voneinander sind. Anderfalls wäre es nicht
        // möglich bei Auswahl einer "Sonstiges" Option unterschiedliche Eingaben vorzunehmen.
        self._choices = _.cloneDeep(self.choices);
    }

    self.isSelected = function (choice) {
        return choice.index === self.selectedIndex;
    };

    self.updateChoiceSelection = function (event) {
        var selectedChoice = _.clone(event.choice);
        self.selectedIndex = selectedChoice.index;
        self.model.$setViewValue(selectedChoice);
    };

    self.$onInit = function () {
        self.model.$render = render;
        copyChoices();
    };

    self.$onChanges = function (changes) {
        if (changes.choices) {
            copyChoices();
        }
    }
}

module.exports = {
    templateUrl: 'widget/form/radio-choice-group.component.html',
    controller: RadioChoiceGroupComponentController,
    require: {
        model: 'ngModel'
    },
    bindings: {
        choices: '<',
        // es muss ein anderer Name als "disabled" verwendet werden, da sonst im IE das
        // Element und seine Kinder immer disabled sind, wenn das Attribut gesetzt ist.
        isDisabled: '<?'
    }
};
