'use strict';

/**
 * @ngInject
 */
function IfAuthenticatedComponentController(auth) {
    var self = this;

    self.isAuthenticated = auth.isAuthenticated;
}

module.exports = {
    transclude: true,
    templateUrl: 'auth/auth.ifAuthenticated.component.html',
    controller: IfAuthenticatedComponentController,
};
