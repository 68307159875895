'use strict';

/**
 * Komponente zur Darsellung eines Buttons welche in der onClick() Expression eine $watch() Funktion bereitstellt über
 * die ein busy Indikator für die Aktion angezeigt werden kann, die über den Button ausgelöst wird.
 */
require('angular').module('leipzigerMesse.ticketShop.frontend.common.button').component('lmButton', {
    controller: function ($log, $q) {
        'ngInject';

        var self = this;

        function $watch(result) {
            self.isBusy = true;

            // ggf. ist result kein Promise daher hier per $q.when() einwickeln,
            // damit alle Fälle gleich behandelt werden können.
            $q.when(result).finally(function () {
                self.isBusy = false;
            });
        }

        self.click = function () {
            self.onClick({$watch: $watch});
        };

        self.getType = function () {
            return self.type || 'button';
        };
    },

    bindings: {
        type: '@',
        klass: '@',
        onClick: '&',
        spinnerOnly: '<?',
        isDisabled: '<?'
    },
    transclude: true,
    templateUrl: 'common/button/button.component.html'
});
