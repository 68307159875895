'use strict';

const _ = require('lodash');

/**
 * @ngInject
 */
function ImageWidgetController(widget, order, shop, parent, $log) {
    var vm = this;
    vm.widget = widget;
    vm.order = order;
    vm.shop = shop;
    vm.parent = parent;
    vm.model = order.getFormDataProperty(widget.path);

    vm.hasUrl = () => {
        return !_.isEmpty(vm.widget.linkTargetUrl);
    };

    vm.getUrl = () => {
        if(!vm.hasUrl()) return undefined;

        if(_.startsWith(vm.widget.linkTargetUrl, 'http://') || _.startsWith(vm.widget.linkTargetUrl, 'https://')) {
            return vm.widget.linkTargetUrl;
        }

        return ('http://' + vm.widget.linkTargetUrl);
    };
}

module.exports = ImageWidgetController;
