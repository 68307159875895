'use strict';

// Konstante für das Wort "kassentag"
const KASSENTAG_KEYWORD = 'kassentag';

/**
 * Prüft ob ein String wahrscheinlich eine ObjectId repräsentiert.
 */
function isProbablyAnObjectId(string) {
    return /^[a-f\d]{24}$/i.test(string);
}

/**
 * Prüft ob ein String den case insensitive String "kassentag" enthält.
 *
 * @param string
 * @returns {boolean}
 */
function hasKassentagSubstring(string) {
    return (new RegExp(KASSENTAG_KEYWORD, 'i')).test(string);
}

/**
 * @ngInject
 */
function config($stateProvider) {
    /**
     * @ngInject
     */
    function resolveShop($window, $q, $transition$, $location, i18nService, shopStylingService, shopService, Shop) {
        const params = $transition$.params();
        const shopReference = params.shopReference;
        const orderId = params.oid;

        // Aussteller Params
        let exhibitorParams = {};
        if (params.regiNr !== undefined && params.hash !== undefined) {
            exhibitorParams = {
                regiNr: params.regiNr,
                hash: params.hash
            };
        }

        let shop;

        // Je nachdem ob der Shop direkt über seine ObjectId oder den Slug referenziert wird rufen wir ihn
        // unterschiedlich ab. Der Abruf über den Slug beachtet die Verkaufszeiten, so dass ein Abruf nur
        // möglich ist, wenn der Shop auch für die Allgemeinheit sichtbar sein soll.
        if (isProbablyAnObjectId(shopReference)) {
            shop = Shop.get(Object.assign({id: shopReference, orderId}, exhibitorParams));
        } else {
            shop = Shop.getBySlug(Object.assign({slug: shopReference, orderId}, exhibitorParams));
        }

        return shop.$promise
            .then(shop => {
                shopService.setIsBackendCall(isProbablyAnObjectId(shopReference));
                if (shop.redirectUrl) {
                    $window.location.href = shop.redirectUrl;
                    return $q.reject(`current shop state demands redirect to ${shop.redirectUrl}`);
                } else {
                    return shop;
                }
            })
            .then(i18nService.setupTranslationsForShop)
            .then(shopStylingService.injectStylingForShop)
            .catch(error => {
                // Enthält die Error-Message den Substring "Kassentag", dann handelt es sich um eine Fehlermeldung im
                // Kontext von Kassentagen, was zur Folge hat, dass der Shop nicht erreicht werden darf und stattdessen
                // die entsprechende error page angezeigt werden soll
                if (error.status === 500 && hasKassentagSubstring(error.data.message)) {
                    $window.location.href = '/no-daily-report';
                }

                return $q.reject(error.data.message);
            });
    }

    /**
     * @ngInject
     */
    function suggestLogin($log, $state, $transition$, keycloak, data) {
        const params = $transition$.params();
        const proceedWithoutLogin = !!params.nl && !data.shop.isLoginRequired;

        // Wenn der Shop eine Anmeldung erlaubt, den Kunden immer zuerst auffordern sich anzumelden,
        // sofern er diese Option zuvor nicht aktiv ausgeschlagen hat oder bereits angemeldet ist.
        if (keycloak && data.shop.isLoginAllowed && !(keycloak.authenticated || proceedWithoutLogin)) {
            $log.debug('not authenticated:', $transition$);
            return $state.target('root.shop.login', params, {location: false});
        }
    }

    $stateProvider
        .state('root.shopNotFound', {
            url: 'not-found',
            templateUrl: 'shop/shop.notFound.html'
        })
        .state('root.onsiteNoDailyReport', {
            url: 'no-daily-report',
            templateUrl: 'shop/shop.onsite.noDailyReport.html'
        })
        // Dieser State dient nur dazu um alte deep links auf die nicht-mehr vorhandenen Danke-Seite auf
        // den Shop im Auftragskontext umzuleiten. Dazu müssen jedoch zunächst die Daten für die Dankeseite
        // abgerufen werden um den slug des Shops zu ermitteln.
        .state('root.shopLegacyThankYou', {
            url: 'thank-you/:id',
            resolve: {
                _ignored_: function ($state, $transition$, Order) {
                    const params = $transition$;

                    return Order.get({id: params.id}).$promise.then(function (data) {
                        return $state.go('root.shop.showWithOrder', {
                            shopReference: data.shop.shopUrl,
                            orderId: params.id
                        });
                    });
                }
            }
        })
        // Dieser State dient dazu URLs der Form /:shopReference/:oid auf /:shopRefrence?oid=:orderReference
        // umzubiegen, damit alle States die eine Shop Referenz benötigen und ggf. optional eine Order referenzieren
        // gleich behandelt werden können und der Shop bereits im parent State resolved werden kann.
        .state('root.shopWithOrder', {
            url: ':shopReference/:oid?nl&regiNr&hash', // oid = "order ID", nl = "no login", regiNr = "Aussteller Registrierung Nummer", hash = "Aussteller Validierungshash"
            redirectTo: {
                state: 'root.shop.show',
                options: {inherit: true}
            }
        })
        .state('root.shop', {
            abstract: true,
            url: ':shopReference?oid&nl&regiNr&hash', // oid = "order ID", nl = "no login", regiNr = "Aussteller Registrierung Nummer", hash = "Aussteller Validierungshash"
            template: '<div ui-view></div>',
            resolve: {
                data: resolveShop,
                ignoreOffline: $transition$ => isProbablyAnObjectId($transition$.params().shopReference)
            }
        })
        .state('root.shop.login', {
            component: 'lmShopLogin'
        })
        .state('root.shop.show', {
            url: '',
            component: 'lmShopShow',
            onEnter: suggestLogin,
            resolve: {
                order: ($transition$, Order) => {
                    // ggf. referenzierte Order laden
                    const id = $transition$.params().oid;
                    return id ? Order.get({id}).$promise : null;
                }
            }
        })
        .state('root.shop.contact', {
            url: '/contact',
            component: 'lmShopTemplatePage',
            resolve: {
                template: () => 'frontendContactPage'
            },
            bindings: {
                shop: 'data.shop'
            }
        })
        .state('root.shop.legal', {
            url: '/legal',
            component: 'lmShopTemplatePage',
            resolve: {
                template: () => 'frontendLegalPage'
            },
            bindings: {
                shop: 'data.shop'
            }
        });
}

module.exports = config;
