'use strict';

/**
 * @ngInject
 */
function config($stateProvider) {
    /**
     * Ggf. notwendige Meldungen bzgl. globaler Fehler bei Bestellabwicklung anzeigen.
     *
     * @ngInject
     */
    function showGlobalErrorMessages($transition$, growl) {
        const {expired, paymentStatus} = $transition$.params();

        if (expired) {
            growl.error('shop.error.global.orderExpired');
        }

        switch (paymentStatus) {
            case 'cancel':
                growl.warning('shop.paymentStatus.cancel');
                break;
            case 'error':
                growl.error('shop.paymentStatus.error');
                break;
            default:
                break;
        }
    }

    $stateProvider
        .state('root', {
            abstract: true,
            controller: 'RootController as root',
            url: '/?debug&paymentStatus&expired',
            template: '<div ui-view></div>',
            onEnter: showGlobalErrorMessages
        });
}

module.exports = config;
