'use strict';

var _ = require('lodash');

/**
 * @ngInject
 */
function OptionalSubformWidgetController(widget, order) {
    var vm = this;

    vm.widget = widget;

    vm.show = true;

    vm.model = order.getSubmodel(vm.widget.path);

    vm.toggleShow = function () {
        if (!vm.show) {
            vm.model.clearFormData();
        }
    }
}

module.exports = OptionalSubformWidgetController;