'use strict';

/* @ngInject */
function ImageController($scope, lmAppConfig) {
    $scope.url = lmAppConfig.api.imageUrl + '/' + $scope.imageId + '?';
    if ($scope.width) {
        $scope.url += 'w=' + $scope.width + '&';
    }
    if ($scope.height) {
        $scope.url += 'h=' + $scope.height + '&';
    }
}

module.exports = function ImageDirective() {
    return {
        templateUrl: 'common/lmImage.html',
        scope: {
            imageId: '<',
            imageClass: '@',
            width: '@',
            height: '@'
        },
        controller: ImageController
    };

};