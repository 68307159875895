'use strict';

const _ = require('lodash');

/**
 * @ngInject
 */
function AuthInterceptorProvider() {
    const config = {
        keycloakHeader: 'Keycloak',
        authHeader: 'LM-Authorization',
        authPrefix: 'Bearer '
    };

    this.config = config;

    this.$get = function ($log, $injector, $location, $q, authJwt, keycloak, lmAppConfig) {
        'ngInject';

        return {
            request: function (req) {
                req.headers = req.headers || {};

                const onsiteAuthToken = authJwt.get();

                // Ein ggf. bereits vorhandener authHeader wird nicht überschrieben
                if (onsiteAuthToken && !req.headers[config.authHeader]) {
                    req.headers[config.authHeader] = config.authPrefix + onsiteAuthToken;
                }

                // Falls vorhanden wird der Keycloak access token in einem nicht Authorization Header übertragen,
                // damit es zu keiner Wechselwirkung mit den Logins des onsite Personals kommen kann, auch wenn
                // es praktisch nicht zu dieser Situation kommen sollte, da es bei einem onsite-Verkauf keine
                // Logins durch Benutzer geben sollte.
                if (keycloak && keycloak.token && !req.headers[config.keycloakHeader]) {
                    req.headers[config.keycloakHeader] = keycloak.token;
                }

                return req;
            },

            responseError: function (response) {
                const $state = $injector.get('$state');

                // Abhängig von dem Wert für "realm", den uns die API bei Authorisierungsfehlern mitteilt ist
                // eine Login über Keylcoak oder die Ticketshop-API notwendig um den gewünschten Shop abzurufen.
                const needsKeycloakLogin = _.get(response, 'data.realm') === lmAppConfig.keycloak.realm;

                // FIXME: vllt umbauen, dass default eine generische Fehlerseite ist und OnSite-Login nur bei OnSite-Shops kommt?
                switch (response.status) {
                    case 401:
                        if (needsKeycloakLogin) {
                            keycloak.login();
                        } else if (!$state.is('login')) {
                            $state.go('login', {onSuccessUrl: $location.path()});
                        }
                        break;

                    case 403:
                        if (needsKeycloakLogin) {
                            $state.go('error.order.unauthorized');
                        } else {
                            $state.go('login', {
                                onSuccessUrl: $location.path(),
                                errorMessage: 'Sie haben nicht die Berechtigung auf diesen Shop zuzugreifen'
                            });
                        }
                        break;
                    default:
                        break;
                }
                // In jedem Fall müssen wir den aufgetretenen Fehler weiter durchreichen.
                return $q.reject(response);
            }
        };
    };
}

module.exports = AuthInterceptorProvider;
