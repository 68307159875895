'use strict';

var _ = require('lodash');

require('angular').module('leipzigerMesse.ticketShop.frontend.widget').component('lmWidgetErrorMessage', {
    template: '<span class="help-block error-message pull-right" ng-bind-html="error.getMessage() | trustHtml"></span>',
    controller: function ($translate, i18nFilter) {
        'ngInject';

        var self = this;

        self.getMessage = function () {
            var error = self.getErrorCode();
            return error && self.getErrorMessage(error);
        };

        self.getErrorCode = function () {
            // die erste truthy Property in den definierten Fehlern oder am widget Model, ist der gesuchte Fehlercode.
            var errorCode = self.errors ? _.findKey(self.errors) : _.findKey(_.get(self.widget, 'ngModel.$error', {}));

            // Liegt ein server-seitig gemeldeter Fehler vor, dann müssen wir
            // den effektiven Fehlercode aus dem Model des Widget holen.
            if (errorCode === 'server') {
                errorCode = self.widget.model.getError(self.widget.data.path);
            }

            return errorCode;
        };

        self.getErrorMessage = function (code) {
            // zunächst spezifischste Fehlermeldung an dem Widget abrufen
            var i18nMessage = _.get(self, 'widget.data.errorMessages', {})[code];

            // Falls keine Fehlermeldung am Widget vorhanden ist, dann Fallback auf Definition am Shop.
            if (_.isUndefined(i18nMessage)) {
                i18nMessage = _.get(self, 'shop.data.shop.errorMessages', {})[code];
            }

            // Wenn keine spezifischere Fehlermeldung vorhanden ist, eine generische aus den Message-Bundles holen.
            return i18nMessage ? i18nFilter(i18nMessage) : $translate.instant('shop.form.error.' + code);
        };
    },
    controllerAs: 'error',
    bindings: {
        errors: '<?'
    },
    require: {
        widget: '^^lmWidget',
        shop: '^^lmShop'
    }
});