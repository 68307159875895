'use strict';

/**
 * Direktive mit der debug Informationen je nach Bedarf eingeblendet werden können.
 *
 * Damit die Elemente, die mit dieser Direktive annotiert sind, muss man die Seite mit dem query-Paraneter "debug" laden,
 *
 * @ngInject
 */
function DebugDirective($location) {

    function DebugDirectiveController() {

        var self = this;

        self.isActive = function () {
            return $location.search().debug;
        }

    }

    return {
        restrict: 'EAC',
        transclude: true,
        template: '<div ng-if="debug.isActive()" ng-transclude></div>',
        scope: true,
        controller: DebugDirectiveController,
        controllerAs: 'debug',
        bindToController: true
    };
}

module.exports = DebugDirective;