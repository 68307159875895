'use strict';

const _ = require('lodash');

class ItemPersonalizationWidgetComponentController {
    isIdentRelevantForPersonalization(ident) {
        return !!this.getItemDefinition(ident);
    }

    getItemDefinition(ident) {
        return _.find(this.widget.itemDefinitions, {ident});
    }

    getItems() {
        return _.get(this.shop, 'data.model.items', {});
    }
}

require('angular').module('leipzigerMesse.ticketShop.frontend.widget').component('lmItemPersonalizationWidget', {
    templateUrl: 'widget/widget.itemPersonalization.component.html',
    controller: ItemPersonalizationWidgetComponentController,
    require: {
        shop: '^^lmShop'
    },
    bindings: {
        widget: '<',
        model: '<'
    }
});
