'use strict';

var _ = require('lodash');
var uuid = require('node-uuid');

/**
 * @ngInject
 */
function WidgetGroupController($log, widget, order, parent) {
    var self = this;
    self.widget = widget;
    self.order = order;

    self.id = uuid.v1();

    var itemsToAmount = {};

    // Die ID der Leistungs-Widget, das aktuell ausgewählt wurde. Ist nur von Bedeutung, wenn die in dieser Gruppe
    // enthaltenen Leistungsauswahl als  radio-buttons dargestellt werden.
    var selectedItemController = undefined;

    var containedItemsById = {};

    self.registerItemAmount = function (itemId, amount) {
        if (!_.isUndefined(amount)) {
            itemsToAmount[itemId] = amount;
        }

        // Wenn ein item komplett entfernt wird und dieses item aktuell ausgewählt ist müssen wir
        // die Auswahl zurücksetzen, damit der Status der radio-buttons korrekt angezeigt wird.
        if (!(amount > 0) && _.get(selectedItemController, 'widget.id') === itemId) {
            selectedItemController = undefined;
        }

        self.orderCount = _.sum(_.values(itemsToAmount));

        if (parent && _.isFunction(parent.registerItemAmount)) {
            parent.registerItemAmount(itemId, amount);
        }
    };

    self.getItemAmount = function (itemId) {
        return itemsToAmount[itemId];
    };

    // ngModel-getterSetter für das aktuell ausgewählte item in einer radio-group
    self.selectedItem = function (itemCtrl) {
        if (_.isUndefined(itemCtrl)) {
            return selectedItemController;
        }

        selectedItemController = itemCtrl;

        // Alle item controller nach und nach aufsammeln,
        containedItemsById[selectedItemController.widget.id] = selectedItemController;

        // Alle bekannten items außer dem aktuell ausgewählten deselektieren, um das
        // verhalten einer radio-group zu simulieren.
        _.each(containedItemsById, function (itemCtrl, id) {
            if (id !== selectedItemController.widget.id) {
                itemCtrl.deselectItem();
            }
        });
    };

}

module.exports = WidgetGroupController;
