'use strict';

var widgetFormModule = require('angular').module('leipzigerMesse.ticketShop.frontend.widget.form', []);

widgetFormModule.component('lmDropdown', require('./dropdown.component'));
widgetFormModule.component('lmDateTimeInput', require('./dateTimeInput.component'));

widgetFormModule.component('lmCheckboxChoice', require('./checkbox-choice.component'));
widgetFormModule.component('lmCheckboxChoiceGroup', require('./checkbox-choice-group.component'));

widgetFormModule.component('lmRadioChoice', require('./radio-choice.component'));
widgetFormModule.component('lmRadioChoiceGroup', require('./radio-choice-group.component'));

widgetFormModule.component('lmCodeEntry', require('./codeEntry.component'));
widgetFormModule.component('lmPersonTypeSelection', require('./personTypeSelection.component'));

widgetFormModule.component('lmAccreditation', require('./accreditation.component'));

widgetFormModule.component('lmAccreditationPhotoId', require('./accreditationPhotoId.component'));

module.exports = widgetFormModule.name;
