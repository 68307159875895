module.exports=[
  {
    "name": "Andorra",
    "isoCode": "AD",
    "callingCode": "376"
  },
  {
    "name": "United Arab Emirates",
    "isoCode": "AE",
    "callingCode": "971"
  },
  {
    "name": "Afghanistan",
    "isoCode": "AF",
    "callingCode": "93"
  },
  {
    "name": "Antigua and Barbuda",
    "isoCode": "AG",
    "callingCode": "1-268"
  },
  {
    "name": "Anguilla",
    "isoCode": "AI",
    "callingCode": "1-264"
  },
  {
    "name": "Albania",
    "isoCode": "AL",
    "callingCode": "355"
  },
  {
    "name": "Armenia",
    "isoCode": "AM",
    "callingCode": "374"
  },
  {
    "name": "Angola",
    "isoCode": "AO",
    "callingCode": "244"
  },
  {
    "name": "Antarctica",
    "isoCode": "AQ",
    "callingCode": "672"
  },
  {
    "name": "Argentina",
    "isoCode": "AR",
    "callingCode": "54"
  },
  {
    "name": "American Samoa",
    "isoCode": "AS",
    "callingCode": "1-684"
  },
  {
    "name": "Austria",
    "isoCode": "AT",
    "callingCode": "43"
  },
  {
    "name": "Australia",
    "isoCode": "AU",
    "callingCode": "61"
  },
  {
    "name": "Aruba",
    "isoCode": "AW",
    "callingCode": "297"
  },
  {
    "name": "Åland Islands",
    "isoCode": "AX",
    "callingCode": "358"
  },
  {
    "name": "Azerbaijan",
    "isoCode": "AZ",
    "callingCode": "994"
  },
  {
    "name": "Bosnia and Herzegovina",
    "isoCode": "BA",
    "callingCode": "387"
  },
  {
    "name": "Barbados",
    "isoCode": "BB",
    "callingCode": "1-246"
  },
  {
    "name": "Bangladesh",
    "isoCode": "BD",
    "callingCode": "880"
  },
  {
    "name": "Belgium",
    "isoCode": "BE",
    "callingCode": "32"
  },
  {
    "name": "Burkina Faso",
    "isoCode": "BF",
    "callingCode": "226"
  },
  {
    "name": "Bulgaria",
    "isoCode": "BG",
    "callingCode": "359"
  },
  {
    "name": "Bahrain",
    "isoCode": "BH",
    "callingCode": "973"
  },
  {
    "name": "Burundi",
    "isoCode": "BI",
    "callingCode": "257"
  },
  {
    "name": "Benin",
    "isoCode": "BJ",
    "callingCode": "229"
  },
  {
    "name": "Saint Barthélemy",
    "isoCode": "BL",
    "callingCode": "590"
  },
  {
    "name": "Bermuda",
    "isoCode": "BM",
    "callingCode": "1-441"
  },
  {
    "name": "Brunei Darussalam",
    "isoCode": "BN",
    "callingCode": "673"
  },
  {
    "name": "Bolivia (Plurinational State of)",
    "isoCode": "BO",
    "callingCode": "591"
  },
  {
    "name": "Bonaire, Sint Eustatius and Saba",
    "isoCode": "BQ",
    "callingCode": "599"
  },
  {
    "name": "Brazil",
    "isoCode": "BR",
    "callingCode": "55"
  },
  {
    "name": "Bahamas",
    "isoCode": "BS",
    "callingCode": "1-242"
  },
  {
    "name": "Bhutan",
    "isoCode": "BT",
    "callingCode": "975"
  },
  {
    "name": "Bouvet Island",
    "isoCode": "BV",
    "callingCode": "47"
  },
  {
    "name": "Botswana",
    "isoCode": "BW",
    "callingCode": "267"
  },
  {
    "name": "Belarus",
    "isoCode": "BY",
    "callingCode": "375"
  },
  {
    "name": "Belize",
    "isoCode": "BZ",
    "callingCode": "501"
  },
  {
    "name": "Canada",
    "isoCode": "CA",
    "callingCode": "1"
  },
  {
    "name": "Cocos (Keeling) Islands",
    "isoCode": "CC",
    "callingCode": "61"
  },
  {
    "name": "Democratic Republic of the Congo",
    "isoCode": "CD",
    "callingCode": "243"
  },
  {
    "name": "Central African Republic",
    "isoCode": "CF",
    "callingCode": "236"
  },
  {
    "name": "Congo",
    "isoCode": "CG",
    "callingCode": "242"
  },
  {
    "name": "Switzerland",
    "isoCode": "CH",
    "callingCode": "41"
  },
  {
    "name": "Côte d'Ivoire",
    "isoCode": "CI",
    "callingCode": "225"
  },
  {
    "name": "Cook Islands",
    "isoCode": "CK",
    "callingCode": "682"
  },
  {
    "name": "Chile",
    "isoCode": "CL",
    "callingCode": "56"
  },
  {
    "name": "Cameroon",
    "isoCode": "CM",
    "callingCode": "237"
  },
  {
    "name": "China",
    "isoCode": "CN",
    "callingCode": "86"
  },
  {
    "name": "Colombia",
    "isoCode": "CO",
    "callingCode": "57"
  },
  {
    "name": "Costa Rica",
    "isoCode": "CR",
    "callingCode": "506"
  },
  {
    "name": "Cuba",
    "isoCode": "CU",
    "callingCode": "53"
  },
  {
    "name": "Cabo Verde",
    "isoCode": "CV",
    "callingCode": "238"
  },
  {
    "name": "Curaçao",
    "isoCode": "CW",
    "callingCode": "599"
  },
  {
    "name": "Christmas Island",
    "isoCode": "CX",
    "callingCode": "61"
  },
  {
    "name": "Cyprus",
    "isoCode": "CY",
    "callingCode": "357"
  },
  {
    "name": "Czechia",
    "isoCode": "CZ",
    "callingCode": "420"
  },
  {
    "name": "Germany",
    "isoCode": "DE",
    "callingCode": "49"
  },
  {
    "name": "Djibouti",
    "isoCode": "DJ",
    "callingCode": "253"
  },
  {
    "name": "Denmark",
    "isoCode": "DK",
    "callingCode": "45"
  },
  {
    "name": "Dominica",
    "isoCode": "DM",
    "callingCode": "1-767"
  },
  {
    "name": "Dominican Republic",
    "isoCode": "DO",
    "callingCode": "1-809"
  },
  {
    "name": "Algeria",
    "isoCode": "DZ",
    "callingCode": "213"
  },
  {
    "name": "Ecuador",
    "isoCode": "EC",
    "callingCode": "593"
  },
  {
    "name": "Estonia",
    "isoCode": "EE",
    "callingCode": "372"
  },
  {
    "name": "Egypt",
    "isoCode": "EG",
    "callingCode": "20"
  },
  {
    "name": "Western Sahara",
    "isoCode": "EH",
    "callingCode": "212"
  },
  {
    "name": "Eritrea",
    "isoCode": "ER",
    "callingCode": "291"
  },
  {
    "name": "Spain",
    "isoCode": "ES",
    "callingCode": "34"
  },
  {
    "name": "Ethiopia",
    "isoCode": "ET",
    "callingCode": "251"
  },
  {
    "name": "Finland",
    "isoCode": "FI",
    "callingCode": "358"
  },
  {
    "name": "Fiji",
    "isoCode": "FJ",
    "callingCode": "679"
  },
  {
    "name": "Falkland Islands (Malvinas)",
    "isoCode": "FK",
    "callingCode": "500"
  },
  {
    "name": "Micronesia (Federated States of)",
    "isoCode": "FM",
    "callingCode": "691"
  },
  {
    "name": "Faroe Islands",
    "isoCode": "FO",
    "callingCode": "298"
  },
  {
    "name": "France",
    "isoCode": "FR",
    "callingCode": "33"
  },
  {
    "name": "Gabon",
    "isoCode": "GA",
    "callingCode": "241"
  },
  {
    "name": "United Kingdom of Great Britain and Northern Ireland",
    "isoCode": "GB",
    "callingCode": "44"
  },
  {
    "name": "Grenada",
    "isoCode": "GD",
    "callingCode": "1-473"
  },
  {
    "name": "Georgia",
    "isoCode": "GE",
    "callingCode": "995"
  },
  {
    "name": "French Guiana",
    "isoCode": "GF",
    "callingCode": "594"
  },
  {
    "name": "Guernsey",
    "isoCode": "GG",
    "callingCode": "44"
  },
  {
    "name": "Ghana",
    "isoCode": "GH",
    "callingCode": "233"
  },
  {
    "name": "Gibraltar",
    "isoCode": "GI",
    "callingCode": "350"
  },
  {
    "name": "Greenland",
    "isoCode": "GL",
    "callingCode": "299"
  },
  {
    "name": "Gambia",
    "isoCode": "GM",
    "callingCode": "220"
  },
  {
    "name": "Guinea",
    "isoCode": "GN",
    "callingCode": "224"
  },
  {
    "name": "Guadeloupe",
    "isoCode": "GP",
    "callingCode": "590"
  },
  {
    "name": "Equatorial Guinea",
    "isoCode": "GQ",
    "callingCode": "240"
  },
  {
    "name": "Greece",
    "isoCode": "GR",
    "callingCode": "30"
  },
  {
    "name": "South Georgia and the South Sandwich Islands",
    "isoCode": "GS",
    "callingCode": "500"
  },
  {
    "name": "Guatemala",
    "isoCode": "GT",
    "callingCode": "502"
  },
  {
    "name": "Guam",
    "isoCode": "GU",
    "callingCode": "1-671"
  },
  {
    "name": "Guinea-Bissau",
    "isoCode": "GW",
    "callingCode": "245"
  },
  {
    "name": "Guyana",
    "isoCode": "GY",
    "callingCode": "592"
  },
  {
    "name": "China, Hong Kong Special Administrative Region",
    "isoCode": "HK",
    "callingCode": "852"
  },
  {
    "name": "Heard Island and McDonald Islands",
    "isoCode": "HM",
    "callingCode": "672"
  },
  {
    "name": "Honduras",
    "isoCode": "HN",
    "callingCode": "504"
  },
  {
    "name": "Croatia",
    "isoCode": "HR",
    "callingCode": "385"
  },
  {
    "name": "Haiti",
    "isoCode": "HT",
    "callingCode": "509"
  },
  {
    "name": "Hungary",
    "isoCode": "HU",
    "callingCode": "36"
  },
  {
    "name": "Indonesia",
    "isoCode": "ID",
    "callingCode": "62"
  },
  {
    "name": "Ireland",
    "isoCode": "IE",
    "callingCode": "353"
  },
  {
    "name": "Israel",
    "isoCode": "IL",
    "callingCode": "972"
  },
  {
    "name": "Isle of Man",
    "isoCode": "IM",
    "callingCode": "44"
  },
  {
    "name": "India",
    "isoCode": "IN",
    "callingCode": "91"
  },
  {
    "name": "British Indian Ocean Territory",
    "isoCode": "IO",
    "callingCode": "246"
  },
  {
    "name": "Iraq",
    "isoCode": "IQ",
    "callingCode": "964"
  },
  {
    "name": "Iran (Islamic Republic of)",
    "isoCode": "IR",
    "callingCode": "98"
  },
  {
    "name": "Iceland",
    "isoCode": "IS",
    "callingCode": "354"
  },
  {
    "name": "Italy",
    "isoCode": "IT",
    "callingCode": "39"
  },
  {
    "name": "Jersey",
    "isoCode": "JE",
    "callingCode": "44"
  },
  {
    "name": "Jamaica",
    "isoCode": "JM",
    "callingCode": "1-876"
  },
  {
    "name": "Jordan",
    "isoCode": "JO",
    "callingCode": "962"
  },
  {
    "name": "Japan",
    "isoCode": "JP",
    "callingCode": "81"
  },
  {
    "name": "Kenya",
    "isoCode": "KE",
    "callingCode": "254"
  },
  {
    "name": "Kyrgyzstan",
    "isoCode": "KG",
    "callingCode": "996"
  },
  {
    "name": "Cambodia",
    "isoCode": "KH",
    "callingCode": "855"
  },
  {
    "name": "Kiribati",
    "isoCode": "KI",
    "callingCode": "686"
  },
  {
    "name": "Comoros",
    "isoCode": "KM",
    "callingCode": "269"
  },
  {
    "name": "Saint Kitts and Nevis",
    "isoCode": "KN",
    "callingCode": "1-869"
  },
  {
    "name": "Democratic People's Republic of Korea",
    "isoCode": "KP",
    "callingCode": "850"
  },
  {
    "name": "Republic of Korea",
    "isoCode": "KR",
    "callingCode": "82"
  },
  {
    "name": "Kuwait",
    "isoCode": "KW",
    "callingCode": "965"
  },
  {
    "name": "Cayman Islands",
    "isoCode": "KY",
    "callingCode": "1-345"
  },
  {
    "name": "Kazakhstan",
    "isoCode": "KZ",
    "callingCode": "7"
  },
  {
    "name": "Lao People's Democratic Republic",
    "isoCode": "LA",
    "callingCode": "856"
  },
  {
    "name": "Lebanon",
    "isoCode": "LB",
    "callingCode": "961"
  },
  {
    "name": "Saint Lucia",
    "isoCode": "LC",
    "callingCode": "1-758"
  },
  {
    "name": "Liechtenstein",
    "isoCode": "LI",
    "callingCode": "423"
  },
  {
    "name": "Sri Lanka",
    "isoCode": "LK",
    "callingCode": "94"
  },
  {
    "name": "Liberia",
    "isoCode": "LR",
    "callingCode": "231"
  },
  {
    "name": "Lesotho",
    "isoCode": "LS",
    "callingCode": "266"
  },
  {
    "name": "Lithuania",
    "isoCode": "LT",
    "callingCode": "370"
  },
  {
    "name": "Luxembourg",
    "isoCode": "LU",
    "callingCode": "352"
  },
  {
    "name": "Latvia",
    "isoCode": "LV",
    "callingCode": "371"
  },
  {
    "name": "Libya",
    "isoCode": "LY",
    "callingCode": "218"
  },
  {
    "name": "Morocco",
    "isoCode": "MA",
    "callingCode": "212"
  },
  {
    "name": "Monaco",
    "isoCode": "MC",
    "callingCode": "377"
  },
  {
    "name": "Republic of Moldova",
    "isoCode": "MD",
    "callingCode": "373"
  },
  {
    "name": "Montenegro",
    "isoCode": "ME",
    "callingCode": "382"
  },
  {
    "name": "Saint Martin (French Part)",
    "isoCode": "MF",
    "callingCode": "590"
  },
  {
    "name": "Madagascar",
    "isoCode": "MG",
    "callingCode": "261"
  },
  {
    "name": "Marshall Islands",
    "isoCode": "MH",
    "callingCode": "692"
  },
  {
    "name": "The former Yugoslav Republic of Macedonia",
    "isoCode": "MK",
    "callingCode": "389"
  },
  {
    "name": "Mali",
    "isoCode": "ML",
    "callingCode": "223"
  },
  {
    "name": "Myanmar",
    "isoCode": "MM",
    "callingCode": "95"
  },
  {
    "name": "Mongolia",
    "isoCode": "MN",
    "callingCode": "976"
  },
  {
    "name": "China, Macao Special Administrative Region",
    "isoCode": "MO",
    "callingCode": "853"
  },
  {
    "name": "Northern Mariana Islands",
    "isoCode": "MP",
    "callingCode": "1-670"
  },
  {
    "name": "Martinique",
    "isoCode": "MQ",
    "callingCode": "596"
  },
  {
    "name": "Mauritania",
    "isoCode": "MR",
    "callingCode": "222"
  },
  {
    "name": "Montserrat",
    "isoCode": "MS",
    "callingCode": "1-664"
  },
  {
    "name": "Malta",
    "isoCode": "MT",
    "callingCode": "356"
  },
  {
    "name": "Mauritius",
    "isoCode": "MU",
    "callingCode": "230"
  },
  {
    "name": "Maldives",
    "isoCode": "MV",
    "callingCode": "960"
  },
  {
    "name": "Malawi",
    "isoCode": "MW",
    "callingCode": "265"
  },
  {
    "name": "Mexico",
    "isoCode": "MX",
    "callingCode": "52"
  },
  {
    "name": "Malaysia",
    "isoCode": "MY",
    "callingCode": "60"
  },
  {
    "name": "Mozambique",
    "isoCode": "MZ",
    "callingCode": "258"
  },
  {
    "name": "Namibia",
    "isoCode": "NA",
    "callingCode": "264"
  },
  {
    "name": "New Caledonia",
    "isoCode": "NC",
    "callingCode": "687"
  },
  {
    "name": "Niger",
    "isoCode": "NE",
    "callingCode": "227"
  },
  {
    "name": "Norfolk Island",
    "isoCode": "NF",
    "callingCode": "672"
  },
  {
    "name": "Nigeria",
    "isoCode": "NG",
    "callingCode": "234"
  },
  {
    "name": "Nicaragua",
    "isoCode": "NI",
    "callingCode": "505"
  },
  {
    "name": "Netherlands",
    "isoCode": "NL",
    "callingCode": "31"
  },
  {
    "name": "Norway",
    "isoCode": "NO",
    "callingCode": "47"
  },
  {
    "name": "Nepal",
    "isoCode": "NP",
    "callingCode": "977"
  },
  {
    "name": "Nauru",
    "isoCode": "NR",
    "callingCode": "674"
  },
  {
    "name": "Niue",
    "isoCode": "NU",
    "callingCode": "683"
  },
  {
    "name": "New Zealand",
    "isoCode": "NZ",
    "callingCode": "64"
  },
  {
    "name": "Oman",
    "isoCode": "OM",
    "callingCode": "968"
  },
  {
    "name": "Panama",
    "isoCode": "PA",
    "callingCode": "507"
  },
  {
    "name": "Peru",
    "isoCode": "PE",
    "callingCode": "51"
  },
  {
    "name": "French Polynesia",
    "isoCode": "PF",
    "callingCode": "689"
  },
  {
    "name": "Papua New Guinea",
    "isoCode": "PG",
    "callingCode": "675"
  },
  {
    "name": "Philippines",
    "isoCode": "PH",
    "callingCode": "63"
  },
  {
    "name": "Pakistan",
    "isoCode": "PK",
    "callingCode": "92"
  },
  {
    "name": "Poland",
    "isoCode": "PL",
    "callingCode": "48"
  },
  {
    "name": "Saint Pierre and Miquelon",
    "isoCode": "PM",
    "callingCode": "508"
  },
  {
    "name": "Pitcairn",
    "isoCode": "PN",
    "callingCode": "870"
  },
  {
    "name": "Puerto Rico",
    "isoCode": "PR",
    "callingCode": "1"
  },
  {
    "name": "State of Palestine",
    "isoCode": "PS",
    "callingCode": "970"
  },
  {
    "name": "Portugal",
    "isoCode": "PT",
    "callingCode": "351"
  },
  {
    "name": "Palau",
    "isoCode": "PW",
    "callingCode": "680"
  },
  {
    "name": "Paraguay",
    "isoCode": "PY",
    "callingCode": "595"
  },
  {
    "name": "Qatar",
    "isoCode": "QA",
    "callingCode": "974"
  },
  {
    "name": "Réunion",
    "isoCode": "RE",
    "callingCode": "262"
  },
  {
    "name": "Romania",
    "isoCode": "RO",
    "callingCode": "40"
  },
  {
    "name": "Serbia",
    "isoCode": "RS",
    "callingCode": "381"
  },
  {
    "name": "Russian Federation",
    "isoCode": "RU",
    "callingCode": "7"
  },
  {
    "name": "Rwanda",
    "isoCode": "RW",
    "callingCode": "250"
  },
  {
    "name": "Saudi Arabia",
    "isoCode": "SA",
    "callingCode": "966"
  },
  {
    "name": "Solomon Islands",
    "isoCode": "SB",
    "callingCode": "677"
  },
  {
    "name": "Seychelles",
    "isoCode": "SC",
    "callingCode": "248"
  },
  {
    "name": "Sudan",
    "isoCode": "SD",
    "callingCode": "249"
  },
  {
    "name": "Sweden",
    "isoCode": "SE",
    "callingCode": "46"
  },
  {
    "name": "Singapore",
    "isoCode": "SG",
    "callingCode": "65"
  },
  {
    "name": "Saint Helena",
    "isoCode": "SH",
    "callingCode": "290"
  },
  {
    "name": "Slovenia",
    "isoCode": "SI",
    "callingCode": "386"
  },
  {
    "name": "Svalbard and Jan Mayen Islands",
    "isoCode": "SJ",
    "callingCode": "47"
  },
  {
    "name": "Slovakia",
    "isoCode": "SK",
    "callingCode": "421"
  },
  {
    "name": "Sierra Leone",
    "isoCode": "SL",
    "callingCode": "232"
  },
  {
    "name": "San Marino",
    "isoCode": "SM",
    "callingCode": "378"
  },
  {
    "name": "Senegal",
    "isoCode": "SN",
    "callingCode": "221"
  },
  {
    "name": "Somalia",
    "isoCode": "SO",
    "callingCode": "252"
  },
  {
    "name": "Suriname",
    "isoCode": "SR",
    "callingCode": "597"
  },
  {
    "name": "South Sudan",
    "isoCode": "SS",
    "callingCode": "211"
  },
  {
    "name": "Sao Tome and Principe",
    "isoCode": "ST",
    "callingCode": "239"
  },
  {
    "name": "El Salvador",
    "isoCode": "SV",
    "callingCode": "503"
  },
  {
    "name": "Sint Maarten (Dutch part)",
    "isoCode": "SX",
    "callingCode": "1-721"
  },
  {
    "name": "Syrian Arab Republic",
    "isoCode": "SY",
    "callingCode": "963"
  },
  {
    "name": "Swaziland",
    "isoCode": "SZ",
    "callingCode": "268"
  },
  {
    "name": "Turks and Caicos Islands",
    "isoCode": "TC",
    "callingCode": "1-649"
  },
  {
    "name": "Chad",
    "isoCode": "TD",
    "callingCode": "235"
  },
  {
    "name": "French Southern Territories",
    "isoCode": "TF",
    "callingCode": "262"
  },
  {
    "name": "Togo",
    "isoCode": "TG",
    "callingCode": "228"
  },
  {
    "name": "Thailand",
    "isoCode": "TH",
    "callingCode": "66"
  },
  {
    "name": "Tajikistan",
    "isoCode": "TJ",
    "callingCode": "992"
  },
  {
    "name": "Tokelau",
    "isoCode": "TK",
    "callingCode": "690"
  },
  {
    "name": "Timor-Leste",
    "isoCode": "TL",
    "callingCode": "670"
  },
  {
    "name": "Turkmenistan",
    "isoCode": "TM",
    "callingCode": "993"
  },
  {
    "name": "Tunisia",
    "isoCode": "TN",
    "callingCode": "216"
  },
  {
    "name": "Tonga",
    "isoCode": "TO",
    "callingCode": "676"
  },
  {
    "name": "Turkey",
    "isoCode": "TR",
    "callingCode": "90"
  },
  {
    "name": "Trinidad and Tobago",
    "isoCode": "TT",
    "callingCode": "1-868"
  },
  {
    "name": "Tuvalu",
    "isoCode": "TV",
    "callingCode": "688"
  },
  {
    "name": "Taiwan",
    "isoCode": "TW",
    "callingCode": "886"
  },
  {
    "name": "United Republic of Tanzania",
    "isoCode": "TZ",
    "callingCode": "255"
  },
  {
    "name": "Ukraine",
    "isoCode": "UA",
    "callingCode": "380"
  },
  {
    "name": "Uganda",
    "isoCode": "UG",
    "callingCode": "256"
  },
  {
    "name": "United States Minor Outlying Islands",
    "isoCode": "UM",
    "callingCode": ""
  },
  {
    "name": "United States of America",
    "isoCode": "US",
    "callingCode": "1"
  },
  {
    "name": "Uruguay",
    "isoCode": "UY",
    "callingCode": "598"
  },
  {
    "name": "Uzbekistan",
    "isoCode": "UZ",
    "callingCode": "998"
  },
  {
    "name": "Holy See",
    "isoCode": "VA",
    "callingCode": "39-06"
  },
  {
    "name": "Saint Vincent and the Grenadines",
    "isoCode": "VC",
    "callingCode": "1-784"
  },
  {
    "name": "Venezuela (Bolivarian Republic of)",
    "isoCode": "VE",
    "callingCode": "58"
  },
  {
    "name": "British Virgin Islands",
    "isoCode": "VG",
    "callingCode": "1-284"
  },
  {
    "name": "United States Virgin Islands",
    "isoCode": "VI",
    "callingCode": "1-340"
  },
  {
    "name": "Viet Nam",
    "isoCode": "VN",
    "callingCode": "84"
  },
  {
    "name": "Vanuatu",
    "isoCode": "VU",
    "callingCode": "678"
  },
  {
    "name": "Wallis and Futuna Islands",
    "isoCode": "WF",
    "callingCode": "681"
  },
  {
    "name": "Samoa",
    "isoCode": "WS",
    "callingCode": "685"
  },
  {
    "name": "Yemen",
    "isoCode": "YE",
    "callingCode": "967"
  },
  {
    "name": "Mayotte",
    "isoCode": "YT",
    "callingCode": "262"
  },
  {
    "name": "South Africa",
    "isoCode": "ZA",
    "callingCode": "27"
  },
  {
    "name": "Zambia",
    "isoCode": "ZM",
    "callingCode": "260"
  },
  {
    "name": "Zimbabwe",
    "isoCode": "ZW",
    "callingCode": "263"
  }
]