'use strict';

var _ = require('lodash');

/**
 * @ngInject
 */
function RadioInputWidgetController(widget, order) {
    var self = this;

    self.widget = widget;
    self.order = order;
    self.model = order.getFormDataProperty(widget.path);
}

module.exports = RadioInputWidgetController;