'use strict';

var JWT_STORAGE_KEY = 'jwt';

/**
 * Verantwortlich für die lokale Persistenz des JWT.
 *
 * @ngInject
 */
function authJwt($localStorage, authHelper) {

    var claims = {};

    function getJwtStorage() {
        // Den Kontext für den JWT aus dem localStorage laden falls vorhanden,
        // ansonstent initialisieren damit dieser in Zukunft persistiert wird.
        var storage = $localStorage[JWT_STORAGE_KEY] || {};
        $localStorage[JWT_STORAGE_KEY] = storage;

        return storage;
    }

    function init() {
        // einmal lesen und wieder speichern, damit der claims cache gefüllt wird.
        set(get());
    }

    function get() {
        return getJwtStorage().token;
    }

    function set(jwt) {
        // sobald ein (neuer) JWT gesetzt wird, merken wir uns eine decodierte Version der claims, um den JWT nicht
        // immer wieder decodieren zu müssen bspw. bei der Abfrage von Rechten und Rollen.
        claims = jwt ? authHelper.decodeToken(jwt) : {};
        return getJwtStorage().token = jwt;
    }

    function getClaims() {
        return claims;
    }

    return {
        init: init,
        get: get,
        set: set,
        getClaims: getClaims
    }
}

module.exports = authJwt;