'use strict';

var auth = require('angular').module('leipzigerMesse.ticketShop.backend.auth', [
    // angular-permission klinkt sich in den router ein, daher muss dieser vorher geladen sein.
    require('@uirouter/angularjs').default,
    // Es werden zwei Module aus dem Paket angular-permission benötigt, durch das cachende Verhalten von require()
    // wird das  Module angular-permission nicht zweimal geladen.
    require('angular-permission').uiPermission,
    require('angular-permission').permission,
    require('ngstorage') && 'ngStorage',
    require('../api')
]);

auth.config(require('./auth.states'));

auth.service('auth', require('./auth.service'));
auth.service('authJwt', require('./auth.jwt.service'));
auth.service('authHelper', require('./auth.helper.service'));

auth.service('AuthGroup', require('./auth.group.resource'));

auth.provider('authInterceptor', require('./auth.interceptor'));

auth.component('lmIfAuthenticated', require('./auth.ifAuthenticated.component'));

auth.controller('LoginController', require('./login.controller'));

auth.config(function ($httpProvider) {

    // Einen Interceptor einrichten, der auf Authorisierungs-Fehler lauscht, damit wir ggf. client-seitig
    // auf die Login-Seite umleiten können, damit sich der Benutzer (erneut) anmelden kann.
    // Weiterhin soll zur Authorisierung bei jedem Request der JWT, sofern lokal vorhanden, mitgeschickt werden.
    $httpProvider.interceptors.push('authInterceptor');
});

auth.run(function ($log, $interval, lmAppConfig, authJwt, keycloak) {
    // Der access token, den wir von Keycloak erhalten ist nur eine kurze Zeit gültig, anstatt bei jedem
    // Request zu prüfen ob wir noch über einen gültigen Token verfügen, prüfen wir periodisch ob der
    // access token erneuert werden muss. Bei einem Aufruf von updateToken() wird auch der refresh token
    // erneuert, sodass wir im Regelfall solange über einen gültigen token verfügen bis die Keycloak Session
    // des Benutzers ausläuft.
    if (keycloak && keycloak.authenticated) {
        $interval(function () {
            keycloak.updateToken(lmAppConfig.keycloak.refreshThreshold)
                .success(function (tokenWasRefreshed) {
                    if (tokenWasRefreshed) {
                        $log.debug('access token sucessfully refreshed');
                    }
                })
                .error(function (reason) {
                    $log.error('failed to refresh access token:', reason);
                });
        }, lmAppConfig.keycloak.refreshInterval * 1000);
    }

    authJwt.init();
});

module.exports = auth.name;
