'use strict';

var _ = require('lodash');

/**
 * @ngInject
 */
function FormStateComponentController() {

    var self = this;

    self.getState = function () {
        // Zunnächst alle irrelevanten Daten ausschließen, sonst wirds schnell unübersichtlich
        var formData = _.pick(self.form, [
            '$name',
            '$dirty',
            '$valid',
            '$submitted'
        ]);

        // Nach Bedarf aber die NgModelController der registierten Eingabeelemente anzeigen.
        if (self.includeControls) {
            formData.controls = _(self.form)
                .pickBy(function (v, k) {
                    return !k.startsWith('$');
                })
                .map('$name')
                .value();
        }

        return formData;
    }

}

module.exports = {
    template: '<pre>{{ formState.getState() | json }}</pre>',
    controller: FormStateComponentController,
    controllerAs: 'formState',
    bindings: {
        includeControls: '<'
    },
    require: {
        form: '^^'
    }
};