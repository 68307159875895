'use strict';

const angular = require('angular');

/**
 * @ngInject
 */
function ShopQuantityLimitExceedancePopupController($scope, lmAppConfig, shopService) {
    /**
     * Broadcast-Listener, welcher bei Limit-Überschreitung ein Popup anzeigt, wenn ein seatingItem entfernt wird
     */
    $scope.$on('cart:removeSeatingItemNotAllowed', (event, args) => {
        this.showPopup = true;
    });

    this.$onInit = () => {
        this.showPopup = false;
    };

    this.reloadShop = () => {
        window.location.assign(lmAppConfig.api.seatingFrontendUrl + window.location.pathname + '/seating-shop');
    };

    this.closePopup = () => {
        this.showPopup = false;
    };
}

angular.module('leipzigerMesse.ticketShop.frontend.shop').component('lmQuantityLimitExceedancePopup', {
    templateUrl: 'shop/shop.quantityLimitExceedancePopup.component.html',
    controller: ShopQuantityLimitExceedancePopupController,
    bindings: {},
    require: {
        shop: '^^lmShop'
    }
});
