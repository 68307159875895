'use strict';

/**
 * @ngInject
 */
function i18nService($log, $translate, i18nConfig, shopService) {
    var self = this;

    self.setupTranslationsForShop = function (shop) {
        i18nConfig.useItfTranslations = shopService.isItfShop(shop.shop);

        return $translate.refresh()
            .then(function () {
                return shop;
            });
    };
}

module.exports = i18nService;
