'use strict';

var _ = require('lodash');

/**
 * @ngInject
 */
function CheckboxChoiceComponentController($log) {
    var self = this;

    self.onSelectChange = function () {
        self.onChange({
            $event: {
                choice: self.choice,
                selected: self.selected
            }
        })
    };

    self.onInputChange = function () {
        // Sobald etwas eingetragen wird, die checkbox auswählen, denn es ist sehr warscheinlich, dass dies der
        // Benutzer ohnehin als nächstes tun wird. Diesen Schritte können wird ihm hiermit ersparen.
        if (!_.isEmpty(self.choice.inputValue)) {
            self.selected = true;
        }

        self.onSelectChange();
    };
}

module.exports = {
    templateUrl: 'widget/form/checkbox-choice.component.html',
    controller: CheckboxChoiceComponentController,
    require: {
        group: '^lmCheckboxChoiceGroup'
    },
    bindings: {
        choice: '<',
        selected: '<',
        // es muss ein anderer Name als "disabled" verwendet werden, da sonst im IE das
        // Element und seine Kinder immer disabled sind, wenn das Attribut gesetzt ist.
        isDisabled: '<?',
        onChange: '&'
    }
};
