'use strict';

const _ = require('lodash');

/**
 * @ngInject
 */
function VoucherCodeInputComponentController($log) {
    const self = this;

    self.loading = false;

    self.onKeypress = function ($event) {
        if ($event.keyCode === 13) {
            $event.preventDefault();
            self.submit();
        }
    };

    self.submit = function () {
        self.loading = true;

        // Die invaliden Voucher brauchen wir nicht erneut zu senden.
        self.shop.discardInvalidVouchers();

        self.shop.addVoucherCode(self.input).finally(() => {
            self.loading = false;
        });

        self.input = undefined;
    };

    self.isVisible = () => {
        return self.shop.allowVoucherRedemption();
    }

    self.isDisabled = () => {
        return !(self.shop.getEffectiveCartTotal() > 0);
    }

    self.hasError = () => {
        // Wir zeigen den Fehler einfach immer an, wenn es min. einen invaliden Gutschein gibt.
        // Das ist nicht sehr genau, sollte aber ausreichend sein, da der Benutzer immer nur
        // einen Gutschein zur Zeit eingeben kann, daher sollte ersichtlich sein, dass sich
        // der Fehler auf den so eben eingegebenen Code bezieht.
        //
        // FIXME/ACHTUNG: Dies unterschlägt jedoch den Fall, dass einer der zuvor eingegebenen
        //  Codes in der Zwischenzeit ungültig geworden ist. In diesem Fall würde ein Fehler
        //  angezeigt werden, obwohl der zuletzt eingegebene Code nicht der Übeltäter war.
        return self.shop.hasInvalidVouchers();
    }

    self.clearError = () => {
        // Da die Prüfung, ob ein Fehler angezeigt werden soll, nur daran hängt, ob es invalide Codes gibt,
        // diese einfach entfernen um die Meldung loszuwerden. Dies ist insofern legitim, da man diese
        // wohl ohnehin nicht weiter verwenden wollen würde.
        self.shop.discardInvalidVouchers();
    }

}

module.exports = {
    controller: VoucherCodeInputComponentController,
    require: {
        shop: '^^lmShop'
    },
    template: `
    <div ng-if="$ctrl.isVisible()" class="lm-voucher-code-input col-sm-12">
        <div class="row">
            <div class="col-sm-6 col-sm-offset-3">
                <div class="row">
                    <div class="col-sm-8">
                        <input class="form-control"
                               type="text"
                               name="code"
                               placeholder="{{ 'shop.voucher.input.placeholder.redeem' | translate }}"
                               ng-model="$ctrl.input"
                               ng-focus="$ctrl.clearError()"
                               ng-keypress="$ctrl.onKeypress($event)"
                               ng-disabled="$ctrl.isDisabled()" 
                        />
                    </div>
                    <div class="col-sm-4">
                        <button type="button" class="btn btn-primary" ng-click="$ctrl.submit()" ng-disabled="!$ctrl.input">
                            <span translate="shop.voucher.button.label.redeem"></span>
                            <span ng-if="$ctrl.loading">
                                <i class="fa fa-fw fa-circle-o-notch fa-spin" aria-hidden="true"></i>
                            </span>
                        </button>
                    </div>
                    
                    <div class="row" ng-if="$ctrl.hasError()">
                        <div class="col-sm-10 has-error">
                            <span class="help-block error pull-right" translate="shop.voucher.error.voucherInvalid"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    `
};
