'use strict';

var i18n = require('angular').module('leipzigerMesse.ticketShop.frontend.i18n', []);

i18n.filter('i18n', require('./i18n.filter'));

i18n.constant('i18nConfig', require('./i18n.config'));
i18n.service('i18nService', require('./i18n.service'));
i18n.factory('i18nTranslationLoader', require('./i18n.translationLoader.factory'));

module.exports = i18n.name;