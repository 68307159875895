module.exports={
  "common.language.de": "Deutsch",
  "common.language.en": "English",
  "error.login.unauthorized": "Invalid user name and/or password.",
  "shop.error.global.orderExpired": "Your order has expired. Please try again.",
  "shop.login.button.login.label": "Login",
  "shop.login.button.register.label": "Register",
  "shop.login.button.nologin.label": "Proceed without login",
  "shop.login.button.linkedin.label": "LinkedIn",
  "shop.login.button.facebook.label": "Facebook",
  "shop.login.idp.hint": "Login with:",
  "shop.paymentStatus.cancel": "Your payment has been cancelled.",
  "shop.paymentStatus.error": "Your payment was not successful. Please choose another mode of payment.",
  "shop.header.logout.label": "Logout",
  "shop.header.login.label": "Login",
  "shop.accreditation.upload.error.tooLarge": "The file is too big. You may upload files with a max. size of {{ maxFileSize | filesize:10 }}.",
  "shop.accreditation.upload.selectFile.placeholder": "Select file",
  "shop.accreditation.upload.sendFile.label": "Attach file",
  "shop.accreditation.upload.removeFile.hint": "Remove file",
  "shop.accreditation.upload.description.placeholder": "Short description for the file (optional)",
  "shop.accreditation.upload.reuse.hint": "uploaded for {{ uploadContext }} at {{ uploadedAt | date }}",
  "shop.checkout.codeEntry.remove": "remove",
  "shop.checkout.header": "order",
  "shop.checkout.info": "A summary of services selected so far is found here.",
  "shop.checkout.payment.info": "Choose your preferred payment mode please:",
  "shop.checkout.paymentMethod.none": "Choose your preferred payment mode please:",
  "shop.checkout.paymentMethod.onsite_cash": "cash",
  "shop.checkout.paymentMethod.onsite_debit_card": "girocard",
  "shop.checkout.paymentMethod.onsite_credit_card": "credit card",
  "shop.checkout.paymentMethod.invoice": "invoice",
  "shop.checkout.paymentMethod.payone_credit_card": "credit card",
  "shop.checkout.paymentMethod.payone_pay_direct": "paydirekt\n",
  "shop.checkout.paymentMethod.payone_sofort": "Pay immediately with Klarna",
  "shop.checkout.paymentMethod.paypal_api_checkout": "PayPal",
  "shop.checkout.proceed.label.submit": "Submit",
  "shop.checkout.proceed.label.emptyCart": "Please, select at least one ticket.",
  "shop.checkout.proceed.label.payNow": "Buy now",
  "shop.checkout.proceed.label.redeem": "Complete your order",
  "shop.checkout.proceed.label.requestAccreditation": "Submit request",
  "shop.checkout.proceed.label.fixQuantity": "Please fix your order quantity",
  "shop.checkout.proceed.label.selectionNotPossible": "Selection not possible",
  "shop.checkout.proceed.label.pendingAccreditation": "Complete purchase subject to successful accreditation",
  "shop.checkout.submit.serverError.message": "We are unable to process your order at this time, please try again later.",
  "shop.checkout.summary.item.description": "Description",
  "shop.checkout.summary.item.quantity": "Quantity",
  "shop.checkout.summary.item.totalPrice": "Total price (incl. VAT)",
  "shop.checkout.summary.item.unitPrice": "Unit price (incl. VAT)",
  "shop.checkout.summary.total": "Total price (incl. VAT)",
  "shop.checkout.summary.vat": "incl. {{ amount | money }} {{ rate | number:0 }}% VAT.",
  "shop.checkout.termsAndConditions.acceptPrivacyStatement.label": "Zustimmung zu Datenschutz entfällt hier im Rahmen von #3487",
  "shop.checkout.termsAndConditions.acceptPrivacyStatement.url": "https://www.leipziger-messe.de/datenschutz_en/",
  "shop.checkout.codeEntry.button": "Redeem",
  "shop.checkout.codeEntry.placeholder": "Code",
  "shop.checkout.codeEntry.error.required": "Please enter a code",
  "shop.checkout.codeEntry.error.unsent": "You have an unredeemed code. Please click 'Redeem'.",
  "shop.tickets.inviteCode.label": "invitation code: ",
  "shop.contact.email.address": "tickets (at) leipziger-messe.de",
  "shop.contact.email.address.href": "tickets@leipziger-messe.de",
  "shop.contact.email.label": "e-mail:",
  "shop.contact.info": "You can contact us by telephone Monday to Friday and at weekends during our trade shows from 9.00 a.m. to 5.00 p.m..",
  "shop.contact.phone.number": "+49 (0)341 678-6862",
  "shop.contact.qna.label": "For questions about ticket orders:",
  "shop.contact.qna.link": "Questions / Answers",
  "shop.contact.qna.link.url": "https://www.leipziger-messe.de/media/001_global_media/Besucher/Ticketshop/FAQ-Leipziger-Messe-Ticketshop-eng.pdf",
  "shop.contact.title": "contact & information",
  "shop.contact.phone.label": "Hotline:",
  "shop.form.error.maxlength": "Your entry is too long.",
  "shop.form.error.minlength": "Your entry is not long enough.",
  "shop.form.error.pattern": "Your entry has to fullfill the given pattern.",
  "shop.form.error.required": "Please fill in the required information",
  "shop.form.error.server": "server based validation has failed.",
  "shop.header.link.backToShop": "back to ticketshop",
  "shop.header.link.contact": "contact/imprint",
  "shop.header.link.legal": "Terms and conditions",
  "shop.legal.documents.hausordnung.url": "http://www.leipziger-messe.com/company/download/gcp/",
  "shop.legal.link.imprint": "Imprint",
  "shop.legal.link.imprint.url": "https://www.leipziger-messe.de/impressum_en/",
  "shop.legal.link.privacyStatement": "Data protection/Liability",
  "shop.legal.link.privacyStatement.url": "https://www.leipziger-messe.de/datenschutz_en/",
  "shop.legal.link.termsAndConditions": "terms and conditions",
  "shop.step.proceed.button.label": "Continue",
  "shop.thankYou.info.paymentConfirmationPending": "Your tickets will be available shortly. The confirmation of your payment is still pending. This process can take up to an hour.<br /> You can close your browser and you will receive an e-mail afterwards.",
  "shop.thankYou.invoices": "your invoice",
  "shop.thankYou.tickets": "your tickets",
  "shop.thankYou.tickets.canceled": "canceled",
  "shop.thankYou.tickets.processing": "Ticket is loading, download not yet available.",
  "shop.thankYou.tickets.all.description": "print all tickets",
  "shop.thankYou.invoices.processing": "Invoice is loading, download not yet available.",
  "shop.thankYou.invoices.invoice.description": "Invoice No. {{ bookingNumber }}",
  "shop.thankYou.invoices.refund.description": "Refund No. {{ bookingNumber }}",
  "shop.thankYou.otherItems": "your other items ({{ count }})",
  "shop.thankYou.tickets.help": "Print your ticket and bring it with you",
  "shop.thankYou.tickets.mobile.help": "Save your ticket on a mobile phone",
  "shop.thankYou.rcl": "your registration confirmation letter",
  "shop.thankYou.rcl.description": "registration confirmation letter",
  "shop.thankYou.rcl.help": "download your registration confirmation letter",
  "shop.thankYou.archived": "Document has been archived. Please contact the Leipziger Messe support team.",
  "shop.tickets.selection.quantity": "quantity",
  "shop.tickets.selection.ticketTitle": "{{ index + 1 }}. Ticket",
  "shop.form.warn.missingHouseNumber": "Please add the house number if necessary.",
  "error.order.unauthorized.heading": "Unauthorized",
  "error.order.unauthorized.body": "You are not authorized to view this order. You probably created this order using a different Leipziger Messe account. Please sign in with the correct Leipziger Messe account.<br/><br/><a target='_self' href='{{ loginUrl }}'>Sign in with a different account</a>",
  "shop.social.share.mail.label": "Mail",
  "shop.social.share.mail.tooltip": "share via email",
  "shop.social.share.xing.label": "XING",
  "shop.social.share.xing.tooltip": "share on XING",
  "shop.social.share.linkedin.label": "LinkedIn",
  "shop.social.share.linkedin.tooltip": "share on LinkedIn",
  "shop.social.share.facebook.label": "Facebook",
  "shop.social.share.facebook.tooltip": "share on Facebook"
}
