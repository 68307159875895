'use strict';

const _ = require('lodash');
const angular = require('angular');

/**
 * Service für wiederholte Ausführung eines callbacks.
 *
 * @param {Function} callback Eine function, die bei jeder Wiederholung ausgeführt werden soll.
 *  Ist der return-Wert des callback Promise-like, dann erfolgt die nächste Wiederholung erst,
 *  wenn dieses resolved ist.
 * @param {Function|number} delay die Verzögerung zwischen den Wiederholungen. Kann ein konkreter Wert in ms sein
 *  oder eine function, die den nächsten delay liefert; Aufruf erfolgt mit der Anzahl der bisher erfolgten
 *  Wiederholungen als Parameter.
 *
 * @return Ein object mit einer cancel() Methode mit der die wiederholte Ausführung abgebrochen werden kann.
 */
angular.module('leipzigerMesse.ticketShop.frontend.common').factory('repeat', ($timeout, $q) => {
    'ngInject';

    const repeat = (callback, delay) => {
        const nextDelay = _.isFunction(delay) ? delay : _.constant(delay);
        let cancelled = false;
        let repeatCount = 0;
        let nextRun;

        const run = () => {
            // Ergebnis des callback per resolve wrappen, so können normale Werte
            // und Promise-like Objekte gleich behandelt werden.
            // Chaining per finally(), damit die Wiederholungen nicht abgebrochen
            // werden, wenn der callback auf einen Fehler läuft.
            $q.resolve(callback()).finally(() => {
                if (!cancelled) {
                    nextRun = $timeout(run, nextDelay(repeatCount++));
                }
            });
        };

        run();

        return {
            cancel() {
                cancelled = true;
                $timeout.cancel(nextRun);
            }
        }
    }

    return repeat;
});
