'use strict';

var uuid = require('node-uuid');

/**
 * @ngInject
 */
function WidgetControlDirectiveController() {

    var self = this;

    // UUID zur eindeutigen Identifikation des zur diesen Controller-Instanz gehörenden DOM-Elements
    self.id = uuid.v1();

    self.setDirty = function () {
        self.ngModel.$setDirty();
    };

    self.toString = function () {
        return 'input: ' + self.widget.data.path;
    };
}

/**
 * @ngInject
 */
function WidgetControlDirective() {
    return {
        link: function (scope, elem, attr, ctrl) {
            // NgModelController für die Eingaben dieses Widget an der umgebenen Komponente registrieren.
            ctrl.widget.registerNgModel(ctrl.ngModel);

            // Einen "server"-Fehler anzeigen, wenn im Model der unter dem Datenpfad die error-Property gesetzt ist
            ctrl.ngModel.$validators.server = function () {
                return !ctrl.widget.model.hasError(ctrl.widget.data.path);
            };

            // Einen Parser zu registrieren um bei der ersten Benutzereingabe die server-seitigen Fehler im Model
            // zu entfernen, da dies sonst auf dauer verhindern würden, dass die Eingabe jemals valide wird.
            //
            // Registrieren eines Callback unter $viewChangeListeners hilft hier nicht, da diese erst nach den
            // Validatoren ausgeführt werden und auch nur wenn der Wert valide ist, was aufgrund der unseres
            // selbst-registierten server-Validators niemals passieren kann, solange im Model ein server-seitiger
            // Fehler vorliegt
            ctrl.ngModel.$parsers.push(function (value) {
                ctrl.widget.model.clearError(ctrl.widget.data.path);
                return value;
            });

            // Das Eingabelement mit der ID des Controllers versehen, damit dieses ggf. explizit fokussiert werden kann.
            attr.$set('id', ctrl.self.id);
        },
        controller: WidgetControlDirectiveController,
        bindToController: true,
        require: {
            ngModel: '',
            widget: '^^lmWidget',
            self: 'lmWidgetControl'
        }
    };
}

module.exports = WidgetControlDirective;