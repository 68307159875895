'use strict';

/**
 * @ngdoc function
 * @name dreamhack-srs.backend.auth.LoginController
 * @description Dient der Anmeldung per Benutzername und Passwort im Service-Desk.
 *
 * @ngInject
 */
var LoginController = function ($log, $scope, $state, $window, $translate, auth) {

    var vm = this;

    vm.credentials = {
        email: undefined,
        password: undefined
    };

    vm.error = $state.params.errorMessage;

    vm.submit = function () {
        return auth.login(vm.credentials)
            .then(function (login) {
                $log.info('login successful', login);
                return $window.location.href = $state.params.onSuccessUrl;
            }).catch(function (error) {
                $log.error('login failed', error);
                vm.error = $translate.instant('error.login.unauthorized');
            });
    };
};

module.exports = LoginController;
