'use strict';

var _ = require('lodash');


var dataModule = require('angular').module('leipzigerMesse.ticketShop.frontend.data', []);

dataModule.service('countryDataByIsoCode', function () {
    return _(require('./countries.json'))
        .transform(function (acc, country) {
            acc[country.isoCode] = country;
        }, {})
        .value();
});

require('./data.contactSuggestion.resource')(dataModule);
require('./data.contactSuggestion.component')(dataModule);
require('./data.contactSuggestions.component')(dataModule);

module.exports = dataModule;