"use strict";

var _ = require('lodash');

/**
 * @ngInject
 */
function DataContactSuggestionComponentController() {
    var self = this;

    self.applyData = function ($watch) {
        if (_.isFunction(self.onApplyData)) {
            self.onApplyData({
                $watch: $watch,
                $event: {
                    data: self.data,
                }
            });
        }
    };
}

module.exports = function (ngModule) {
    ngModule.component('lmDataContactSuggestion', {
        templateUrl: 'data/data.contactSuggestion.component.html',
        controller: DataContactSuggestionComponentController,
        bindings: {
            data: '<',
            onApplyData: '&?'
        }
    });
};
