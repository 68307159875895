module.exports={
  "common.language.de": "Deutsch",
  "common.language.en": "English",
  "error.login.unauthorized": "Ungültiger Benutzer und/oder Passwort.",
  "shop.login.button.login.label": "Anmelden",
  "shop.login.button.register.label": "Registrieren",
  "shop.login.button.nologin.label": "Kaufen ohne Anmeldung",
  "shop.login.button.linkedin.label": "LinkedIn",
  "shop.login.button.facebook.label": "Facebook",
  "shop.login.idp.hint": "Login mit",
  "shop.header.logout.label": "Abmelden",
  "shop.header.login.label": "Anmelden",
  "shop.error.global.orderExpired": "Ihre Bestellung ist abgelaufen. Bitte versuchen Sie es erneut.",
  "shop.paymentStatus.cancel": "Sie haben die Zahlung abgebrochen.",
  "shop.paymentStatus.error": "Leider ist Ihre Zahlung fehlgeschlagen. Bitte wählen Sie eine andere Zahlart.",
  "shop.accreditation.upload.error.tooLarge": "Die Datei ist zu groß. Sie können Dateien mit einer Größe von max. {{ maxFileSize | filesize:10 }} hochladen.",
  "shop.accreditation.upload.selectFile.placeholder": "Datei auswählen",
  "shop.accreditation.upload.sendFile.label": "Datei senden",
  "shop.accreditation.upload.removeFile.hint": "Datei entfernen",
  "shop.accreditation.upload.description.placeholder": "Kurze Dateibeschreibung (optional)",
  "shop.accreditation.upload.reuse.hint": "Hochgeladen für {{ uploadContext }} am {{ uploadedAt | date }}",
  "shop.checkout.codeEntry.remove": "entfernen",
  "shop.checkout.header": "Bestellung",
  "shop.checkout.info": "Zusammenfassung Ihrer bisher ausgewählten Leistungen",
  "shop.checkout.payment.info": "Bitte gewünschte Zahlart auswählen",
  "shop.checkout.paymentMethod.none": "Zahlungsweise auswählen",
  "shop.checkout.paymentMethod.onsite_cash": "Barzahlung",
  "shop.checkout.paymentMethod.onsite_debit_card": "girocard",
  "shop.checkout.paymentMethod.onsite_credit_card": "Kreditkarte",
  "shop.checkout.paymentMethod.invoice": "Rechnungskauf",
  "shop.checkout.paymentMethod.payone_credit_card": "Kreditkarte",
  "shop.checkout.paymentMethod.payone_pay_direct": "paydirekt",
  "shop.checkout.paymentMethod.payone_sofort": "Sofort bezahlen mit Klarna",
  "shop.checkout.paymentMethod.paypal_api_checkout": "PayPal",
  "shop.checkout.proceed.label.submit": "Abschicken",
  "shop.checkout.proceed.label.emptyCart": "Bitte wählen sie min. ein Ticket aus",
  "shop.checkout.proceed.label.payNow": "kostenpflichtig bestellen",
  "shop.checkout.proceed.label.redeem": "Bestellung abschließen",
  "shop.checkout.proceed.label.requestAccreditation": "Anfrage abschicken",
  "shop.checkout.proceed.label.fixQuantity": "Bitte Ticketauswahl anpassen",
  "shop.checkout.proceed.label.selectionNotPossible": "Auswahl nicht möglich",
  "shop.checkout.proceed.label.pendingAccreditation": "Kauf abschliessen vorbehaltlich einer erfolgreichen Akkreditierung",
  "shop.checkout.submit.serverError.message": "Ihre Bestellung kann aktuell nicht verarbeitet werden, bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.",
  "shop.checkout.summary.item.description": "Bezeichnung",
  "shop.checkout.summary.item.quantity": "Anzahl",
  "shop.checkout.summary.item.totalPrice": "Gesamtpreis (inkl. USt.)",
  "shop.checkout.summary.item.unitPrice": "Einzelpreis (inkl. USt.)",
  "shop.checkout.summary.total": "Summe",
  "shop.checkout.summary.vat": "inkl. {{ amount | money }} {{ rate | number:0 }}% USt.",
  "shop.checkout.termsAndConditions.acceptPrivacyStatement.label": "Zustimmung zu Datenschutz entfällt hier im Rahmen von #3487",
  "shop.checkout.termsAndConditions.acceptPrivacyStatement.url": "https://www.leipziger-messe.de/datenschutz/",
  "shop.checkout.codeEntry.button": "Einlösen",
  "shop.checkout.codeEntry.placeholder": "Code",
  "shop.checkout.codeEntry.error.required": "Bitte geben Sie einen Code ein",
  "shop.checkout.codeEntry.error.unsent": "Sie haben einen noch nicht eingelösten Code. Bitte klicken Sie auf 'Einlösen'.",
  "shop.tickets.inviteCode.label": "Einladungscode: ",
  "shop.contact.email.address": "tickets (at) leipziger-messe.de",
  "shop.contact.email.address.href": "tickets@leipziger-messe.de",
  "shop.contact.email.label": "e-mail:",
  "shop.contact.info": "Wir sind für Sie von Montag bis Freitag sowie an den Veranstaltungs-Wochenenden von 9.00 - 17.00 Uhr telefonisch erreichbar. Außerhalb dieser Zeiten können Sie uns gern per E-Mail kontaktieren.",
  "shop.legal.link.termsAndConditions": "AGB",
  "shop.contact.phone.label": "Hotline:",
  "shop.contact.phone.number": "+49 (0)341 678-6862",
  "shop.contact.qna.label": "Bei Fragen zur Ticketbestellung:",
  "shop.contact.qna.link": "Fragen / Antworten",
  "shop.contact.qna.link.url": "https://www.leipziger-messe.de/faq-leipziger-messe-ticketshop/",
  "shop.contact.title": "Kontakt & Informationen",
  "shop.form.error.maxlength": "Ihre Eingabe ist zu lang",
  "shop.form.error.minlength": "Ihre Eingabe ist nicht lang genug",
  "shop.form.error.pattern": "Ihre Eingabe muss dem vorgegeben Muster entsprechen",
  "shop.form.error.required": "Bitte geben Sie Ihre Daten ein.",
  "shop.form.error.server": "Serverseitige Validierung fehlgeschlagen",
  "shop.header.link.backToShop": "Zurück zum Ticketshop",
  "shop.header.link.contact": "Kontakt/Impressum",
  "shop.header.link.legal": "AGB/Datenschutz",
  "shop.legal.documents.hausordnung.url": "http://www.leipziger-messe.de/unternehmen/download/agb/",
  "shop.legal.link.imprint": "Impressum",
  "shop.legal.link.imprint.url": "https://www.leipziger-messe.de/impressum/",
  "shop.legal.link.privacyStatement": "Datenschutz/Haftung",
  "shop.legal.link.privacyStatement.url": "https://www.leipziger-messe.de/datenschutz/",
  "shop.step.proceed.button.label": "Weiter",
  "shop.thankYou.info.paymentConfirmationPending": "Ihre Tickets stehen Ihnen in Kürze zur Verfügung. Die Bestätigung Ihrer Zahlung steht noch aus. Dieser Vorgang kann bis zu einer Stunde dauern.<br /> Sie können den Browser schließen und erhalten im Nachgang eine E-Mail.",
  "shop.thankYou.invoices": "Ihre Rechnung ({{ count }})",
  "shop.thankYou.tickets": "Ihre Tickets ({{ count }})",
  "shop.thankYou.tickets.canceled": "storniert",
  "shop.thankYou.tickets.processing": "Ticket wird noch geladen, Download noch nicht verfügbar.",
  "shop.thankYou.tickets.all.description": "alle Tickets drucken",
  "shop.thankYou.invoices.processing": "Rechnung wird noch geladen, Download noch nicht verfügbar.",
  "shop.thankYou.invoices.invoice.description": "Rechnung Nr. {{ bookingNumber }}",
  "shop.thankYou.invoices.refund.description": "Gutschrift Nr. {{ bookingNumber }}",
  "shop.thankYou.otherItems": "Ihre weiteren Leistungen ({{ count }})",
  "shop.thankYou.tickets.help": "Ticket ausdrucken und mitbringen",
  "shop.thankYou.tickets.mobile.help": "Ticket auf Smartphone abspeichern",
  "shop.thankYou.rcl": "your registration confirmation letter",
  "shop.thankYou.rcl.description": "registration confirmation letter",
  "shop.thankYou.rcl.help": "download your registration confirmation letter",
  "shop.thankYou.archived": "Dokument bereits archiviert. Bitte wenden Sie sich an den Support der Leipziger Messe.",
  "shop.tickets.selection.quantity": "Anzahl",
  "shop.tickets.selection.ticketTitle": "{{ index + 1 }}. Ticket",
  "shop.form.warn.missingHouseNumber": "Bitte ggf. noch die Hausnummer nachtragen.",
  "shop.social.share.mail.label": "Mail",
  "shop.social.share.mail.tooltip": "per E-Mail teilen",
  "shop.social.share.xing.label": "XING",
  "shop.social.share.xing.tooltip": "auf XING teilen",
  "shop.social.share.linkedin.label": "LinkedIn",
  "shop.social.share.linkedin.tooltip": "auf LinkedIn teilen",
  "shop.social.share.facebook.label": "Facebook",
  "shop.social.share.facebook.tooltip": "auf Facebook teilen",
  "error.order.unauthorized.heading": "Keine Berechtigung",
  "error.order.unauthorized.body": "Sie haben nicht die Berechtigung diese Bestellung aufzurufen. Möglicherweise haben Sie diese Bestellung mit einem anderen Leipziger Messe Konto getätigt. Bitte melden Sie sich mit dem korrekten Leipziger Messe Konto an.<br/><br/><a target='_self' href='{{ loginUrl }}'>Mit einem anderen Konto anmelden</a>",
  "shop.voucher.effectiveTotal": "Gesamtsumme",
  "shop.voucher.error.voucherInvalid": "Der Gutschein ist noch nicht/nicht mehr gültig oder nicht bekannt.",
  "shop.voucher.button.label.redeem": "Einlösen",
  "shop.voucher.input.placeholder.redeem": "Gutschein-Nr.",
  "shop.voucher.redemptions.description":  "Gutschein {{ code }}",
  "shop.voucher.redemptions.remainingAmount": "(Restwert nach dieser Einlösung: {{ remainingAmount | money }})"
}
