'use strict';

const _ = require('lodash');

const ACCREDITATION_BY_UPLOAD = 'ACCREDITATION_BY_UPLOAD';
const REUSE_UPLOAD = 'REUSE_UPLOAD';
const ACCREDITATION_BY_TEXT_INPUT = 'ACCREDITATION_BY_TEXT_INPUT';

/**
 * @ngInject
 */
function AccreditationComponentController($log, Upload, apiConfiguration) {
    const self = this;

    self.maxTextInputLength = 1000;

    self.internalModel = {};

    self.isUploadInProgress = false;

    self.error = undefined;

    self.upload = {
        file: undefined,
        description: undefined,
        inProgress: false
    };

    function render() {
        self.internalModel = _.cloneDeep(self.model.$viewValue) || {};

        const activeOptions = self.getActiveOptions();

        if (activeOptions.length === 1) {
            self.internalModel.type = activeOptions[0];
        }
    }

    /**
     * Ermittelt ob die Eingabe vollständig bzw. nicht leer ist.
     */
    function isEmpty(value) {
        if (_.isEmpty(value)) {
            return true;
        }

        switch (value.type) {
            case ACCREDITATION_BY_UPLOAD:
                // Wurde "Akkreditierung durch Upload" gewählt muss der Kunde min. einen Nachweis hochladen.
                return _.isEmpty(_.get(self.internalModel, 'files'));
            case REUSE_UPLOAD:
                return !_.some(_.get(self.internalModel, 'reusedUploads', {}));
            case ACCREDITATION_BY_TEXT_INPUT:
                return _.isEmpty(_.get(self.internalModel, 'textInput'));
        }

        return false;
    }

    self.onChange = function (setPristine = false) {
        switch (self.internalModel.type) {
            case ACCREDITATION_BY_UPLOAD:
                self.model.$setViewValue(_.omit(_.cloneDeep(self.internalModel), ['reusedUploads']));
                break;
            case REUSE_UPLOAD:
                const reusedUploads = _.keys(_.pickBy(self.internalModel.reusedUploads));
                self.model.$setViewValue({type: self.internalModel.type, reusedUploads});
                break;
            case ACCREDITATION_BY_TEXT_INPUT:
                self.model.$setViewValue(_.pick(self.internalModel, ['type', 'textInput']));
                break;
            default:
                self.model.$setViewValue({type: self.internalModel.type});
                break;
        }

        if (setPristine) {
            self.model.$setPristine();
        }
    };

    self.$onInit = function () {
        self.model.$render = render;
        self.model.$isEmpty = isEmpty;
    };

    self.areUploadControlsVisible = function () {
        const isAccreditationByUploadSelected = _.get(self.internalModel, 'type') === ACCREDITATION_BY_UPLOAD;
        const isAccreditationByUploadEnabled = _.get(self.widget, 'layout.accreditationByUploadEnabled', false);
        const isAccreditaionByUploadOnlyOption = isAccreditationByUploadEnabled && self.hasOnlyOneOption();

        return isAccreditationByUploadSelected || isAccreditaionByUploadOnlyOption;
    };

    self.arePreviousUploadsVisible = () => {
        const isReuseUploadSelected = _.get(self.internalModel, 'type') === REUSE_UPLOAD;
        const isReuseUploadEnabled = _.get(self.widget, 'layout.reuseUploadEnabled', false);
        const isReuseUploadOnlyOption = isReuseUploadEnabled && self.hasOnlyOneOption();

        return isReuseUploadSelected || isReuseUploadOnlyOption;
    };

    self.isUploadDisabled = function () {
        return self.isFileTooLarge || !self.upload.file || self.upload.inProgress;
    };

    self.selectFile = function (file) {
        // Callback für ngf-select oder ngf-change wird auch aufgerufen, wenn Dialog ein weiteres mal
        // geöffnent wird, aber bereits bevor eine Datei ausgewählt wurde. In diesem Fall ist $file == null.
        if (file === null) {
            return;
        }

        self.upload.file = file;

        if (self.widget.maxFileSize && (file.size > self.widget.maxFileSize)) {
            self.isFileTooLarge = true;
        } else {
            self.isFileTooLarge = false;
            self.error = undefined;
        }
    };

    self.getDownloadUrl = file => {
        return `${apiConfiguration.baseUrl}/file/download/${file.id}`;
    };

    self.uploadFile = function () {
        self.upload.inProgress = true;

        Upload.upload({
            url: apiConfiguration.baseUrl + '/file/upload',
            data: _.assign({
                widgetId: self.widget.id,
                shopId: self.shop.data.shop.id
            }, self.upload)
        }).then(function (result) {
            // Bei upload einer Datei implizit den type auf ACCREDITATION_BY_UPLOAD setzen. Dies ist nötig für den Fall,
            // dass ein Upload die einzige Möglichkeit ist, denn dann hatte der Benutzer keien Möglichkeit aktiv.
            // den type auszuwählen. Diese Implizite auswahl ist auch dann unproblematisch, wenn der Benutzer zwischen
            // mehreren Typen auswählen kann, da die Upload Elemente nur sichtbar sind, wenn ohnehin der Upload
            // ausgewählt wurde.
            self.internalModel.type = ACCREDITATION_BY_UPLOAD;
            self.internalModel.files = self.internalModel.files || [];
            self.internalModel.files.push(result.data);
            self.upload = {};
            self.onChange();
        }).catch(function (result) {
            self.error = result.data;

            $log.error('An error occured while uploading file', self.upload.file.name, self.error);

            // In den Fällen in denen keine lokalisierten Fehlermeldungen vorliegen zeigen wir eine
            // generische Meldung an, damit der Benutzer wenigstens erfährt, dass etwas schiefgelaufen ist.
            self.error.message = _.assign({
                de: 'Es ist ein Fehler aufgetreten',
                en: 'An Error occurred'
            }, self.error.message);
        }).finally(function () {
            self.upload.inProgress = false;
        });
    };

    self.removeFile = function (file) {
        _.remove(_.get(self.internalModel, 'files'), file);
        self.onChange();
    };

    self.isVisible = function () {
        // Das Akkreditierungswidget ist nur sichtbar, wenn min. eine der Optionen wählbar ist.
        return self.getActiveOptions().length > 0;
    };
    self.hasOnlyOneOption = function () {
        // Das Akkreditierungswidget ist nur sichtbar, wenn min. eine der Optionen wählbar ist.
        return self.getActiveOptions().length === 1;
    };

    /**
     * Ermittelt die aktiven Optionen für die Akkreditierung.
     *
     * @return {string[]}
     */
    self.getActiveOptions = () => {
        return _({
            ACCREDITATION_BY_FAX_OR_MAIL: _.get(self.widget, 'layout.accreditationByMailOrFaxEnabled'),
            ALREADY_ACCREDITED: _.get(self.widget, 'layout.alreadyAccreditedEnabled'),
            ACCREDITATION_BY_UPLOAD: _.get(self.widget, 'layout.accreditationByUploadEnabled'),
            REUSE_UPLOAD: _.get(self.widget, 'layout.reuseUploadEnabled'),
            ACCREDITATION_BY_TEXT_INPUT: _.get(self.widget, 'layout.textInputEnabled')
        }).pickBy().keys().value();
    };
}

module.exports = {
    templateUrl: 'widget/form/accreditation.component.html',
    controller: AccreditationComponentController,
    require: {
        model: 'ngModel',
        shop: '^^lmShop'
    },
    bindings: {
        widget: '<',
        isDisabled: '<'
    }
};
