'use strict';

const _ = require('lodash');

/**
 *
 * @param widgetType
 * @param isSeatingItem
 * @return {string}
 */
const getTemplatePath = (widgetType, isSeatingItem = false) => {
    switch (widgetType) {
        case 'ticket_item':
        case 'purchasable_item':
        case 'voucher_item':
            if (isSeatingItem) {
                return `widget/templates/seating_item.html`;
            }

            // Sonderbehandlung für alle Leistungen, da diese inhaltlich das gleiche Template verwenden,
            // und somit vermeiden wird drei verschiedene Templates mit dem gleichen Inhalt zu pflegen.
            return `widget/templates/purchasable_item.html`;
        default:
            return `widget/templates/${widgetType}.html`;
    }
};

/**
 * @ngInject
 */
function WidgetComponentController($log, $scope, $controller, shopService, keycloak) {
    const self = this;

    self.ngModel = undefined;
    self.isInitialized = false;
    self.template = undefined;

    /**
     * Ermittelt, ob es sich um eine Seating Leistung handelt
     *
     * @return {boolean|boolean}
     */
    const isSeatingItem = (widgetScope) => {
        if (widgetScope.shop) {
            const order = _.get(widgetScope.shop.data, 'order');
            if (order && _.has(order, 'mainItems')) {
                const seatingItem = _.find(order.mainItems, (item) => {
                    return (['purchasable_item', 'ticket_item'].includes(widgetScope.data.type)) && widgetScope.data.ident === item.ident;
                });
                return seatingItem ? !!seatingItem.seatingData : false;
            }
        }
        return false;
    };

    /**
     * @param ngModel
     */
    self.registerNgModel = ngModel => {
        if (self.ngModel && (self.ngModel !== ngModel)) {
            $log.warn('trying to register a second ngModel on widget', self.data.id);
            return;
        }

        self.ngModel = ngModel;
    };

    /**
     * @returns {boolean}
     */
    self.isValid = () => {
        // Wenn kein Model registriert wurde, dann ist dieses Widget valide.
        const ngModelIsValid = _.get(self.ngModel, '$valid', true);
        const hasServerError = self.model.hasError(self.data.path);
        return ngModelIsValid && !hasServerError;
    };

    /**
     * @returns {never}
     */
    self.isDirty = () => {
        // Wenn kein Model registriert wurde, dann ist dieses Widget niemals dirty.
        const ngModelIsDirty = _.get(self.ngModel, '$dirty', false);
        const hasServerError = self.model.hasError(self.data.path);
        return ngModelIsDirty || hasServerError;
    };

    /**
     * @returns {boolean|*}
     */
    self.isActive = () => {
        let isVisible = true;

        // Wenn es sich um eine Seating-Leistung handelt, darf diese nur dann angezeigt werden, wenn in
        // der Order(-Session) eine Leistung mit Seating-Daten übergeben wurde.
        if (self.data.itemDefinition && self.data.itemDefinition.hasSeating) {
            isVisible = isSeatingItem(self);
        }

        return isVisible && shopService.isWidgetActive(self.data, self.shop.data);
    };

    self.$onInit = () => {
        self.controller = $controller(
            self.data.type + 'WidgetController',
            {
                $scope: $scope.$new(),
                widget: self.data,
                order: self.model,
                shop: self.shop,
                parent: self.parent ? self.parent.controller : null,
                isActive: self.isActive
            }
        );

        self.controllerFactory = () => self.controller;

        self.template = getTemplatePath(self.data.type, isSeatingItem(self));
    };

    $log.debug('lm-widget created for %s (%s) %s', self.data.type, self.data.id, self.data.path || '');
}

module.exports = {
    template: '<div class="widget" ng-if="widget.isActive()" ng-class="widget.data.type" ng-include="widget.template" ng-controller="widget.controllerFactory as vm"></div>',
    controller: WidgetComponentController,
    controllerAs: 'widget',
    require: {
        parent: '^^?lmWidget',
        shop: '^^lmShop'
    },
    bindings: {
        data: '<widget',
        model: '<'
    }
};
