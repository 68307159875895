'use strict';

var _ = require('lodash');

/**
 * @ngInject
 */
function ShopLoginIndicatorComponentController($state, keycloak, keycloakUserProfile) {
    var self = this;

    self.isUserAuthenticated = function () {
        return keycloak.authenticated;
    };

    self.$onInit = function () {
        self.userProfile = _.cloneDeep(keycloakUserProfile);

        self.logoutUrl = keycloak.createLogoutUrl({
            redirectUri: $state.href('^.show', {nl: true}, {absolute: true})
        });

        self.loginUrl = keycloak.createLoginUrl({
            redirectUri: $state.href('^.show', {nl: undefined}, {absolute: true})
        });
    };
}

module.exports = {
    templateUrl: 'shop/shop.loginIndicator.component.html',
    controller: ShopLoginIndicatorComponentController
};
