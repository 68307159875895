'use strict';

/**
 * @ngInject
 */
function DefaultWidgetController(widget, order, shop, parent) {
    var vm = this;
    vm.widget = widget;
    vm.order = order;
    vm.shop = shop;
    vm.parent = parent;
    vm.model = order.getFormDataProperty(widget.path);
}

module.exports = DefaultWidgetController;
