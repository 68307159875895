"use strict";

require('angular').module('leipzigerMesse.ticketShop.frontend.onsite').component('lmChangeCalculator', {
    controller: function () {
        'ngInject';

        var self = this;

        self.$onInit = function () {
            self.amountReceived = 0;
            self.calculateChange();
        };

        self.$onChanges = function (changes) {
            if (changes.total) {
                self.calculateChange();
            }
        };

        self.calculateChange = function () {
            self.change = Math.max(0, self.amountReceived - self.total / 100);
        };

        self.isAmountSufficient = function () {
            return !(self.amountReceived < self.total / 100);
        }
    },
    templateUrl: 'onsite/onsite.changeCalculator.component.html',
    bindings: {
        total: '<'
    }
});