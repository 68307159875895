'use strict';

const widget = require('angular').module('leipzigerMesse.ticketShop.frontend.widget', [
    require('angular-translate'),
    require('../i18n'),
    require('./form'),
    require('./voucher')
]);

require('./widget.thankYou.component');
require('./widget.orderSum.component');
widget.component('lmDownloadList', require('./widget.thankYou.downloadList.component'));
widget.controller('thank_youWidgetController', require('./controllers/DefaultWidgetController'));
widget.controller('order_sumWidgetController', require('./controllers/DefaultWidgetController'));

require('./widget.checkout.component');
require('./widget.checkout.subItem.component');
widget.component('lmSelectPaymentMethod', require('./widget.checkout.selectPaymentMethod.component'));
widget.controller('checkoutWidgetController', require('./controllers/DefaultWidgetController'));

require('./widget.itemPersonalization.component');
widget.controller('item_personalizationWidgetController', require('./controllers/DefaultWidgetController'));

require('./widget.itemSelection.component');
widget.controller('item_selectionWidgetController', require('./controllers/DefaultWidgetController'));

require('./widget.contingentDisplay.component');
widget.controller('contingent_displayWidgetController', require('./controllers/DefaultWidgetController'));

widget.controller('code_entryWidgetController', require('./controllers/DefaultWidgetController'));
widget.controller('person_type_selectionWidgetController', require('./controllers/DefaultWidgetController'));
widget.controller('choice_inputWidgetController', require('./controllers/InputWidgetController'));
widget.controller('radio_choice_inputWidgetController', require('./controllers/RadioInputWidgetController'));
require('./controllers/CheckboxInputWidgetController');
widget.controller('postal_address_inputWidgetController', require('./controllers/PostalAddressWidgetController'));
widget.controller('optional_sub_formWidgetController', require('./controllers/OptionalSubformWidgetController'));
widget.controller('purchasable_itemWidgetController', require('./controllers/TicketItemWidgetController'));
widget.controller('ticket_itemWidgetController', require('./controllers/TicketItemWidgetController'));
widget.controller('voucher_itemWidgetController', require('./controllers/TicketItemWidgetController'));
widget.controller('text_elementWidgetController', require('./controllers/DefaultWidgetController'));
widget.controller('text_inputWidgetController', require('./controllers/InputWidgetController'));
widget.controller('textarea_inputWidgetController', require('./controllers/InputWidgetController'));
widget.controller('datetime_inputWidgetController', require('./controllers/DefaultWidgetController'));
widget.controller('widget_groupWidgetController', require('./controllers/WidgetGroupController'));
widget.controller('referenceWidgetController', require('./controllers/WidgetGroupController'));
widget.controller('accordion_widget_groupWidgetController', require('./controllers/WidgetGroupController'));
widget.controller('accordion_element_widget_groupWidgetController', require('./controllers/WidgetGroupController'));
widget.controller('accreditationWidgetController', require('./controllers/DefaultWidgetController'));
widget.controller('item_selection_headerWidgetController', require('./controllers/DefaultWidgetController'));
widget.controller('notificationWidgetController', require('./controllers/DefaultWidgetController'));
widget.controller('person_item_overviewWidgetController', require('./controllers/PersonItemOverviewWidgetController'));
widget.controller('separatorWidgetController', require('./controllers/DefaultWidgetController'));
widget.controller('imageWidgetController', require('./controllers/ImageWidgetController'));
widget.controller('ad_bannerWidgetController', require('./controllers/DefaultWidgetController'));
widget.controller('social_share_buttonsWidgetController', require('./controllers/DefaultWidgetController'));
widget.controller('accreditation_photo_idWidgetController', require('./controllers/DefaultWidgetController'));

widget.component('lmWidget', require('./widget.component'));
widget.component('lmWidgetFormGroup', require('./widget.formGroup.component'));
require('./widget.errorMessage.component');
require('./widget.adBanner.component');
require('./widget.socialShareButtons.component');

widget.component('lmWidgetAccordion', require('./widget.accordion.component'));
widget.component('lmWidgetAccordionGroup', require('./widget.accordionGroup.component'));
widget.component('lmWidgetPostalAddress', require('./widget.postalAddress.component'));

widget.component('lmFormState', require('./formState.component'));

widget.directive('lmWidgetControl', require('./widget.control.directive'));

module.exports = widget;
