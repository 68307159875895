'use strict';

class ErrorPageComponentController {

}

require('angular').module('leipzigerMesse.ticketShop.frontend.error').component('lmErrorPage', {
    templateUrl: 'error/error.page.component.html',
    controller: ErrorPageComponentController,
    bindings: {
        heading: '<',
        body: '<',
        bodyValues: '<'
    }
});
