'use strict';

const _ = require('lodash');

require('angular').module('leipzigerMesse.ticketShop.frontend.widget').component('lmOrderSumWidget', {
    templateUrl: 'widget/widget.orderSum.component.html',
    controller: function ($log) {
        'ngInject';
        const self = this;
        self.getTotal = () => _.get(self.shop, 'data.order.total', 0);
    },
    require: {
        shop: '^^lmShop'
    },
    bindings: {
        widget: '<',
        model: '<'
    }
});
