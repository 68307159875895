'use strict';

/**
 * @ngdoc provider
 * @name dreamhack-srs.backend.api.apiConfigurationProvider
 * @description Provider für Konfiguration für die Rest-API.
 *
 * @ngInject
 */
function apiConfigurationProvider() {

    // Config Objekt das vom Provider verwaltet wird
    var config = {
        baseUrl: null,
        authEndpointUrl: null
    };

    this.config = config;

    // Rückgabe Config Instanz
    this.$get = [function () {
        return config;
    }];

}

module.exports = apiConfigurationProvider;
