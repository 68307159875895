var _ = require('lodash');

/**
 * Service der Funktionalität für die Interoperbilität von AngularJS Code und der Google Maps JavaScript API herstellt.
 *
 * @ngInject
 */
function GoogleMapsApiInteropService($log, $window) {

    var onMapsAuthErrorCallbacks = [];

    var mapsAuthFailed = false;

    // Tritt ein Fehler bei der Authentifizierungs des Client auf, bspw. durch einen ungültigen API Key wird diese
    // Funktion aufgerufen. Dies die einzige Möglichkeit um auf diesen Fall programmatisch zu reagieren.
    $window.gm_authFailure = function () {
        mapsAuthFailed = true;

        _.each(onMapsAuthErrorCallbacks, function (callback) {
            callback();
        });
    };

    function onMapsAuthError(callback) {
        if (mapsAuthFailed) {
            // wir wissen bereits, dass die Authentifizierung fehlgeschlagen ist, dementsprechend brauchen
            // wir uns den callback gar nicht erst zu merken, sondern können ihn sofort ausführen.
            $log.debug('maps auth already failed, executing callback immediately');
            callback();
        }
        else {
            onMapsAuthErrorCallbacks.push(callback);
        }
    }

    function hasMapsAuthFailed() {
        return mapsAuthFailed;
    }

    return {
        onMapsAuthError: onMapsAuthError,
        hasMapsAuthFailed: hasMapsAuthFailed
    };
}

module.exports = GoogleMapsApiInteropService;