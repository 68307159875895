'use strict';

var _ = require('lodash');

/**
 * @ngInject
 */
function ShopLoginComponentController($state, lmAppConfig, keycloak) {
    var self = this;

    self.isIdentityBrokeringEnabled = function () {
        return !_.isEmpty(self.loginWithIdpUrls);
    };

    self.$onInit = function () {
        var keycloakOptions = {
            // Der Benutzer möchte sich explizit anmelden, daher einfach immer den Login-Screen anzeigen,
            // auch wenn der Benutzer bereits authentifiziert ist. Anderfalls würde sofort ein redirect
            // zurück zum Ticketshop erfolgen, was an dieser Stelle den Benutezr irritieren würde.
            prompt: 'login',
            // Nach erfolgreicher Anmeldung/Registrierung die auf den Kaufprozess leiten anstatt zurück
            // dieser Login Seite.
            redirectUri: $state.href('^.show', {}, {absolute: true})
        };

        self.loginUrl = keycloak.createLoginUrl(_.cloneDeep(keycloakOptions));
        self.registerUrl = keycloak.createRegisterUrl(_.cloneDeep(keycloakOptions));
        self.loginWithIdpUrls = _(_.get(lmAppConfig, 'keycloak.identityProvider', {}))
            .pickBy()
            .mapValues(function (enabled, providerName) {
                // Über die Option idpHint wird erreicht, dass der Login direkt per Idenitity Brokering mit LinkedIn erfolgt
                // ohne dass der Benutzer dies auf der Keycloak-Seite nochmals auswählen muss. Dieses Feature funktioniert
                // aber aufgrund eines Bugs in der Keycloak Version 3.2.X nicht zuverlässig.
                //
                // http://www.keycloak.org/docs/3.2/server_admin/topics/identity-broker/suggested.html
                // https://issues.jboss.org/browse/KEYCLOAK-5260
                return keycloak.createLoginUrl(_.defaults({idpHint: providerName}, keycloakOptions));
            })
            .value();
    }
}

module.exports = {
    templateUrl: 'shop/shop.login.component.html',
    controller: ShopLoginComponentController,
    bindings: {
        data: '<'
    }
};
