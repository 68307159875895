'use strict';

var _ = require('lodash');

/**
 * @ngInject
 */
function RadioChoiceComponentController($log) {
    var self = this;

    self.onSelectChange = function () {
        self.onChange({
            $event: {
                choice: self.choice
            }
        });
    };

    self.onInputChange = function () {
        // Prüfung auf nicht leeren Input ist nötig, denn sonst kann die Auswahl nicht geändert werden,
        // wenn die Eingabe aktuell leer ist. Denn durch Auswahl einer anderen Choice ist die zusätzliche Angabe
        // nicht mehr required und somit wird ein change-Event gefeuert, da die Eingabe nun wieder gültig ist;
        // dementsprechend würde die Auswahl erneut angewählt und die immernoch leere Eingabe wieder ungültig XD
        // Von außen betrachtet aber wirkt es einfach nur so als wenn der die Auswahl nicht geändert werden kann.
        if (!_.isEmpty(self.choice.inputValue)) {
            self.onSelectChange();
        }
    };
}

module.exports = {
    templateUrl: 'widget/form/radio-choice.component.html',
    controller: RadioChoiceComponentController,
    require: {
        group: '^lmRadioChoiceGroup'
    },
    bindings: {
        choice: '<',
        selected: '<',
        // es muss ein anderer Name als "disabled" verwendet werden, da sonst im IE das
        // Element und seine Kinder immer disabled sind, wenn das Attribut gesetzt ist.
        isDisabled: '<?',
        onChange: '&'
    }
};
