'use strict';

const _ = require('lodash');

class ShopSubmitOrderComponentController {
    /**
     * @param {ShopService} shopService
     * @ngInject
     */
    constructor(shopService) {
        this._shopService = shopService;
    }

    isCheckoutAllowed() {
        const isCartEmpty = this.shop.isCartEmpty();
        const isSubmitWithEmptyCartAllowed = this._shopService.isSubmitWithEmptyCartAllowed(this.shop.data);
        return (!isCartEmpty || isSubmitWithEmptyCartAllowed) && this.isSeatingQuantityValid();
    };

    isSeatingQuantityValid() {
        return this._shopService.areSeatingMinMaxQuantitiesFulfilled(this.shop.data);
    }

    getSubmitLabelTranslateKey() {
        if (!this.isSeatingQuantityValid()) {
            return 'shop.checkout.proceed.label.fixQuantity';
        }

        if (this.shop.isCartEmpty()) {
            if (this.shop.isAccreditationRequired()) {
                // Warenkorb ist leer & Akkreditierung erforderlich: Reine Akkreditierungsanfrage
                return 'shop.checkout.proceed.label.requestAccreditation';
            } else if (this._shopService.isSubmitWithEmptyCartAllowed(this.shop.data)) {
                // Ein leerer Warenkorb ist zulässig: Reine Datenübermittlung
                return 'shop.checkout.proceed.label.submit';
            } else {
                // Warenkorb ist leer & keine Akkreditierung erforderlich: Min. eine Leistung muss ausgewählt sein.
                return 'shop.checkout.proceed.label.emptyCart';
            }
        } else {
            const cartTotal = this.shop.getCartTotal();

            if (this.shop.isAccreditationRequired()) {
                if (cartTotal > 0) {
                    // Kostenplfichtige Leistungen im Warenkorb & Akkreditierung erforderlich: Bestellung mit Zahlung; vorbehaltlich einer Akkreditierung
                    return 'shop.checkout.proceed.label.pendingAccreditation';
                } else {
                    // Nur kostenlose Leistungen im Warenkorb & Akkreditierung erforderlich: Bestellung ohne Zahlung; vorbehaltlich einer Akkreditierung
                    return 'shop.checkout.proceed.label.redeem';
                }
            } else {
                if (cartTotal > 0 && this.shop.isPaymentNecessary()) {
                    // Kostenplfichtige Leistungen im Warenkorb & keine Akkreditierung erforderlich: Normale Bestellung mit Zahlung
                    return 'shop.checkout.proceed.label.payNow';
                } else {
                    // Nur kostenlose Leistungen im Warenkorb & keine Akkreditierung erforderlich: Normale Bestellung ohne Zahlung
                    return 'shop.checkout.proceed.label.redeem';
                }
            }
        }
    };

    getAcceptTermsPromptTemplate() {
        return _.get(this.shop, 'data.shop.templateContainer.frontendAcceptTermsPrompt');
    };
}

require('angular').module('leipzigerMesse.ticketShop.frontend.shop').component('lmShopSubmitOrder', {
    templateUrl: 'shop/shop.submitOrder.component.html',
    controller: ShopSubmitOrderComponentController,
    require: {
        shop: '^^lmShop'
    }
});
