'use strict';

const _ = require('lodash');
const angular = require('angular');

angular.module('leipzigerMesse.ticketShop.frontend.common').factory('injectCSS', ($document, $q) => {
    'ngInject';

    function createStyleSheetLinkElement(id, href) {
        const link = $document[0].createElement('link');

        link.id = id;
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = href;

        return link;
    }

    function injectStyleSheetLink(id, url) {
        const deferred = $q.defer();

        const isInjectionNecessary = _.isEmpty(angular.element($document[0].querySelector('link#' + id)));

        if (isInjectionNecessary) {
            // Ein <link> Element erstellen, welches auf das gewünschte Stylesheet zeigt,
            // dessen onload-Event das Promise resolved, wenn das Stylesheet geladen wurde.

            const link = createStyleSheetLinkElement(id, url);
            link.onload = deferred.resolve;
            angular.element($document[0].getElementsByTagName('head')).append(link);
        } else {
            deferred.resolve();
        }

        return deferred.promise;
    }

    return injectStyleSheetLink;
});
