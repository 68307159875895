'use strict';

/**
 * @ngInject
 */
function WidgetFormGroupDirectiveController() {

    var self = this;

    self.getClasses = function () {
        var isDirty = self.widget.isDirty();
        var isValid = self.widget.isValid();

        return [
            'col-sm-' + self.widget.data.layout.columnCount,
            'col-sm-offset-' + self.widget.data.layout.columnOffset,
            {'has-error': isDirty && !isValid}
        ];
    }

}

module.exports = {
    transclude: true,
    templateUrl: 'widget/widget.formGroup.component.html',
    controller: WidgetFormGroupDirectiveController,
    controllerAs: 'formGroup',
    require: {
        widget: '^^lmWidget'
    }
};