'use strict';

var _ = require('lodash');
var moment = require('moment-timezone');
var flatpickr = require('flatpickr');

// das deutsche Locale für den flatpickr laden, "en" wird standarmäßig geladen.
require('flatpickr/dist/l10n/de').default.de;

// Alle Datums- und Zeitangaben sollen in der deutschen Zeitzone interpretiert werden,
// da sich diese i.d.R auf eine Veranstaltung (in Leipzig) beziehen, bspw. Ankunft am
// Flughafen, Datum von Seminaren etc..
var PICKER_TIME_ZONE = 'Europe/Berlin';

var FLATPICKR_ALT_FORMATS_BY_LOCALE = {
    de: {
        date: 'l j. F Y', // Bsp. "Dienstag 24. Oktober 2017"
        dateTime: 'l j. F Y, H:i', // Bsp.  "Dienstag 24. Oktober 2017, 13:44"
    },
    en: {
        date: 'F j, Y', // Bsp. "October 24, 2017"
        dateTime: 'F j, Y H:i', // Bsp. "October 24, 2017, 13:44"
    }
};

var STATIC_FLATPICKR_OPTIONS = {
    altInput: true,
    allowInput: false,
    time_24hr: true,
    minuteIncrement: 1,
    dateFormat: 'Y-m-dTH:i:S' // Zeitzonen agnostischer "ISO 8601 like" datetime String
};

function utcToLocalDate(utcDateString) {
    var localDate = moment(utcDateString).tz(PICKER_TIME_ZONE);
    return localDate.format('YYYY-MM-DDTHH:mm:ss');
}

/**
 * @ngInject
 */
function DateTimeInputComponentController($log, $element, $translate) {
    var self = this;

    var dateTimePicker = undefined;

    function render() {
        if (!dateTimePicker) {
            return;
        }

        if (self.model.$isEmpty(self.model.$viewValue)) {
            return dateTimePicker.setDate(undefined);
        }

        // Die Werte in unserem System sind UTC Daten, dies müssen wir bzgl. der vorgegebenen Zeitzone
        // in "Tischkalender/Wanduhr" Angaben transformieren und dann an den Picker weiterreichen.
        dateTimePicker.setDate(utcToLocalDate(self.model.$viewValue));
    }

    function onChange(selectedDates, dateString) {
        $log.debug('onChange():', selectedDates, dateString);

        if (_.isEmpty(selectedDates)) {
            return self.model.$setViewValue(undefined);
        }

        // Die "Tischkalender/Wanduhr" Angaben bzgl. der vorgegebenen Zeitzone interpretieren
        // und aufgrund dieser dann in ein UTC Datum wandeln, mit dem wir im System arbeiten.
        var interpretedDate = moment.tz(dateString, PICKER_TIME_ZONE);

        self.model.$setViewValue(interpretedDate.toISOString());
    }

    self.togglePicker = function () {
        if (dateTimePicker) {
            dateTimePicker.toggle();
        }
    };

    self.clearPicker = function () {
        if (dateTimePicker) {
            dateTimePicker.clear();
        }
    };

    self.$onInit = function () {
        self.model.$render = render;
    };

    // Initialisierung des flatpicker muss im $postLink erfolgen, da der picker das eigentliche <input> Element
    // austauscht, wenn man die Option "altInput" verwendet und DOM Manipulation ist von AngularJS aus erst hier
    // erlaubt, da sonst das Rendering der Komponente nicht korrekt abgeschlossen werden kann.
    self.$postLink = function () {
        // Dadurch dass durch den Wechsel der Sprache ein reload der Seite ausgelöst wird,
        // müssen wir hier nicht extra darauf achten, die Sprache in diesem Fall umzustellen,
        // da die Komponente ohnehin neu initialisiert werden wird.
        var activeLocale = $translate.use() || 'en';
        var isTimeEnabled = !!self.enableTime;
        var altFormat = FLATPICKR_ALT_FORMATS_BY_LOCALE[activeLocale];

        var options = _.extend({}, STATIC_FLATPICKR_OPTIONS, {
            locale: activeLocale,
            enableTime: isTimeEnabled,
            minDate: self.minDate ? utcToLocalDate(self.minDate) : null,
            maxDate: self.maxDate ? utcToLocalDate(self.maxDate) : null,
            altFormat: isTimeEnabled ? altFormat.dateTime : altFormat.date,
            onChange: onChange
        });

        dateTimePicker = flatpickr($element.find('input')[0], options);

        // Attribut disabled nur setzen, wenn der Wert tatsächlich truthy ist, denn sonst wird zwar als Wert "false"
        // gesetzt, das Eingabefeld ist aber tritzdem inaktiv, da die reine Existenz des Attributs bereits ausreicht.
        // Weiterhin setzten wir diesen Zustand nur initial, so das eine Änderung nicht berücksichtigt werden würde,
        // was aber aktuell in Ordnung ist, da bisher nicht vorgesehen ist, da die Entscheidung ob ein Feld read-only
        // ist alleine serverseitig festgelegt wird.
        if (self.isDisabled) {
            dateTimePicker._input.setAttribute('disabled', self.isDisabled);
        }

        // Angular Template-Interpolation funktioniert nicht für Darstellung des Platzhalters im Eingabefeld,
        // da der flatpickr ein eigenes DOM Element einbaut, welches nicht am Angular-Templating teilnimmt.
        if (!_.isEmpty(self.placeholder)) {
            dateTimePicker._input.setAttribute('placeholder', self.placeholder);
        }
    };

    self.$onDestroy = function () {
        if (dateTimePicker) {
            dateTimePicker.destroy();
        }
    };
}

module.exports = {
    templateUrl: 'widget/form/dateTimeInput.component.html',
    controller: DateTimeInputComponentController,
    require: {
        model: 'ngModel'
    },
    bindings: {
        placeholder: '@?',
        isDisabled: '<?',
        enableTime: '<?',
        minDate: '<?',
        maxDate: '<?',
    }
};
