module.exports={
  "shop.checkout.noReturn": "You will be redirected to the payment page. Please note that you cannot return to the registration page afterwards.",
  "shop.checkout.codeEntry.remove": "submit another code",
  "shop.checkout.codeEntry.button": "Submit",
  "shop.checkout.codeEntry.placeholder": "Personal login",
  "shop.checkout.header": "Summary of your registration",
  "shop.checkout.proceed.label.pendingAccreditation": "Confirm and submit",
  "shop.checkout.proceed.label.payNow": "Confirm and submit",
  "shop.checkout.proceed.label.redeem": "Confirm and submit",
  "shop.checkout.proceed.label.emptyCart": "Please log in using your personal login details.",
  "shop.header.link.contact": "Contact information",
  "shop.thankYou.header": "Your registration has been successfully submitted.",
  "shop.thankYou.infoInvoice": "Thank you for your registration. You will receive a confirmation by email.<br/>Please note that you can re-enter the registration portal at any time using your login data to review your registration. To modify your registration, please contact the Registration Help Desk.",
  "shop.checkout.codeEntry.error.unsent": "You have an unredeemed code. Please click 'Submit'."
}
