'use strict';

require('angular').module('leipzigerMesse.ticketShop.frontend.common').component('lmStepNavigationElement', {
    templateUrl: 'common/stepNavigation/stepNavigation.element.component.html',
    controller: function StepNavigationElementComponentController($document, stepNavigationConfiguration) {
        'ngInject';

        var self = this;

        self.config = stepNavigationConfiguration;

        /**
         * Den repräsentierten Schritt fokussieren.
         */
        self.gotoStep = function () {
            // Navigation zu einem Schritt ist nur zulässig und sinnvoll, wenn dieser bereits sichtbar ist.
            if (self.shop.isStepVisible(self.step)) {
                var element = $document[0].getElementById(self.step.id);
                $document.scrollToElement(element, stepNavigationConfiguration.scrollOffset, stepNavigationConfiguration.scrollDurationMs);
            }
        };

        self.isStepComplete = function () {
            return self.step.$$completedInPreviousStage || self.shop.isStepComplete(self.step.id);
        };

        self.isStepIncomplete = function () {
            return self.shop.isStepIncomplete(self.step.id);
        };
    },
    require: {
        shop: '^^lmShop'
    },
    bindings: {
        step: '<',
        stepNumber: '<',
        isLastStep: '<'
    }
});
