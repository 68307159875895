'use strict';

const _ = require('lodash');

// Ident der Reihe von ITF Veranstaltungen. Wird genutzt um zu bestimmen, ob eine ITF-spezifische
// Sonderbehandlung/Darstellung für den vorliegenden Shop erfolgen muss.
const ITF_GROUP_IDENT = 'ITF';

const CONDITION_CHECKS_BY_TYPE = {
    constant: _.property('value'),
    has_value: checkIfValueIsSet,
    is_value: checkIfValueIsExpectedValue,
    billing_country: checkIfBillingCountryMatches,
    item_in_cart: checkIfItemIsInCart
};

const DEFAULT_DATA_PATHS_FROM_MODEL = [
    'user.salutation',
    'user.title',
    'user.firstName',
    'user.lastName'
];

const ITEM_TYPES = new Set(['purchasable_item', 'ticket_item', 'voucher_item']);

function checkIfBillingCountryMatches(condition, shop, shopService) {
    const isBillingAddressActive = !_.isEmpty(shopService.getWidgetsByPredicate(shop, function (widget) {
        return widget.path === 'billing.address';
    }, true));

    const addressModel = shop.model.getSubmodel(isBillingAddressActive ? 'billing.address' : 'user.address');
    const country = addressModel.getValue('country');

    return _.includes(condition.countryIsoCodes, country);
}

function checkIfValueIsSet(condition, shop) {
    return shop.model.hasValue(condition.path);
}

function checkIfValueIsExpectedValue(condition, shop) {
    return shop.model.getValue(condition.path) === condition.expectedValue;
}

function checkIfItemIsInCart(condition, shop) {
    const allowedItemModels = _.pick(shop.model.items, condition.itemIdents);
    return _.some(allowedItemModels, (model) => !_.isEmpty(model));
}

class ShopService {
    checkRuleCondition(condition, shop) {
        const check = _.get(CONDITION_CHECKS_BY_TYPE, condition.type, _.constant(true));
        return check(condition, shop, this);
    }

    determineRuleFulfillment(rules, shop) {
        return _(rules)
            .groupBy('type')
            .mapValues(rulesOfSameType => {
                return _.every(rulesOfSameType, rule => {
                    return this.checkRuleCondition(rule.selectedCondition, shop);
                });
            })
            .value();
    }

    getItemAmount(itemWidget, shop) {
        return (_.get(shop, 'model.items.' + itemWidget.ident, [])).length;
    }

    getWidgetsByPredicateAccumulator(shop, predicate, widget, requireActive, includeSubWidgets) {
        let result = [];

        if (!requireActive || this.isWidgetActive(widget, shop)) {
            if (predicate(widget)) {
                result.push(widget);
            }

            result = result.concat(_.flatMap(widget.widgets, w => {
                return this.getWidgetsByPredicateAccumulator(shop, predicate, w, requireActive, includeSubWidgets);
            }));

            if (includeSubWidgets && widget.subWidgetContainer) {
                result = result.concat(this.getWidgetsByPredicateAccumulator(shop, predicate, widget.subWidgetContainer, requireActive, includeSubWidgets));
            }
        }

        return result;
    }

    /**
     * Setzt isBackendCall für Weiterverarbeitung
     * @param {boolean} value
     */
    setIsBackendCall(value) {
        this.isBackendCall = value;
    }

    /**
     * Findet alle Widgets eines Shops die ein Prädikat erfüllen.
     *
     * @param shop
     * @param predicate
     * @param requireActive
     * @param includeSubWidgets
     * @return {[Widget]} Alle Widgets die das Prädikat erfüllen in pre-order Reihenfolge.
     */
    getWidgetsByPredicate(shop, predicate, requireActive = false, includeSubWidgets = false) {
        return this.getWidgetsByPredicateAccumulator(shop, predicate, shop.shop.widgetContainer, requireActive, includeSubWidgets);
    }

    /**
     * Prüft ob ein aktives Leistungswidget mit einem bestimten {@code ident} von {@code dataRoot} aus erreichbar ist.
     */
    hasActiveItemWidgetForIdent(shop, ident, dataRoot) {
        let widgets = this.getWidgetsByPredicateAccumulator(shop, widget => {
            return (widget.type === 'ticket_item' || widget.type === 'purchasable_item') && (widget.ident === ident);
        }, dataRoot, true, true);

        return widgets.length > 0;
    }

    isWidgetActive(widget, shop) {
        // Status
        if (widget.displayStatus === 'INACTIVE') return false;

        if (widget.displayStatus === 'INVITATION_ONLY' && (widget.type === 'ticket_item' || widget.type === 'purchasable_item')) {
            return !!this.getItemAmount(widget, shop);
        }

        const fulfillment = this.determineRuleFulfillment(widget.rules, shop);

        // Wenn keine Regel vom Typ 'is_active' definiert war, dann ist dieses Widget auf jeden Fall sichtbar.
        const isActive = _.get(fulfillment, 'is_active', true);

        // Wenn keine Regel vom Typ 'is_not_active' definiert war, dann ist dieses Widget auf jeden Fall sichtbar.
        const isNotActive = _.get(fulfillment, 'is_not_active', false);

        // Wenn eine is_not_active Regel erfüllt ist, dominiert diese
        return isActive && !isNotActive;
    }

    /**
     * Ermittelt ob es sich bei dem übergebenen Shop um einen ITF Shop handelt.
     *
     * @param shop
     * @return {boolean}
     */
    isItfShop(shop) {
        return _.get(shop, 'event.groupIdent') === ITF_GROUP_IDENT;
    }

    isOnsiteShop(shop) {
        return _.get(shop, 'isOnsite', false);
    }

    /**
     * Prüft, ob MinMax-Bestellmengen für Seating-Leistungen erfüllt sind
     *
     * @param shopData Dto-Daten aus Shop-Endpunkt
     * @param itemCountOffset Offset um zu prüfen, ob auch nach dem Löschen von
     *                        X Items MinMax-Bestellmengen erfüllt wären
     * @return {boolean}
     */
    areSeatingMinMaxQuantitiesFulfilled(shopData, itemCountOffset = 0) {
        if (this.isBackendCall) {
            return true;
        }
        if (!_.has(shopData, 'seatingConfig')) {
            return true;
        }
        const seatingItemsCount = this.countSeatingItems(shopData) + itemCountOffset;
        const minQuantity = _.get(shopData, 'seatingConfig.minQuantity');
        const maxQuantity = _.get(shopData, 'seatingConfig.maxQuantity');

        if (minQuantity && minQuantity > seatingItemsCount) {
            return false;
        }
        return !(maxQuantity && maxQuantity < seatingItemsCount);
    }

    /**
     * Zählt die vorhandenen Seating-Leistungen
     *
     * @param shopData Dto-Daten aus Shop-Endpunkt
     * @return {int}
     */
    countSeatingItems(shopData) {
        return _.get(shopData, 'order.mainItems', []).filter(item => Object.keys(item).findIndex(key => key === 'seatingData') !== -1).length;
    };

    hasActiveItemWidgets(shop) {
        const itemWidgets = this.getWidgetsByPredicate(shop, w => ITEM_TYPES.has(w.type), true);
        return !_.isEmpty(itemWidgets);
    }

    /**
     * Ermittelt ob für den übergebenen Shop das Abschicken einer Order mit leerem Warenkorb zulässig ist.
     * @param shop
     * @return {boolean}
     */
    isSubmitWithEmptyCartAllowed(shop) {
        return !this.hasActiveItemWidgets(shop);
    }

    isItemModelWithoutPersonalisation(itemModel) {
        return _.every(DEFAULT_DATA_PATHS_FROM_MODEL, (dataPath) => {
            return _.isEmpty(itemModel.getData(dataPath));
        });
    }

    applyOrderFormDataToItemModel(order, itemModel) {
        _.each(DEFAULT_DATA_PATHS_FROM_MODEL, function (dataPath) {
            var value = order.getData(dataPath);
            if (!_.isEmpty(value)) {
                itemModel.setData(dataPath, value);
            }
        });
    }
}

require('angular').module('leipzigerMesse.ticketShop.frontend.shop').service('shopService', ShopService);
