'use strict';

const _ = require('lodash');
const moment = require('moment');

module.exports = function ($stateProvider) {
    'ngInject';

    $stateProvider
        .state('login', {
            templateUrl: 'auth/login.html',
            controller: 'LoginController as login',
            params: {
                onSuccessUrl: '/',
                errorMessage: undefined
            },
            resolve: {
                styling: (lmAppConfig, injectCSS) => {
                    'ngInject';

                    // cache-busting paramter an URL anfügen, da im Netzwerk in dem sich die OnSite-Kassensysteme
                    // befinden anscheinend ein Proxy drinhängt, der zu aggressiv cached.
                    const stylingHref = _.get(lmAppConfig, 'api.stylingUrl') + `?t=${moment().unix()}`;
                    return injectCSS('shopstyling_generic', stylingHref);
                }
            }
        });
};
