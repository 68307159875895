'use strict';

var _ = require('lodash');

/**
 * Direktive die die Validität eines ngModel ausschließlich in Abhängigkeit eines extern Vorgegebenen Ausdrucks bestimmt.
 *
 * @ngInject
 */
function ValidateValidationDirective() {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elem, attr, ngModel) {
            scope.$watch(attr.lmValidate, function (isValid) {
                // Hier explizit auf boolean "casten", da undefined und null durch
                // $setValidity() gesondert interpretiert und nicht als "falsy".
                ngModel.$setValidity('lmValidate', !!isValid);
            });
        }
    };
}

module.exports = ValidateValidationDirective;