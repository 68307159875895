module.exports={
  "api": {
    "baseUrl": "/index.php/api/v1/frontend",
    "authEndpointUrl": "/index.php/api/v1/backend/security/auth",
    "imageUrl": "/index.php/image",
    "stylingUrl": "/index.php/styling",
    "seatingFrontendUrl": "https://seating.leipziger-messe.de"
  },
  "keycloak": {
    "url": "https://sso.leipziger-messe.de/auth/",
    "realm": "LeipzigerMesse",
    "clientId": "ticketshop-frontend",
    "refreshThreshold": 60,
    "refreshInterval": 30,
    "timeout": 5,
    "identityProvider": {
      "facebook": true,
      "linkedin": true
    }
  },
  "debug": {
    "enabled": false
  },
  "build": {
    "LIVE_RELOAD": false,
    "FAVICON_FILE": "/img/favicon.ico",
    "ASSETS_URL": "/static",
    "GOOGLE_MAPS_API_KEY": "AIzaSyDmnDsYaJu-rpUjKhdv0WboRW9fd8Cwavk"
  }
}
