'use strict';

var _ = require('lodash');

require('angular').module('leipzigerMesse.ticketShop.frontend.widget').controller('checkbox_choice_inputWidgetController', function (widget, order) {
    'ngInject';
    
    var vm = this;

    vm.widget = widget;

    var model = order.getFormDataProperty(widget.path);

    // Leere Auswahl im model initialisieren falls bisher nicht vorhanden
    var selectedChoices = model() || [];
    model(selectedChoices);

    vm.model = model;
});