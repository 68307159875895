'use strict';

var _ = require('lodash');

require('angular').module('leipzigerMesse.ticketShop.frontend.shop').component('lmShopHeader', {
    transclude: true,
    templateUrl: 'shop/shop.header.component.html',
    controller: function ($window, $state, $translate, shopService, auth, keycloak) {
        'ngInject';

        const self = this;


        self.getAlternativeLanguage = function () {
            return ($translate.use() === 'de') ? 'en' : 'de';
        };

        self.toggleLanguage = function () {
            // Aktuell wird fest davon ausgegangen, dass nur zwei Sprachen: Englisch und Deutsch, unterstützt werden.
            $translate.use(self.getAlternativeLanguage());
        };

        self.isLanguageSwitcherVisible = function () {
            return _.get(self, 'showLanguageSwitcher', true) && !self.isItfShop();
        };

        self.isContactStateActive = function () {
            return $state.is('root.shop.contact');
        };

        self.closeWindow = function () {
            $window.close();
        };

        self.logout = () => auth.logout()
            .then(() => $state.reload());

        self.isLoginAllowed = function () {
            return keycloak && _.get(self.shop, 'isLoginAllowed', false);
        };

        self.isItfShop = function () {
            return shopService.isItfShop(self.shop);
        };

        self.isOnsiteShop = function () {
            return shopService.isOnsiteShop(self.shop);
        };

        self.getLegalPageUrl = function () {
            return $state.href('root.shop.legal', {}, {absolute: true});
        };

        self.getContactPageUrl = function () {
            return $state.href('root.shop.contact', {}, {absolute: true});
        };

        self.$onInit = function () {
            self.user = auth.getIdentity();
        };
    },
    controllerAs: 'header',
    bindings: {
        showLanguageSwitcher: '<',
        shop: '<',
        orderTimeout: '<',
        containsSeating: '<',
        usesPytMode: '<'
    }
});
