'use strict';

const _ = require('lodash');

/**
 * @ngInject
 */
function i18nTranslationLoaderFactory($log, $q, i18nConfig) {
    const defaultTranslations = {
        de: require('./messages/messages.de.json'),
        en: require('./messages/messages.en.json')
    };

    const itfTranslations = {
        de: require('./messages/messages.itf.de.json'),
        en: require('./messages/messages.itf.en.json')
    };

    return function (options) {
        const language = options.key;

        let translations;

        if (i18nConfig.useItfTranslations) {
            $log.debug('loading ITF translations for "%s"', language);
            translations = _.defaults({}, itfTranslations[language], defaultTranslations[language]);
        } else {
            $log.debug('loading default translations for "%s"', language);
            translations = defaultTranslations[language];
        }

        $log.debug(translations);

        return $q.resolve(translations);
    };
}

module.exports = i18nTranslationLoaderFactory;
