'use strict';

require('angular').module('leipzigerMesse.ticketShop.frontend.common').component('lmStepNavigation', {
    templateUrl: 'common/stepNavigation/stepNavigation.component.html',
    require: {
        shop: '^^lmShop'
    },
    bindings: {
        steps: '<'
    }
});
