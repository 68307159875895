'use strict';

var _ = require('lodash');
var $ = require('jquery-browserify');

var ADDRESS_DATA_PATHS = [
    'street',
    'houseno',
    'postalCode',
    'city',
    'country'
];

// Länderabhängige Sonderfälle beim Mapping von Adresskomponenten.
// Wenn pro Land ein Datenpfad mehrfach gemappt wird, dient dies dazu die Mehrdeutigkeiten in den Daten abzufangen,
// da teilweise ein relevantes Datum mal in dem einen mal in der anderen Komponente definierti ist,  aber im Regelfall
// enthält das Ergebnis nur eine der Komponenten, so dass dadurch ein Fallback erzielt wird.
var COMPONENT_MAPPING_BY_COUNTRY = {
    CZ: {
        route: 'street',
        street_number: 'houseno',
        postal_code: 'postalCode',
        country: 'country',
        locality: 'city',
        sublocality_level_1: 'city',
    },
    GB: {
        route: 'street',
        street_number: 'houseno',
        postal_town: 'city',
        country: 'country',
        postal_code_prefix: 'postalCode',
        postal_code: 'postalCode'
    },
    TR: {
        route: 'street',
        street_number: 'houseno',
        postal_code: 'postalCode',
        administrative_area_level_1: 'city',
        country: 'country'
    }
};

var DEFAULT_COMPONENT_MAPPING = {
    route: 'street',
    street_number: 'houseno',
    postal_code: 'postalCode',
    locality: 'city',
    country: 'country'
};

/**
 * Sucht ein Länderspezifisches Mapping für Adresskomponenten sofern vorhanden, ansonsten das Standardmapping.
 */
function getComponentMapping(place) {
    var countryComponent = _.find(place.address_components, function (component) {
        return component.types[0] === 'country';
    });

    var countryIsoCode = _.get(countryComponent, 'short_name');

    return _.get(COMPONENT_MAPPING_BY_COUNTRY, countryIsoCode, DEFAULT_COMPONENT_MAPPING);
}

/**
 * @ngInject
 */
function WidgetPostalAddressComponentController($log, $scope, $timeout, $element, googleMapsApiInteropService, i18nFilter) {
    var self = this;

    self.propertiesByPath = {};

    self.hasInputForHouseNumber = false;

    // DOM Element des Inputs für die Straße
    var streetInput = undefined;

    function findCountryChoice(countryIsoCode) {
        var countryChoiceWidget = _.find(_.get(self.widget, 'subWidgetContainer.widgets'), {
            type: 'choice_input',
            path: 'country',
        });

        var choices = countryChoiceWidget ? i18nFilter(countryChoiceWidget.choices) : [];

        return _.find(choices, {value: countryIsoCode});
    }

    function setAddressDataFromPlace(place) {
        if (_.isEmpty(place.address_components)) {
            // In diesem Fall hat der Benutzer keinen Vorschlag ausgewählt,
            // sondern die Eingabe per Enter o.Ä. abgeschlossen, daher
            // den Wert aus dem Eingabefeld unberührt lassen und auch die
            // anderen Adressfelder nicht modifizieren.
            return;
        }

        $scope.$applyAsync(function () {
            // Zunächst alle Felder leeren, damit keine kaputte Adressen entstehen,
            // wenn der Vorschlag nur partielle Daten enthält.
            _.each(self.propertiesByPath, function (property) {
                property(undefined);
            });

            var componentMapping = getComponentMapping(place);

            _.each(place.address_components, function (component) {

                // Es ist ausreichend den ersten Eintrag im "types" Array zu betrachten. Die übrigen Einträgen,
                // wenn überhaupt vorhanden, sind für unsere Zwecke unnötig feingranular.
                var path = _.get(componentMapping, component.types[0]);

                if (path) {
                    switch (path) {
                        case 'country':
                            // Von der Google API bekommen wir für das Land nur einen ISO Code bzw. den Ländernamen,
                            // wir benötigen aber die korrespondierende Auswahl.
                            self.propertiesByPath[path](findCountryChoice(component.short_name));
                            break;

                        default:
                            self.propertiesByPath[path](component.long_name);
                            break;
                    }
                }
            });

            // Wird die Hausnummer nicht extra abgefragt stellen wir sie der Straße voran, sofern vorhanden.
            if (!self.hasInputForHouseNumber) {
                self.propertiesByPath['street'](_.compact([
                    self.propertiesByPath['street'](),
                    self.propertiesByPath['houseno'](),
                ]).join(' '));
            }
        });
    }

    /**
     * Ermittelt ob die Warnung bzgl. einer möglicherweise fehlenden Hausnummer angezeigt werden soll.
     *
     * @returns {boolean}
     */
    self.isWarningForMissingHouseNumberShown = function () {
        return !(self.hasInputForHouseNumber || self.warningForMissingHouseNumberDismissed || /[0-9]/.test(self.propertiesByPath['street']()));
    };

    self.$onInit = function () {
        self.propertiesByPath = _.transform(ADDRESS_DATA_PATHS, function (acc, path) {
            acc[path] = self.model.getFormDataProperty(path);
        }, {});
    };

    self.$postLink = function () {
        if (!window.google || self.widget.isReadOnly || !self.widget.enableAutocomplete) {
            return;
        }

        // Das Suchen der Eingabefelder um einen digest-Zyklus verzögern, damit
        // das enthaltene ngRepeat die Chance hatte die Unterwidgets zu rendern.
        $timeout(function () {
            self.hasInputForHouseNumber = !!$('input[name="houseno"]', $element[0])[0];

            streetInput = $(':input[name="street"]', $element[0])[0];

            if (streetInput && !googleMapsApiInteropService.hasMapsAuthFailed()) {
                var autocomplete = new window.google.maps.places.Autocomplete(streetInput, {types: ['geocode']});

                autocomplete.addListener('place_changed', function onAddressSelection() {
                    setAddressDataFromPlace(autocomplete.getPlace());
                });

                googleMapsApiInteropService.onMapsAuthError(function () {
                    $scope.$applyAsync(function () {
                        if (streetInput) {
                            // Wenn es einen Authentifizierungsfehler mit der Google Maps API gab, wird das Eingabefeld
                            // disabled und ein Text im Sinne von "Oops! an error occured..." wird angezeigt.
                            // Das wollen wir nicht, denn in diesem Fall soll das Eingabefeld einfach ohne autocomplete
                            // nutzbar bleiben, denn sonst könnte der Kunde keine vollständige Adresse mehr eingeben.
                            _.each(['placeholder', 'style', 'disabled'], function (attr) {
                                streetInput.removeAttribute(attr);
                            });
                        }
                    })
                });
            }
        });
    };
}

module.exports = {
    templateUrl: 'widget/widget.postalAddress.component.html',
    controller: WidgetPostalAddressComponentController,
    bindings: {
        widget: '<',
        model: '<'
    }
};
