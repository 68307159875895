'use strict';

var analyticsModule = require('angular').module('leipzigerMesse.ticketShop.frontend.analytics', [
    require('angulartics'),
    require('angulartics-google-tag-manager'),
]);

analyticsModule.component('lmGoogleTagManager', require('./analytics.googleTagManager.component'));

analyticsModule.config(function ($analyticsProvider) {

    // die $analytics.transactionTrack() Methode definieren, da eventTrack() nicht geeignet ist die Daten so in den
    // dataLayer zu schreiben, wie sie vom Google Tag Manager für GA Enhanced ECommerce benötigt werden.

    $analyticsProvider.registerTransactionTrack(function (event, eCommerceData) {
        // sicherstellen, dass der dataLayer für den Google Tag Manager auch da ist.
        var dataLayer = window.dataLayer = window.dataLayer || [];

        dataLayer.push({
            event: event,
            ecommerce: eCommerceData || {}
        });
    });
});

module.exports = analyticsModule;
