const _ = require('lodash');

/**
 * Säubert die von der API erhaltene formData rekursiv, so dass "data", "error" und "subFormModel" mit Wert
 * "null" oder "undefined" entfernt werden.
 *
 * Die Eingabedaten werden nicht manipuliert, es wird ein neues Objekt erstellt, jedoch als shallow copy,
 * d.h. alle Werte die behalten werden sind shared mit den Originaldaten.
 *
 * @param {Object} formData Das zu säubernde formData Objekt.
 */
module.exports = function cleanFormData(formData) {
    // Alle ungewünschten Werte auf "undefined" mappen, anschließend per _.omitBy() rausfiltern.
    // Alternativ könnte man wohl auch per _.reduce() nur die properties aufsammeln, die man
    // behalten möchte, aber der code ist so simpler, wenn auch weniger performant,
    // wegen der doppelten Iteration und extra Object-Instanziierung.
    return _.omitBy(_.mapValues(formData, (value, key) => {
        switch (key) {
            case 'data':
            case 'error':
                return _.isNull(value) ? undefined : value;
            case 'subFormModel':
                return value ? {formData: cleanFormData(value.formData || {})} : undefined;
            default:
                return cleanFormData(value);
        }
    }), _.isUndefined)
};
