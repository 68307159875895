'use strict';

var _ = require('lodash');

/**
 * Service zur Abwicklung der Authentifizierung & Authorisierung.
 *
 * @ngInject
 */
function auth($log, $http, $q, apiConfiguration, authJwt, authHelper) {

    /**
     * Initialisierungs
     */
    function init() {
        var jwt = authJwt.get();

        if (jwt && authHelper.isJwtExpired(jwt)) {
            $log.warn('JWT has expired!');
            jwt = authJwt.set(undefined);
        }
    }

    function login(credentials) {
        return $http.post(apiConfiguration.authEndpointUrl, credentials)
            .then(function (res) {
                return authJwt.set(res.data.token);
            });
    }

    function logout() {
        // Da Authentifizierung per JWT bezogen auf den server stateless ist reicht es wenn wir einfach
        // lokal den JWT löschen, da nun alle weiteren Requests nicht mehr authentifiziert sind.
        return $q.resolve(authJwt.set(undefined));
    }

    function requestPasswordReset(credentials) {
        return $http.post(apiConfiguration.baseUrl + '/security/requestPasswordReset', credentials);
    }

    function resetPassword(credentials) {
        return $http.post(apiConfiguration.baseUrl + '/security/resetPassword', credentials);
    }

    function getIdentity() {
        return authJwt.getClaims();
    }

    function isAuthenticated() {
        return !_.isEmpty(getIdentity());
    }

    return {
        login: login,
        logout: logout,
        requestPasswordReset: requestPasswordReset,
        resetPassword: resetPassword,
        init: init,
        isAuthenticated: isAuthenticated,
        getIdentity: getIdentity
    }
}

module.exports = auth;