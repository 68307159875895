'use strict';

require('angular').module('leipzigerMesse.ticketShop.frontend.widget').component('lmCheckoutSubItem', {
    templateUrl: 'widget/widget.checkout.subItem.component.html',
    require: {
        checkout: '^^lmCheckoutWidget'
    },
    bindings: {
        itemData: '<',
        itemIdent: '<'
    }
});
