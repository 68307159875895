'use strict';

const _ = require('lodash');
const uuid = require('node-uuid');


class ItemSelectionWidgetComponentController {

    /**
     * @ngInject
     */
    constructor($scope, $log) {
        this.$scope = $scope;
        this.$log = $log;
        this.id = uuid.v4();
    }
    onValueChange() {
        if (this.model()) {
            this.shop.setActiveItemSelection(this.widget.path, this);
        } else {
            // this.shop.setActiveItemSelection(this.widget.path, undefined);

            // wir verhindern das direkte abwählen, kann nur durch auswählen
            // einer anderen Selection abgewählt werden
            this.model(true);
        }
    }


    isOnlySelectionForPath() {
        return (this.shop.getItemSelectionCount(this.widget.path) < 2);
    }

    $onInit() {
        this.shop.registerItemSelection(this.widget.path, this);
        if (this.model()) {
            this.shop.setActiveItemSelection(this.widget.path, this);
        }

        this.$scope.$watch(() => this.shop.getActiveItemSelection(this.widget.path), newVal => {
            this.model(this === newVal);
        });
    }

}

require('angular').module('leipzigerMesse.ticketShop.frontend.widget').component('lmItemSelectionWidget', {
    templateUrl: 'widget/widget.itemSelection.component.html',
    controller: ItemSelectionWidgetComponentController,
    require: {
        shop: '^^lmShop'
    },
    bindings: {
        widget: '<',
        model: '<'
    }
});
