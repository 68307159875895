'use strict';

var filzesize = require('filesize');

/* @ngInject */
function filesizeFilter() {
    return function (sizeInBytes, base) {
        return filzesize(sizeInBytes, {base: base || 2});
    };
}

module.exports = filesizeFilter;