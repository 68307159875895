'use strict';

/**
 * @ngInject
 */
function config($stateProvider) {
    $stateProvider
        .state('root.contact', {
            url: 'contact',
            templateUrl: 'contact/contact.html'
        })
}

module.exports = config;