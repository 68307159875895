'use strict';

const _ = require('lodash');
const angular = require('angular');
const ShopModel = require('./shop.model.class');
const cleanFormData = require('../util/cleanFormData');

/**
 * @ngInject
 */
function Shop($resource, apiConfiguration) {
    const endpointUrl = apiConfiguration.baseUrl + '/shop/:id';

    function shopify(data) {
        data = _.cloneDeep(data);

        // Folgendes bezieht sich auf einen Code Stand vom 2023-01-16:
        // Die API Serialisiert derzeit für alle Datenpfade auch "null"-Werte, dies hat zur Folge,
        // dass wenn die Felder selber nicht in der GUI manipuliert werden, diese bei einem submit
        // auch wieder zurück zur API gesendet werden, da noch so im Model enthalten. Das ist bspw.
        // im Falle von Code-Einlösungen problematisch, da die Personendaten an einem Code dann
        // durch null-Werte im Submit überschrieben werden. Um das Risiko von Kollateralschäden
        // möglichst gering zu halten wurde die Variante gewählt das Problem hier im client zu
        // lösen, indem die null-Werte hier rausgefiltert werden, anstatt diese auf Seite der API zu
        // ignorieren,  da die relevante Code-Stelle (OrderFormModel::applyFormData) von vielen
        // Stellen aufgerufen wird und nur schwierig abzuschätzen ist, ob eine Anpassung dort
        // evtl. Auswirkung an anderer Stelle hätte.
        const formData = _.get(data, 'model.formData', {});
        _.set(data, 'model.formData', cleanFormData(formData));

        data.model = new ShopModel(data.model);
        return new Shop(data);
    }

    function transformResponse(data) {
        return shopify(angular.fromJson(data));
    }

    const Shop = $resource(endpointUrl, {id: '@shop.id'}, {
        get: {
            method: 'GET',
            url: apiConfiguration.baseUrl + '/shop/:id/:orderId',
            transformResponse: transformResponse
        },
        getBySlug: {
            method: 'GET',
            url: apiConfiguration.baseUrl + '/shop/bySlug/:slug/:orderId',
            transformResponse: transformResponse
        },
        save: {
            method: 'POST',
            transformResponse: transformResponse
        },
        submitOrder: {
            method: 'POST',
            url: apiConfiguration.baseUrl + '/order',
            transformResponse: transformResponse
        },
        submit: {
            method: 'POST',
            transformResponse: transformResponse
        },
        applyLastOrderData: {
            method: 'POST',
            url: endpointUrl + '/useLastAddress',
            transformResponse: transformResponse
        }
    });

    /**
     * Erzeugt anhand von Daten eine neue Shop-Resource Instanz, wobei die gleiche
     * Transformationslogik angewendet wird, wie bei einer echten API Response.
     *
     * @param obj Die zu übernehmenden Daten
     * @returns Shop
     */
    Shop.shopify = shopify;

    Shop.prototype.isSubmitAllowed = function () {
        return !!_.get(this, 'shop.isSubmitAllowed');
    };

    return Shop;
}

module.exports = Shop;
