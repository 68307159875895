'use strict';

/**
 * @ngInject
 */
function GoogleTagManagerComponentController($log) {
    var self = this;

    self.$onInit = function () {
        if (self.containerId) {
            (function (w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
                var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', self.containerId);

            $log.info('Google Tag Manager included for container with ID %s', self.containerId);
        }
        else {
            $log.warn('no container ID configured for Google Tag Manager');
        }
    };
}

module.exports = {
    controller: GoogleTagManagerComponentController,
    bindings: {
        containerId: '<'
    }
};