'use strict';

var _ = require('lodash');
var jwtDecode = require('jwt-decode');

/**
 * @ngInject
 */
function AuthHelper() {

    var self = this;

    self.decodeToken = jwtDecode;

    self.getJwtExpirationDate = function (token) {
        var decoded = this.decodeToken(token);

        if (typeof decoded.exp === "undefined") {
            return null;
        }

        var d = new Date(0); // The 0 here is the key, which sets the date to the epoch
        d.setUTCSeconds(decoded.exp);

        return d;
    };

    self.isJwtExpired = function (token, offsetSeconds) {
        var d = this.getJwtExpirationDate(token);
        offsetSeconds = offsetSeconds || 0;
        if (d === null) {
            return false;
        }

        // Token expired?
        return !(d.valueOf() > (new Date().valueOf() + (offsetSeconds * 1000)));
    };

}

module.exports = AuthHelper;