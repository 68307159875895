'use strict';

var _ = require('lodash');

var INVOICE_PAYMENT_METHOD = 'invoice';
var CREDIT_CARD_PAYMENT_METHOD = 'payone_credit_card';

// Da die Reihenfolge der Zahlarten, wie sie von der API geliefert werden undefiniert ist,
// hier client-seitig die bekannten Zahlarten in einer definierte Reihenfolge festlegen.
var PAYMENT_METHOD_SORT_RANK_BY_NAME = _.reduce([
    'onsite_cash',
    'onsite_debit_card',
    'onsite_credit_card',
    CREDIT_CARD_PAYMENT_METHOD,
    'paypal_api_checkout',
    'payone_pay_direct',
    'payone_sofort',
    INVOICE_PAYMENT_METHOD
], function (acc, method, rank) {
    acc[method] = rank;
    return acc;
}, {});

/**
 * @ngInject
 */
function SelectPaymentMethodComponentController($log, $scope, shopService) {
    var self = this;

    function areConditionsForPaymentMethodFulfilled(paymentMethodSettings) {
        return _.some(paymentMethodSettings.conditions, function (condition) {
            return shopService.checkRuleCondition(condition, self.shop);
        });
    }

    self.onSelect = function () {
        self.model.$setViewValue(self.selectedPaymentMethod);
    };

    self.$onInit = function () {
        self.model.$render = function () {
            self.selectedPaymentMethod = self.model.$viewValue;
        };
    };

    self.getSortRank = function (paymentMethodSetting) {
        // unbekannte Zahlungsmethoden werden ganz hinten eingordnet.
        return _.get(PAYMENT_METHOD_SORT_RANK_BY_NAME, paymentMethodSetting.paymentMethod, Math.MAX_SAFE_INTEGER);
    };

    self.getMessageKey = function (paymentMethodSetting) {
        return ['shop.checkout.paymentMethod', paymentMethodSetting.paymentMethod].join('.');
    };

    self.getLogoUrl = function (paymentMethodSetting) {
        return ['/img/payment', paymentMethodSetting.paymentMethod, 'png'].join('.');
    };

    self.isActive = function (paymentMethodSetting) {
        switch (paymentMethodSetting.activationType) {
            case 'ALWAYS':
                return true;
            case 'CONDITIONAL':
                // TODO: beachten, dass eine bereits aushewählte Zahlungsart ungültig werden kann,
                // evtl. muss die Auswahl also zurückgesetzt werden.
                return areConditionsForPaymentMethodFulfilled(paymentMethodSetting);
            default:
                return false;
        }
    };

    self.$onInit = function () {
        // Die aktuell verfügbaren Zahlarten überwachen, da ggf. eine bereits vorgenommenen Auswahl
        // entfernt werden muss, wenn durch ein geändert eingabe diese Auswahlmöglichkeit wegfällt.
        $scope.$watchCollection(function () {
            return _(self.paymentMethodSettings)
                .filter(self.isActive)
                .map('paymentMethod')
                .sortBy()
                .value();
        }, function (newAvailablePaymentMethods) {
            if (!_.includes(newAvailablePaymentMethods, self.selectedPaymentMethod)) {
                self.selectedPaymentMethod = undefined;
                self.onSelect();
            }
        });
    };
}

module.exports = {
    templateUrl: 'widget/widget.checkout.selectPaymentMethod.component.html',
    controller: SelectPaymentMethodComponentController,
    require: {
        model: 'ngModel'
    },
    bindings: {
        shop: '<',
        paymentMethodSettings: '<'
    }
};
