'use strict';

var _ = require('lodash');

/**
 * @ngInject
 */
function WidgetAccordionComponentController() {
    var self = this;

    self.open = function(group) {

    }
}

module.exports = {
    templateUrl: 'widget/widget.accordion.component.html',
    controller: WidgetAccordionComponentController,
    // Kann nicht "accordion" heißen, da die uib-accordion Direktive,
    // die im Template verwendet wird diesen Namen auch verwendet und
    // daher in den relevanten Teilen des Templates kein Zugriff mehr
    // auf den eigenen Controller möglich ist.
    controllerAs: 'lmAccordion',
    bindings: {
        widget: '<',
        model: '<'
    }
};