'use strict';

const _ = require('lodash');

/**
 * @ngInject
 */
function i18nFilter($sce, $translate) {
    return localizedValue => {
        const value = _.get(localizedValue, $translate.use());

        if (_.isNil(value)) {
            return undefined;
        }

        return _.isString(value) ? $sce.trustAsHtml(value) : value;
    };
}

module.exports = i18nFilter;
