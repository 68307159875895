'use strict';

var common = require('angular').module('leipzigerMesse.ticketShop.frontend.common', [
    require('./button')
]);

common.provider('stepNavigationConfiguration', require('./stepNavigation/stepNavigation.configuration.provider'));

require('./stepNavigation/stepNavigation.component');
require('./stepNavigation/stepNavigation.element.component');

require('./injectCSS.factory');
require('./repeat.factory');
require('./uriEncode.filter');

common.filter('trustHtml', require('./trustHtml.filter'));
common.filter('money', require('./money.filter'));
common.filter('filesize', require('./filesize.filter'));

common.directive('lmImage', require('./lmImage.directive'));

common.directive('lmValidate', require('./lmValidate.validation.directive'));

module.exports = common;
