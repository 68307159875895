'use strict';

/**
 * @ngInject
 */
require('angular').module('leipzigerMesse.ticketShop.frontend.shop').component('lmShopItem', {
    controller: function (lmItemRegistry) {
        'ngInject';

        var self = this;

        self.$onInit = function () {
            lmItemRegistry.registerItemInformation(self.data);
        };
    },
    controllerAs: 'item',
    bindings: {
        data: '<'
    },
    require: {
        shop: '^^lmShop'
    }
});