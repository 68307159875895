'use strict';

const _ = require('lodash');

/**
 * @ngInject
 */
function VoucherCodeRedemptions($log) {
    const self = this;

    self.loading = false;

    self.isVisible = () => {
        return self.shop.hasAppliedVouchers();
    }

    self.getAppliedVouchers = () => {
        return self.shop.getAppliedVouchers();
    }

    self.removeVoucher = (voucher) => {
        self.loading = false;

        self.shop.removeVoucher(voucher).finally(function () {
            self.loading = false;
        });
    }

    self.getEffectiveTotal = () => {
        return self.shop.getEffectiveCartTotal();
    }

}

module.exports = {
    controller: VoucherCodeRedemptions,
    require: {
        shop: '^^lmShop'
    },
    template: `
    <div class="col-sm-12 lm-voucher-redemptions" ng-if="$ctrl.isVisible()">
        <div class="well" ng-repeat="voucher in $ctrl.getAppliedVouchers()">
            <div class="row ticket_item ticket-summary">
                <div class="col-sm-8">
                    <span class="ticket-label"
                        translate="shop.voucher.redemptions.description"
                        translate-values="{code: voucher.code}">
                    </span>
                    <span translate="shop.voucher.redemptions.remainingAmount"
                          translate-values="{remainingAmount: voucher.availableAmount - voucher.appliedAmount}">
                    </span>
                </div>
            
                <div class="col-sm-2 col-sm-offset-1">
                    <span class="ticket-price">{{ -voucher.appliedAmount | money }}</span>
                </div>
                
                <div class="col-sm-1">
                    <button type="button" class="btn btn-link btn-block btn-trash" ng-click="$ctrl.removeVoucher(voucher)">
                        <i class="fa fa-fw fa-trash-o" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        </div>
        
        <div class="row ticket_item">
            <div class="summary-header">
                <div class="col-xs-6 col-sm-2 col-sm-offset-7">
                    <span class="ticket-label" translate="shop.voucher.effectiveTotal"></span>
                </div>
                <div class="col-xs-6 col-sm-2">
                    <span class="ticket-label">{{ $ctrl.getEffectiveTotal() | money }}</span><br/>
                </div>
            </div>
        </div>
    </div>
    `
};
