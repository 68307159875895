'use strict';

const moment = require('moment-timezone');

class AdBannerWidgetComponentController {
    $onInit() {
        this.timestamp = moment().valueOf();
    }
}

require('angular').module('leipzigerMesse.ticketShop.frontend.widget').component('lmAdBannerWidget', {
    templateUrl: 'widget/widget.adBanner.component.html',
    controller: AdBannerWidgetComponentController,
    bindings: {
        widget: '<'
    }
});
