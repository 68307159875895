'use strict';

/**
 * @ngInject
 */
function config($stateProvider) {
    $stateProvider
        .state('root.example', {
            abstract: true,
            url: 'example',
            template: '<div ui-view></div>'
        })
        .state('root.example.radioChoiceGroup', {
            url: '/radioChoiceGroup',
            templateUrl: 'example/example.radioChoiceGroup.html',
            controllerAs: '$ctrl',
            controller: function () {
                var self = this;

                self.choices = [
                    {index: 0, label: 'Option A'},
                    {index: 1, label: 'Option B'},
                    {index: 2, label: 'Option C'},
                    {index: 3, label: 'Option D', requiresInput: true}
                ];
            }
        });
}

module.exports = config;