'use strict';

var _ = require('lodash');

/**
 * @ngInject
 */
function PersonItemOverviewWidgetController(widget, order, shop, parent) {
    var vm = this;
    vm.widget = widget;
    vm.order = order;
    vm.shop = shop;
    vm.parent = parent;
    vm.model = order.getFormDataProperty(widget.path);

    vm.itemsByIdent = _.groupBy(widget.items, function(item) { return item.ident; });


    vm.getParticipantName = function (item) {
        // FIXME: hier sind die Datenpfade hardcoded.
        // Aber auch ein Referenzieren der Pfade aus der Widget-Definition bringt nichts,
        // da zwischen choice-Werten und Strings unterschieden werden muss.
        return [
            _.get(item, 'formData.user.salutation.data.label'),
            _.get(item, 'formData.user.title.data.label'),
            _.get(item, 'formData.user.firstName.data'),
            _.get(item, 'formData.user.lastName.data')
        ].join(' ')
    };
}

module.exports = PersonItemOverviewWidgetController;
