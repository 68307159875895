'use strict';

const _ = require('lodash');
const moment = require('moment-timezone');
const {PYT_MODES} = require('../common/constants');

require('angular').module('leipzigerMesse.ticketShop.frontend.widget').component('lmCheckoutWidget', {
    templateUrl: 'widget/widget.checkout.component.html',
    controller: function ($log, $scope, $rootScope, $analytics, lmItemRegistry, Order, shopService) {
        'ngInject';

        var self = this;

        /**
         * Berechnet die USt. Beträge, die pro Steuersatz zu anfallen.
         */
        function calculateVatAmounts() {
            var itemsByVatRate = _.reduce(self.shop.data.model.items, function (acc, participants, ident) {
                var vatRate = self.getVatRate(ident);
                var vatAmount = self.getUnitPrice(ident) * participants.length * vatRate / 100;

                acc[vatRate] = (acc[vatRate] || 0) + vatAmount;

                return acc;
            }, {});

            return _(itemsByVatRate).toPairs().sortBy(0).map(function (pair) {
                return {rate: pair[0], amount: pair[1]};
            }).value();
        }

        self.paymentMethod = undefined;

        self.vatAmounts = {};

        self.getType = function (ident) {
            return _.get(lmItemRegistry.getItemInformation(ident), 'type');
        };

        self.getLabel = function (ident) {
            return _.get(lmItemRegistry.getItemInformation(ident), 'label');
        };

        self.getUnitPrice = function (ident) {
            return self.shop.getItemUnitPrice(ident);
        };
        self.getFreeOfChargeLabel = function (ident) {
            return _.get(lmItemRegistry.getItemInformation(ident), 'freeOfChargeLabel');
        };

        self.getVatRate = function (ident) {
            return _.get(lmItemRegistry.getItemInformation(ident), 'vatPercent', 0);
        };

        self.getTotal = function () {
            return self.shop.getCartTotal();
        };

        self.isPaymentNecessary = function () {
            return self.shop.getEffectiveCartTotal() > 0 && !_.get(self.shop.data, 'order.hasExhibitor');
        };

        self.isPciSealVisible = () => {
            // OnSite-Shops haben keinen freien Zugriff auf das Internet,
            // daher kann dass Siegel nicht angezeigt werden.
            return self.isPaymentNecessary() && !self.shop.isOnsite();
        };

        self.getProducts = function () {
            return _(self.shop.data.model.items).map(function (participants, ident) {
                return {
                    id: ident,
                    name: self.getLabel(ident).de,
                    category: self.getType(ident),
                    price: (self.getUnitPrice(ident) / 100).toFixed(2),
                    quantity: participants.length
                };
            }).filter(function (p) {
                return p.quantity > 0;
            }).value();
        };

        self.getParticipantName = function (item) {
            // FIXME: hier sind die Datenpfade hardcoded.
            // Aber auch ein Referenzieren der Pfade aus der Widget-Definition bringt nichts,
            // da zwischen choice-Werten und Strings unterschieden werden muss.
            return [
                _.get(item, 'formData.user.salutation.data.label'),
                _.get(item, 'formData.user.title.data.label'),
                _.get(item, 'formData.user.firstName.data'),
                _.get(item, 'formData.user.lastName.data')
            ].join(' ');
        };

        /**
         * Gibt das Startdatum eines EventParts als formattierten Date-string zurück
         *
         * @param seat
         * @returns {*}
         */
        self.getSeatEventPartStartDate = (seat) => {
            return moment(seat.eventPart.start).tz('Europe/Berlin').format('DD.MM.YYYY');
        };

        /**
         * Die seatingData beinhaltet den Seating-Betriebsmodus. Diese Methode testet
         * den Modus auf den PYT-Sonderfall. Definiert werden die Modi in der
         * EventSeatingConfiguration der API, sowie in common/constants.js
         *
         * @param seatingData
         * @returns {boolean}
         */
        self.usesPytMode = (seatingData) => {
            return PYT_MODES.includes(seatingData.mode);
        };

        // FIXME: sollte in shop.submitOrder.component o.ä. verschoben werden, wird hier nicht mehr
        // genutzt und Zahlungsauswahl könnte jetzt an einem beliebigen Punkt im Prozess vorkommen.
        self.submitOrder = function () {
            if (self.shop.isValid()) {
                $analytics.transactionTrack('checkout', {
                    checkout: {
                        actionField: {
                            step: self.stepNumber,
                            option: _.get(self.shop, 'data.model.paymentMethod')
                        },
                        products: self.getProducts()
                    }
                });
            }

            self.shop.submitOrder();
        };

        self.getItems = function () {
            return self.shop.data.model.items;
        };

        self.removeItem = function (itemIdent, item) {
            if (item.seatingData) {
                if (!shopService.areSeatingMinMaxQuantitiesFulfilled(self.shop.data, -1)) {
                    // Wenn nach dem Löschen die MinMax-Bestellmengen nicht mehr erfüllt würden
                    // das Löschen verhindern
                    $rootScope.$broadcast('cart:removeSeatingItemNotAllowed');
                    return;
                }

                // Das Item muss gecanceled werden, damit der Platz wieder freigegeben wird
                Order.removeSeatingItem({id: item.id}).$promise.then(() => {
                    // Im Falle vom Seating werden zusätzliche Felder gemapped, weshalb ein Objektvergleich wackelig wäre
                    _.remove(_.get(self.shop.data.model.items, itemIdent, []), (modelItem) => {
                        return modelItem.id === item.id;
                    });

                    // Sicherstellen, dass die Leistung auch aus dem Order-Objekt entfernt wurde
                    _.remove(self.shop.data.order.mainItems, (modelItem) => {
                        return modelItem.id === item.id;
                    });

                    // Broadcast um den Seating-Leistungswidget mitzuteilen, dass eine Leistung entfernt wurde
                    $rootScope.$broadcast('cart:removeSeatingItem', {items: self.shop.data.order.mainItems});
                });
            } else {
                _.remove(_.get(self.shop.data.model.items, itemIdent, []), item);
            }

            if (item.inviteCode) {
                self.shop.removeCode(item.inviteCode);
            }
        };

        self.$onInit = function () {
            // Wenn sich die Anzahl der gewählten Tickets in irgendeiner
            // Weise ändern müssen die USt. Beträge neu berechnet werden
            $scope.$watchCollection(function () {
                return _.map(self.shop.data.model.items, 'length');
            }, function () {
                self.vatAmounts = calculateVatAmounts();
            });

            self.paymentMethodSettings = _([
                'data.shop.paymentSettings.invoiceSettings',
                'data.shop.paymentSettings.sofortueberweisungSettings',
                'data.shop.paymentSettings.paydirektSettings',
                'data.shop.paymentSettings.payPalSettings',
                'data.shop.paymentSettings.creditCardSettings',
                'data.shop.paymentSettings.onsiteCashSettings',
                'data.shop.paymentSettings.onsiteDebitCardSettings',
                'data.shop.paymentSettings.onsiteCreditCardSettings'
            ])
                .map(_.partial(_.get, self.shop))
                .compact()
                .value();
        };
    },
    require: {
        shop: '^^lmShop'
    },
    bindings: {
        widget: '<',
        model: '<'
    }
});
