'use strict';

var _ = require('lodash');
var uuid = require('node-uuid');

/**
 * @ngInject
 */
function WidgetAccordionGroupComponentController() {
    var self = this;

    // UUID zur eindeutigen Identifikation des zur diesen Controller-Instanz gehörenden DOM-Elements
    self.id = uuid.v1();

    self.open = false;

    self.toString = function () {
        return 'accordion group: ' + self.id + '(' + (self.form.$valid ? 'valid' : 'invalid') + ')';
    };
}

module.exports = {
    templateUrl: 'widget/widget.accordionGroup.component.html',
    controller: WidgetAccordionGroupComponentController,
    controllerAs: 'accordionGroup',
    bindings: {
        widget: '<',
        model: '<'
    },
    require: {
        form: '',
        self: 'lmWidgetAccordionGroup'
    }
};