'use strict';

var _ = require('lodash');
var $ = require('jquery-browserify');

/**
 * @ngInject
 */
function DropdownComponentController($element) {

    var self = this;

    self.selectedOption = null;

    self.selection = undefined;
    self.keyboardSelectionPrefix = "";
    self.lastKeyboardInteraction = Date.now();

    self.continuousKeyboardInteractionThreshold = 250; // 0.25s

    self.select = function (item) {
        self.model.$setViewValue(item);
        self.model.$render();
    };
    self.deselect = function () {
        self.model.$setViewValue();
        self.model.$render();
    };

    self.$onInit = function () {
        self.model.$render = function render() {
            self.selection = self.model.$viewValue;
        };
    };

    self.keydown = function (e) {

        var elems = $($element.find('a'));

        switch (e.which) {
            case 40: { // hoch
                if (!angular.isNumber(self.selectedOption)) {
                    self.selectedOption = 0;
                } else {
                    self.selectedOption = self.selectedOption === elems.length - 1 ? self.selectedOption : self.selectedOption + 1;
                }
                elems[self.selectedOption].focus();
                e.preventDefault();
                return false;
            }
            case 38: { // runter
                if (!angular.isNumber(self.selectedOption)) {
                    self.selectedOption = elems.length - 1;
                } else {
                    self.selectedOption = self.selectedOption === 0 ? 0 : self.selectedOption - 1;
                }
                elems[self.selectedOption].focus();
                e.preventDefault();
                return false;
            }
        }

        if (!/[A-Z]|[0-9]/.test(String.fromCharCode(e.which))) {
            return;
        }

        var currentItem = elems.filter(':focus');
        var index = elems.index(currentItem);

        var firstCall = false;

        if (index == -1) {
            index = 0;
            firstCall = true;
        }

        var char = String.fromCharCode(e.keyCode).toLowerCase();
        if ((Date.now() - self.lastKeyboardInteraction) > self.continuousKeyboardInteractionThreshold) {
            self.keyboardSelectionPrefix = char;
        } else {
            self.keyboardSelectionPrefix += char;
        }
        self.lastKeyboardInteraction = Date.now(); // why

        for (var i = index + (firstCall ? 0 : 1); i < elems.length; i++) {
            var $elem = $(elems[i]);
            if (_.startsWith($elem.html().toLowerCase(), self.keyboardSelectionPrefix)) {
                $elem.focus();
                self.selectedOption = i;
                return false;
            }
        }

        for (i = 0; i < index; i++) {
            $elem = $(elems[i]);
            if (_.startsWith($elem.html().toLowerCase(), self.keyboardSelectionPrefix)) {
                $elem.focus();
                self.selectedOption = i;
                return false;
            }
        }
    };
}

module.exports = {
    templateUrl: 'widget/form/dropdown.component.html',
    controller: DropdownComponentController,
    bindings: {
        choices: '<',
        isRequired: '<ngRequired',
        placeholder: '@',
        disabled: '<?'
    },
    require: {
        model: 'ngModel'
    }
};