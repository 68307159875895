'use strict';

/**
 * @ngInject
 */
function StepNavigationConfigurationProvider() {

    // Config Objekt das vom Provider verwaltet wird
    var config = {
        scrollOffset: 150,
        scrollDurationMs: 500
    };

    this.config = config;

    // Rückgabe Config Instanz
    this.$get = [function () {
        return config;
    }];

}

module.exports = StepNavigationConfigurationProvider;