'use strict';

var _ = require('lodash');

/**
 * @ngInject
 */
function InputWidgetController($scope, widget, order, parent, deviceDetector) {
    var vm = this;

    vm.widget = widget;
    vm.order = order;
    vm.model = order.getFormDataProperty(widget.path);

    vm.isIE = deviceDetector.browser === 'ie' || deviceDetector.browser === 'ms-edge';

    vm.minLength = widget.minLength;
    vm.maxLength = widget.maxLength;

    // FIXME: Workaround, fest einprogrammierte Validierung EINES Falles. Allgemeine Lösung finden
    if(_.has(parent, 'getSelectedCountryCode') && widget.path === 'postalCode') {
        $scope.$watch(function() {
            return parent.getSelectedCountryCode();
        }, function(newValue) {
            if(newValue === 'DE') {
                vm.minLength = vm.maxLength = 5;
            } else {
                vm.minLength = widget.minLength;
                vm.maxLength = widget.maxLength;
            }
        });
    }



}

module.exports = InputWidgetController;