'use strict';

var _ = require('lodash');

/**
 * @ngInject
 */
function CodeEntryComponentController($log, auth) {
    var self = this;

    self.loading = false;

    // Wenn das Codeeingabefeld nicht leer ist und der Code noch nicht abgesendet wurde, gilt dies als Fehler
    self.hasUnsentCode = function (codes) {
        codes.valid = !(codes.input !== undefined && self.hasInput() && !self.loading);
    };

    self.onKeypress = function ($event) {
        if ($event.keyCode === 13) {
            $event.preventDefault();
            self.submit();
        }
    };

    self.submit = function () {
        self.loading = true;

        self.shop.refreshShop().finally(function () {
            self.loading = false;
        });
    };

    self.removeCode = function (code) {
        self.loading = false;
        self.shop.removeCode(code).finally(function () {
            self.loading = false;
        });
    };

    self.hasInput = function () {
        return !_.isEmpty(self.codes.input);
    };

    self.isItfShop = function () {
        return self.shop.isItfShop();
    };

    self.isOverbookingPermitted = function () {
        return self.shop.isOnsite()
            && _.includes(_.get(self.user, 'roles', []), 'ROLE_ONSITE_SALE_ORGA') // Nur für Hauptkassierer
            && _.includes(['INVALID_STATUS', 'CONTINGENT_DEPLETED'], _.get(self.codes, "errorCode"));
    };

    self.$onInit = function () {
        self.user = auth.getIdentity();
    };
}

module.exports = {
    templateUrl: 'widget/form/codeEntry.component.html',
    controller: CodeEntryComponentController,
    bindings: {
        shop: '<',
        codes: '<'
    }
};
