'use strict';

var _ = require('lodash');

/**
 * @ngInject
 */
function PostalAddressWidgetController($scope, widget, order) {
    var vm = this;

    vm.widget = widget;

    vm.model = order.getSubmodel(vm.widget.path);

    vm.getSelectedCountryCode = function() {
        return _.get(vm.model, 'formData.country.data.value');
    };

}

module.exports = PostalAddressWidgetController;