'use strict';

var shop = require('angular').module('leipzigerMesse.ticketShop.frontend.shop', [
    require('angular-resource'),
    require('angular-translate'),
    require('angular-growl-v2') && 'angular-growl',
    require('../auth'),
    require('../item'),
    require('../i18n'),
    require('../data').name,
    require('../order').name
]);

shop.config(require('./shop.states'));

shop.constant('ShopModel', require('./shop.model.class'));

shop.service('Shop', require('./shop.resource'));
require('./shop.service');

shop.component('lmShopLoginIndicator', require('./shop.loginIndicator.component'));

require('./shop.styling.service');

shop.component('lmShop', require('./shop.component'));

require('./shop.header.component');
require('./shop.orderExpirationTimer.component');
require('./shop.quantityLimitExceedancePopup.component');
require('./shop.step.component');

require('./shop.submitOrder.component');

require('./shop.item.component');

shop.component('lmShopShow', require('./shop.show.component'));
shop.component('lmShopLogin', require('./shop.login.component'));

shop.component('lmShopTemplatePage', require('./shop.templatePage.component'));


module.exports = shop;
